import React, { useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { Logindetail } from "../context/Logindetail.js";
import moment from "moment";
import toast from "react-hot-toast";
import { getprofile_fun, update_fun } from "../api/Dashboardapi.js";
import { GenderApi } from "../api/Controllapi.js";
// import DatePicker from "react-datepicker";
import { CameraFill } from "react-bootstrap-icons";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { format, formatDate } from 'date-fns'
import dayjs from "dayjs";
import { reference_fun } from '../api/Controllapi'

const Profile = ({ settings }) => {

    const today = dayjs().subtract(18, 'years')
    const [file, setfile] = useState();
    const [imag, setimag] = useState();
    const [gender, setgender] = useState([])
    const [update_data, setupdate_data] = useState([])
    const [spinner, setspinner] = useState(false)
    const [ProfileData, setProfileData] = useState([])
    const [date, setdate] = useState()

    const [refer_data, setrefer_data] = useState([])

    const getrefer_api = () => {
        reference_fun()
            .then((res) => {
                setrefer_data(res?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getrefer_api()

    }, [])

    function handlechange(e) {


        if (e.target.files[0]?.type === 'image/jpeg') {

            if (e.target.files[0]?.size > 2 * 1024 * 1024 || e.target.files[0]?.size <= 0) {
                toast.error('File Size Max 2MB')
            }
            else {
                setfile(URL.createObjectURL(e.target.files[0]));
                setimag(e.target.files[0])
            }

        }
        else {
            toast.error('Only Upload jpeg format')
        }

    }

    const { detail_info, setdetail_info } = useContext(Logindetail)

    //   console.log('detail_info?.avatardetail_info?.avatar',detail_info?.avatar);

    const initvalue = {
        "name": `${detail_info?.name ? detail_info?.name : ''}`,
        "father_name": `${detail_info?.father_name ? detail_info?.father_name : ''}`,
        "phone_number": `${detail_info?.phone_number ? detail_info?.phone_number : ''}`,
        "email": `${detail_info?.email ? detail_info?.email : ''}`,
        "date_of_birth": `${detail_info?.date_of_birth ? detail_info?.date_of_birth : ''}`,
        "gender": `${detail_info?.gender ? detail_info?.gender : 'Male'}`,
        "avatar": `${detail_info?.avatar ? detail_info?.avatar : ''}`,
    }


    const updateschema = Yup.object().shape({
        "name": Yup.string()
            .required("Enter name")
            .matches(/^[a-zA-Z ]*$/, "Invalid name"),

        "father_name": Yup.string()
            .required("Enter mother name")
            .matches(/^[a-zA-Z ]*$/, "Invalid name"),

        "phone_number": Yup.string()
            .min(10, 'Phone Number Minimum 10 digits')
            .max(10, 'Phone Number Maximum 10 digits')
            .required("Enter your Phone Number")
            .matches(/^[0-9]+$/, "Invalid  Phone Number"),


        'email': Yup.string()
            .email('Invalid Email')
            .required('Enter Your Email')
            .matches(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/, 'Invalid Email'
            )
            .test(
                'Invalid Email',
                (value) => {
                    return value === value.toLowerCase()
                }
            ),

        // "date_of_birth": Yup.date()
        //     .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
        //     .required("Enter DOB"),

        "date_of_birth": Yup.string()
            .required('Enter DOB'),

        "gender": Yup.string(),

        "avatar": Yup.mixed(),
    })

    const Formrik = useFormik({

        initialValues: initvalue,
        validationSchema: updateschema,
        onSubmit: async (value, { setErrors, setStatus, resetForm }) => {

            if (detail_info.role === 2) {
                if (!value.avatar) {
                    toast.error('Profile image is mandatory')
                    return false;
                }
            }

            const DOB = moment(date).format('YYYY-MM-DD')

            const formdata = new FormData()
            formdata.append("name", value.name)
            formdata.append("father_name", value.father_name)
            formdata.append("phone_number", value.phone_number)
            formdata.append("email", value.email)
            formdata.append("date_of_birth", DOB)
            formdata.append("gender", value.gender)
            formdata.append("avatar", imag)



            setspinner(true)
            await update_fun(formdata)
                .then((res) => {
                    console.log(res)
                    toast.success(res.message);
                    setupdate_data(res?.user);
                    handlegetprofile();
                    setspinner(false)
                })
                .catch((err) => {
                    console.log(err)
                    setspinner(false)

                })

        }
    })


    const genderapi = async () => {
        try {

            GenderApi(res => {
                setgender(res?.data)
            }).catch(err => {
                console.log(err)
            })
        }
        catch (err) {
            console.log(err)
        }

    }

    const handlegetprofile = () => {
        getprofile_fun()
            .then((res) => {
                localStorage.setItem("green_login", JSON.stringify(res?.data));
                setdetail_info(res?.data)
                setfile(res?.data?.avatar);
            })
            .catch((err) => {
                console.log(err)
            })
    }


    useEffect(() => {
        genderapi();
        setfile(detail_info?.avatar ?? '');
        setimag(detail_info?.avatar ?? '');
        handlegetprofile()

    }, [])


    const [inputValue, setInputValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    // Handle user input change
    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        // Filter data based on input value (only when input value is not empty)
        if (value) {
            const filtered = refer_data.filter((item) =>
                item?.code?.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredData(filtered);
            setIsDropdownVisible(filtered.length > 0);
        } else {
            // If the input is empty, hide the dropdown
            setFilteredData([]);
            setIsDropdownVisible(false);
        }
    };

    // Handle user selecting an item from the dropdown
    const handleSelect = (item) => {
        setInputValue(item.code);
        setIsDropdownVisible(false);
    };

    console.log('dateeee', date)

    return (
        <>
            <HeadingBanner title={"My Profile"} />
            <section>
                <div className="container">
                    <div className="profile_layout">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4">
                                <Dashboard setting_data={settings} />
                            </div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="myprofile-content">
                                    <div className="bd-heads pb-3" >
                                        <h4>My Profile</h4>
                                    </div>
                                    <div className="bd-form border">
                                        {console.log(detail_info.role)}
                                        <form onSubmit={Formrik.handleSubmit}>
                                            <div className="profice-pic text-center position-relative">
                                                <img src={file ? file : "/assets/images/user.jfif"} id="uploadimg" className="img-fluid" alt="profile_img" />
                                                <input type="file" id="my_file" className="d-none" onChange={handlechange} />
                                                <div className=" profile-came position-absolute" onClick={() => { document.getElementById('my_file').click() }}><CameraFill /></div>
                                                {
                                                    Formrik.touched.avatar && Formrik.errors.avatar ? (<p className="text-danger">{Formrik.errors.avatar}</p>) : null
                                                }
                                            </div>
                                            <div className="row p-4">
                                                <div className="form-group col-md-6 col-sm-6 col-6  login-text">
                                                    <label> Name</label>
                                                    <input type="text" className="form-control" id="inputname" placeholder=" Name" {...Formrik.getFieldProps("name")} />
                                                    {
                                                        Formrik.touched.name && Formrik.errors.name ? (<span className="text-danger">{Formrik.errors.name}</span>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6  login-text">
                                                    <label >Mother Name</label>
                                                    <input type="text" className="form-control" id="inputfathername" placeholder="Mother Name" {...Formrik.getFieldProps("father_name")} />
                                                    {
                                                        Formrik.touched.father_name && Formrik.errors.father_name ? (<span className="text-danger">{Formrik.errors.father_name}</span>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6  login-text">
                                                    <label >Email</label>
                                                    <input type="text" className="form-control" id="inputemail1" placeholder="Email"  {...Formrik.getFieldProps("email")} />
                                                    {
                                                        Formrik.touched.email && Formrik.errors.email ? (<span className="text-danger">{Formrik.errors.email}</span>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6  login-text">
                                                    <label >Phone</label>
                                                    <input type="text" className="form-control" disabled id="inputPhone" placeholder="Phone" {...Formrik.getFieldProps("phone_number")} />
                                                    {
                                                        Formrik.touched.phone_number && Formrik.errors.phone_number ? (<span className="text-danger">{Formrik.errors.phone_number}</span>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6  login-text">
                                                    <label >Date of Birth</label>
                                                    {/* <input type="date" className="form-control" id="inputemail2" placeholder="D-O-B" {...Formrik.getFieldProps("date_of_birth")} /> */}
                                                    <div className="react-date">
                                                        {/* <DatePicker
                                                            selected={date}
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={new Date(moment().subtract(100, "years"))}
                                                            maxDate={new Date(moment().subtract(18, "years"))}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            placeholderText="DD-MM-YYYY"
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            onChange={(date) => { setdate(date); Formrik.setFieldValue('date_of_birth', moment(date).format('YYYY-MM-DD')) }}

                                                        /> */}
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                                <DatePicker
                                                                    maxDate={today}
                                                                    format="DD-MM-YYYY"
                                                                    onChange={(newValue) => { setdate(dayjs(newValue).format('YYYY-MM-DD')) }}
                                                                    defaultValue={detail_info?.date_of_birth ? dayjs(detail_info?.date_of_birth) : ''}
                                                                    sx={{
                                                                        backgroundColor: "#b7b2b221",
                                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                                            border: "none"
                                                                        },
                                                                        "&.Mui-focused": {
                                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                                border: "none"
                                                                            }
                                                                        }
                                                                    }}
                                                                    slotProps={{
                                                                        field: {
                                                                            readOnly: true
                                                                        }
                                                                    }}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>

                                                    </div>
                                                    {
                                                        Formrik.touched.date_of_birth && Formrik.errors.date_of_birth ? (<span className="text-danger">{Formrik.errors.date_of_birth}</span>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6  login-text">
                                                    <label>Gender</label>
                                                    <select className="form-select" id="inputGroupSelect01" {...Formrik.getFieldProps("gender")} >
                                                        {/* {
                                                            gender ? gender.map((r,i) => {
                                                                return (
                                                                    <option value={r.id} key={i}>{r.name}</option>
                                                                )
                                                            }) : ""
                                                        } */}
                                                        <option value="" selected disabled>Select</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>

                                                    </select>
                                                    {
                                                        Formrik.touched.gender && Formrik.errors.gender ? (<span className="text-danger">{Formrik.errors.gender}</span>) : null
                                                    }
                                                </div>

                                                {/* {
                                                    (true) ? (
                                                        <div className="col-lg-6 col-md-6  col-12 login-text">
                                                            <label>Reference ID</label>

                                                            <div className="autocomplete-container w-100">
                                                                <input
                                                                    type="text"
                                                                    value={inputValue}
                                                                    onChange={handleChange}
                                                                    onFocus={() => setIsDropdownVisible(filteredData.length > 0 && inputValue !== '')}
                                                                    placeholder="Type to filter..."
                                                                    className="autocomplete-input form-control"
                                                                />
                                                                {isDropdownVisible && (
                                                                    <div className="autocomplete-dropdown">
                                                                        {filteredData.map((item) => (
                                                                            <div
                                                                                key={item.id}
                                                                                onClick={() => handleSelect(item)}
                                                                                className="autocomplete-item"
                                                                            >
                                                                                {item.code}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {
                                                                Formrik.touched.reference_id && Formrik.errors.reference_id ? (<span className="text-danger">{Formrik.errors.reference_id}</span>) : null
                                                            }

                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <p>Reference Id : asdsadds</p>
                                                        </div>
                                                    )
                                                } */}



                                            </div>
                                            <div className="update-profile  mx-3">
                                                <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Edit'}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
export default Profile