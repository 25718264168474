import React, { useState, useEffect, useContext, useRef } from "react"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { reference_fun, role_url } from '../../api/Controllapi'
import * as Yup from 'yup'
import { Formik, useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Signup_fun } from "../../api/Controllapi";
import instance from "../../Config/Config";
import { useNavigate } from "react-router-dom";
import { Footerpages } from "../../context/Footerpages";
import Select from 'react-select'
import { Shimmer } from "react-shimmer";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { format } from "date-fns";
import dayjs from "dayjs";
import { getprofile_fun } from "../../api/Dashboardapi";
import { Logindetail } from "../../context/Logindetail";


const Signup = ({ phone }) => {

    const [user_role, setuser_role] = useState([])
    const [spinner, setspinner] = useState(false)
    const router = useNavigate()
    const [check, setCheck] = useState(false)
    const { footerpage } = useContext(Footerpages)
    const { setdetail_info, settoken } = useContext(Logindetail)
    const [refer_data, setrefer_data] = useState([])
    const [isloading, setisloading] = useState(true)
    const [peoplecount, setpeoplecount] = useState()
    const today = dayjs().subtract(18, 'years')

    let initValue = {
        'role': '2',
        'first_name': '',
        'last_name': '',
        'phone_number': phone ?? localStorage.getItem('phone') ?? '',
        'email': '',
        'password': '',
        'password_confirmation': '',
        'reference_id': '',
        'date_of_birth': '',
    }

    let initValue2 = {
        'role': '1',
        'first_name': '',
        'last_name': '',
        'phone_number': phone ?? localStorage.getItem('phone') ?? '',
        'email': '',
        'password': '',
        'password_confirmation': '',
        'reference_id': '',
        'date_of_birth': '',
    }

    let additionalvalue = {
        'people_contract': '',
        'whyjoin': '',
        'working_detail': '',
        'experience': ''
    }





    const signschema = Yup.object().shape({

        // 'role': Yup.string().required('Required'),

        // 'first_name': Yup.string()
        //     .required('Enter Your First Name')
        //     .matches(/^[a-zA-Z ]*$/, "Invalid name"),
        'first_name': Yup.string()
            .required('Enter Your First Name')
            .matches(/^[a-zA-Z ]*$/, "Invalid name")
            .trim('First name should not have leading or trailing spaces'),


        'last_name': Yup.string()
            .required('Enter Your Last Name')
            .matches(/^[a-zA-Z ]*$/, "Invalid name")
            .trim('First name should not have leading or trailing spaces'),

        'phone_number': Yup.string()
            .min(10, 'Phone Number Minimum 10 digits')
            .max(10, 'Phone Number Maximum 10 digits')
            .required('Enter Your Phone Number')
            .matches(/^[0-9]+$/, "Invalid  Phone Number"),

        'email': Yup.string()
            .email('Invalid Email')
            .required('Enter Your Email')
            .matches(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/, 'Invalid Email'
            )
            .test(
                'Invalid Email',
                (value) => {
                    return value === value.toLowerCase()
                }
            ),

        "password": Yup.string()
            // .required("Enter your password ")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character"
            ),

        "password_confirmation": Yup.string()
            // .required("Enter Confirm Password")
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),

        // 'reference_id': Yup.date().nullable()
        //     .required('Select Your Reference ID'),

        'date_of_birth': Yup.string()
            .required('Enter Your DOB'),

        // 'people_contract': Yup.string()
        //     .required('Enter Your No.of.contract'),

        // 'whyjoin': Yup.string()
        //     .required('Enter Why You Join'),

        // 'working_detail': Yup.string()
        //     .required('Enter Your working detail'),

        // 'experience': Yup.string()
        //     .required('Enter Your Experience'),

    })

    const handlegetprofile = () => {
        getprofile_fun()
            .then((res) => {
                localStorage.setItem("green_login", JSON.stringify(res?.data));
                setdetail_info(res?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const Formrik = useFormik({
        initialValues: initValue,
        validationSchema: signschema,
        onSubmit: async (value, { setErrors, setStatus, resetForm }) => {


            if (!check) {
                toast.error('Please Agree Terms & conditions')
            }

            else {
                setspinner(true)
                await Signup_fun(value)
                    .then((res) => {
                        console.log(res);
                        localStorage.setItem("green_token", res.token)
                        handlegetprofile()
                        settoken(res.token);
                        document.getElementById('closesignmodaltermspage')?.click()
                        resetForm()
                        setspinner(false)
                    })
                    .catch((err) => {
                        setspinner(false)
                        if (err.message === "Request failed with status code 400") {
                            toast.error(err?.response?.data?.email)
                        }
                        console.log(err)
                    })

            }

        }
    }
    )

    const Formrik2 = useFormik({
        initialValues: initValue2,
        validationSchema: signschema,
        onSubmit: async (value, { setErrors, setStatus, resetForm }) => {

            if (!check) {
                toast.error('Please Agree Terms & conditions')
            }
            else {
                setspinner(true)
                await Signup_fun(value)
                    .then((res) => {
                        console.log(res)
                        localStorage.setItem("green_token", res.token)
                        handlegetprofile()
                        settoken(res.token);
                        document.getElementById('closesignmodaltermspage11')?.click()
                        resetForm()
                        setspinner(false)
                    })
                    .catch((err) => {
                        setspinner(false)
                        if (err.message === "Request failed with status code 400") {
                            toast.error(err?.response?.data?.email)
                        }
                        console.log(err)
                    })

            }

        }
    }
    )



    const formik2 = useFormik({
        initialValues: additionalvalue,
        // validationSchema: signschema
    })

    const getrole_api = async () => {

        try {
            const response = await instance.get(`${role_url}`)
            setuser_role(response?.data?.data)

            // Formrik.setFieldValue('role', response?.data?.data?.length > 0 ? response?.data?.data[0]?.id : '');
            setisloading(false)
        }
        catch (err) {
            console.log(err)
            setisloading(false)
        }
    }

    const getrefer_api = () => {
        reference_fun()
            .then((res) => {
                setrefer_data(res?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getrefer_api()
        getrole_api()

    }, [])



    useEffect(() => {
        Formrik.setFieldValue("phone_number", localStorage.getItem('phone'));
        Formrik2.setFieldValue("phone_number", localStorage.getItem('phone'));
    }, [localStorage.getItem('phone')])


    const [inputValue, setInputValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    // Handle user input change
    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        // Filter data based on input value (only when input value is not empty)
        if (value) {
            const filtered = refer_data.filter((item) =>
                item?.code?.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredData(filtered);
            setIsDropdownVisible(filtered.length > 0);
        } else {
            // If the input is empty, hide the dropdown
            setFilteredData([]);
            setIsDropdownVisible(false);
        }
    };

    // Handle user selecting an item from the dropdown
    const handleSelect = (item) => {
        setInputValue(item.code);
        setIsDropdownVisible(false);
    };

    return (
        <>
            <div className="modal p-0 fade signupmodal" id="signupmodalview" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg px-lg-5 " >
                    <div className="modal-content px-md-4 px-2 py-2">
                        <div className="modal-header login-header justify-content-center border-0">
                            <button className="d-none" id="gobacksign" data-bs-toggle="modal" data-bs-target="#signupmodalview" ></button>
                            <button className="d-none" id="closesignmodal" data-bs-toggle="modal" data-bs-target="#loginmodalview" ></button>
                            <button type="button" className="d-none" id="closesignmodaltermspage" data-bs-dismiss="modal" aria-label="Close"></button>
                            <h3 className="modal-title">Create an Account</h3>
                        </div>

                        <div className="modal-body login-body login-text pt-0">
                            <form onSubmit={Formrik.handleSubmit}>

                                {/* <div className="select-identity">

                                    <div className="row check">

                                        {
                                            isloading ? Array.from({ length: 2 }).map((_, i) => {
                                                return (
                                                    <div className="col-lg-6 col-md-6 col-6" key={i}><div ><Shimmer className="rounded" width={298} height={48} /></div></div>
                                                )
                                            }) : user_role ? user_role.map((role, i) => {
                                                return (
                                                    <div className="col-lg-6 col-md-6 col-6" key={i}>
                                                        <div className={role.id == Formrik.getFieldProps('role').value ? "form-check check-active my-2" : "form-check check-unactive my-2"}  >
                                                            <input className="form-check-input cust" type="radio" name="flexRadioDefault" checked={Formrik.getFieldProps('role').value == role.id} value={role.id} id={role.name} onChange={(e) => { Formrik.setFieldValue('role', role.id) }} />
                                                            <label className="form-check-label" htmlFor={role.name} >
                                                                {role.name}
                                                            </label>
                                                        </div>
                                                        {Formrik.touched.role && Formrik.errors.role ? (
                                                            <span className="text-danger">{Formrik.errors.role}</span>
                                                        ) : null}
                                                    </div>
                                                )
                                            }) : ""
                                        }

                                    </div>
                                </div> */}

                                <div className="signup-detailform">
                                    <div className="row mb-3">


                                        <div className="col-lg-6 col-md-6  col-6 py-2">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input type="text" name="firstName" placeholder="First name" className="form-control" {...Formrik.getFieldProps('first_name')} />
                                            {Formrik.touched.first_name && Formrik.errors.first_name ? (
                                                <span className="text-danger">{Formrik.errors.first_name}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6  py-2">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input type="text" name="lastName" placeholder="Last name" className="form-control" {...Formrik.getFieldProps('last_name')} />
                                            {Formrik.touched.last_name && Formrik.errors.last_name ? (
                                                <span className="text-danger">{Formrik.errors.last_name}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6 py-2">
                                            <label>Phone Number</label>
                                            <input name="Phone" placeholder="Phone number" readOnly className="form-control" {...Formrik.getFieldProps('phone_number')} />
                                            {Formrik.touched.phone_number && Formrik.errors.phone_number ? (
                                                <span className="text-danger">{Formrik.errors.phone_number}</span>
                                            ) : null}
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-6 py-2">
                                            <label>DOB <span className="text-danger">*</span></label>
                                            <input type="date" id="date" className="form-control" name="date_of_birth" {...Formrik.getFieldProps('date_of_birth')} />

                                            {/* <div className="react-date signn-react">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                        


                                                        <DatePicker
                                                            maxDate={today}
                                                            format="DD-MM-YYYY"
                                                            onChange={(newValue) => { Formrik.setFieldValue('date_of_birth', dayjs(newValue).format('YYYY-MM-DD')) }}
                                                            sx={{
                                                                backgroundColor: "#b7b2b221",
                                                                "& .MuiOutlinedInput-notchedOutline": {
                                                                    border: "none"
                                                                },
                                                                "&.Mui-focused": {
                                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                                        border: "none"
                                                                    }
                                                                }
                                                            }}
                                                            slotProps={{
                                                                field: {
                                                                    readOnly: true
                                                                }
                                                            }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                                {
                                                    Formrik.touched.date_of_birth && Formrik.errors.date_of_birth ? (<span className="text-danger">{Formrik.errors.date_of_birth}</span>) : null
                                                }
                                            </div> */}

                                        </div>



                                        <div className="col-lg-6 col-md-6 col-12 py-2">
                                            <label>Email <span className="text-danger">*</span></label>
                                            <input type="text" name="email" placeholder="Email" className="form-control" {...Formrik.getFieldProps('email')} />
                                            {Formrik.touched.email && Formrik.errors.email ? (
                                                <span className="text-danger">{Formrik.errors.email}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6 col-md-6  col-6  py-2">
                                            <label>Reference ID</label>

                                            <div className="autocomplete-container">
                                                <input
                                                    type="text"
                                                    value={inputValue}
                                                    onChange={handleChange}
                                                    onFocus={() => setIsDropdownVisible(filteredData.length > 0 && inputValue !== '')}
                                                    placeholder="Type to filter..."
                                                    className="autocomplete-input"
                                                />
                                                {isDropdownVisible && (
                                                    <div className="autocomplete-dropdown">
                                                        {filteredData.map((item) => (
                                                            <div
                                                                key={item.id}
                                                                onClick={() => handleSelect(item)}
                                                                className="autocomplete-item"
                                                            >
                                                                {item.code}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>



                                            {
                                                Formrik.touched.reference_id && Formrik.errors.reference_id ? (<span className="text-danger">{Formrik.errors.reference_id}</span>) : null
                                            }

                                        </div>

                                        {/* <div className="col-lg-6 col-md-6 col-6 py-2">
                                            <label>Password</label>
                                            <input type="password" name="password" placeholder="Password" className="form-control" {...Formrik.getFieldProps('password')} />
                                            {Formrik.touched.password && Formrik.errors.password ? (
                                                <span className="text-danger">{Formrik.errors.password}</span>
                                            ) : null}
                                        </div>
                                        <div className="col-lg-6  col-md-6 col-6 py-2">
                                            <label>Confirm Password</label>
                                            <input type="password" name="password_confirmation" placeholder="Confirm password" className="form-control" {...Formrik.getFieldProps('password_confirmation')} />
                                            {Formrik.touched.password_confirmation && Formrik.errors.password_confirmation ? (
                                                <span className="text-danger">{Formrik.errors.password_confirmation}</span>
                                            ) : null}
                                        </div> */}
                                    </div>
                                </div>

                                <div className="form-check mb-3">
                                    <input className="form-check-input" onChange={(e) => setCheck(e.target.checked)} type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label login-terms ms-2" htmlFor="flexCheckDefault">
                                        {
                                            footerpage ? (<h6>By Signing In, I agree to  <a href={`/pages/terms-conditions`} target="_blank"><span>Terms and Condition</span></a> and  <a href={`/pages/privacy-policy`} target="_blank"><span>Privacy Policy</span></a></h6>)
                                                : (<h6>By Signing In, I agree to Terms and Condition and Privacy Policy </h6>)
                                        }

                                    </label>
                                </div>

                                {/* <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Submit'}</button>
                                <div className="login-footer"> <h4 className="pt-4 text-center">Already have an account <span className="go-signup" data-bs-toggle="modal" data-bs-target="#loginmodalview">Login</span> <span className="signarrow" data-bs-toggle="modal" data-bs-target="#loginmodalview" ><ArrowRight /></span></h4></div> */}

                                <div className="login-footer text-center border-0  d-block">
                                    {
                                        // Formrik.getFieldProps('role').value === 1 ? (<button className='text-white' type="button" onClick={() => { document.getElementById('targe-nextmodal').click(); document.getElementById('closesignmodaltermspage')?.click() }}>Next</button>) :
                                        //     (<button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Submit'}</button>) 
                                        <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Submit'}</button>
                                    }

                                    {/* <h4 className="pt-4">To become a <span className="go-signup" data-bs-toggle="modal" data-bs-target="#agentsmodalview">Agent</span> <span className="signarrow" data-bs-toggle="modal" data-bs-target="#agentsmodalview" ><ArrowRight /></span></h4> */}


                                    {/* <h4 className="pt-4">Already have an account <span className="go-signup" data-bs-toggle="modal" data-bs-target="#loginmodalview">Login</span> <span className="signarrow" data-bs-toggle="modal" data-bs-target="#loginmodalview" ><ArrowRight /></span></h4> */}
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal p-0 fade signupmodal" id="agentsmodalview" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg px-lg-5 " >
                    <div className="modal-content px-md-4 px-2 py-2">
                        <div className="modal-header login-header justify-content-center border-0">
                            <button type="button" className="d-none" id="closesignmodaltermspage11" data-bs-dismiss="modal" aria-label="Close"></button>
                            <h3 className="modal-title">Create an Agent Account</h3>
                        </div>

                        <div className="modal-body login-body login-text pt-0">

                            <form onSubmit={Formrik2.handleSubmit}>
                                <div className="signup-detailform">
                                    <div className="row mb-3">

                                        <div className="col-lg-6 col-md-6  col-6 py-2">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input type="text" name="firstName" placeholder="First name" className="form-control" {...Formrik2.getFieldProps('first_name')} />
                                            {Formrik2.touched.first_name && Formrik2.errors.first_name ? (
                                                <span className="text-danger">{Formrik2.errors.first_name}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6  py-2">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input type="text" name="lastName" placeholder="Last name" className="form-control" {...Formrik2.getFieldProps('last_name')} />
                                            {Formrik2.touched.last_name && Formrik2.errors.last_name ? (
                                                <span className="text-danger">{Formrik2.errors.last_name}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6 py-2">
                                            <label>Phone Number</label>
                                            <input name="Phone" placeholder="Phone number" readOnly className="form-control" {...Formrik2.getFieldProps('phone_number')} />
                                            {Formrik2.touched.phone_number && Formrik2.errors.phone_number ? (
                                                <span className="text-danger">{Formrik2.errors.phone_number}</span>
                                            ) : null}
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-6 py-2">
                                            <label>DOB <span className="text-danger">*</span></label>
                                            <input type="date" id="date" className="form-control" name="date_of_birth" {...Formrik2.getFieldProps('date_of_birth')} />
                                            {/* <div className="react-date signn-react">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker', 'DatePicker']}>

                                                        <DatePicker
                                                            maxDate={today}
                                                            format="DD-MM-YYYY"
                                                            onChange={(newValue) => { Formrik2.setFieldValue('date_of_birth', dayjs(newValue).format('YYYY-MM-DD')) }}
                                                            sx={{
                                                                backgroundColor: "#b7b2b221",
                                                                "& .MuiOutlinedInput-notchedOutline": {
                                                                    border: "none"
                                                                },
                                                                "&.Mui-focused": {
                                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                                        border: "none"
                                                                    }
                                                                }
                                                            }}
                                                            slotProps={{
                                                                field: {
                                                                    readOnly: true
                                                                }
                                                            }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                                {
                                                    Formrik2.touched.date_of_birth && Formrik2.errors.date_of_birth ? (<span className="text-danger">{Formrik2.errors.date_of_birth}</span>) : null
                                                }
                                            </div> */}

                                        </div>



                                        <div className="col-lg-6 col-md-6 col-6 py-2">
                                            <label>Email <span className="text-danger">*</span></label>
                                            <input type="text" name="email" placeholder="Email" className="form-control" {...Formrik2.getFieldProps('email')} />
                                            {Formrik2.touched.email && Formrik2.errors.email ? (
                                                <span className="text-danger">{Formrik2.errors.email}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6 col-md-6  col-6  py-2">
                                            <label>Reference ID</label>


                                            <div className="autocomplete-container">
                                                <input
                                                    type="text"
                                                    value={inputValue}
                                                    onChange={handleChange}
                                                    onFocus={() => setIsDropdownVisible(filteredData.length > 0 && inputValue !== '')}
                                                    placeholder="Type to filter..."
                                                    className="autocomplete-input"
                                                />
                                                {isDropdownVisible && (
                                                    <div className="autocomplete-dropdown">
                                                        {filteredData.map((item) => (
                                                            <div
                                                                key={item.id}
                                                                onClick={() => handleSelect(item)}
                                                                className="autocomplete-item"
                                                            >
                                                                {item.code}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                            {
                                                Formrik2.touched.reference_id && Formrik2.errors.reference_id ? (<span className="text-danger">{Formrik2.errors.reference_id}</span>) : null
                                            }

                                        </div>

                                        {/* <div className="col-lg-6 col-md-6 col-6 py-2">
                                            <label>Password</label>
                                            <input type="password" name="password" placeholder="Password" className="form-control" {...Formrik2.getFieldProps('password')} />
                                            {Formrik2.touched.password && Formrik2.errors.password ? (
                                                <span className="text-danger">{Formrik2.errors.password}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6  col-md-6 col-6 py-2">
                                            <label>Confirm Password</label>
                                            <input type="password" name="password_confirmation" placeholder="Confirm password" className="form-control" {...Formrik2.getFieldProps('password_confirmation')} />
                                            {Formrik2.touched.password_confirmation && Formrik2.errors.password_confirmation ? (
                                                <span className="text-danger">{Formrik2.errors.password_confirmation}</span>
                                            ) : null}
                                        </div> */}
                                    </div>
                                </div>

                                <div className="form-check mb-3">

                                    <input className="form-check-input" checked={check} onChange={(e) => setCheck(e.target.checked)} type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label login-terms ms-2" htmlFor="flexCheckDefault">
                                        {
                                            footerpage ? (<h6>By Signing In, I agree to  <a href={`/pages/terms-conditions`} target="_blank"><span>Terms and Condition</span></a> and  <a href={`/pages/privacy-policy`} target="_blank"><span>Privacy Policy</span></a></h6>)
                                                : (<h6>By Signing In, I agree to Terms and Condition and Privacy Policy </h6>)
                                        }

                                    </label>
                                </div>



                                <div className="login-footer text-center border-0  d-block">
                                    {
                                        <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Submit'}</button>
                                    }

                                    {/* <h4 className="pt-4">Already have an account <span className="go-signup" data-bs-toggle="modal" data-bs-target="#loginmodalview">Login</span> <span className="signarrow" data-bs-toggle="modal" data-bs-target="#loginmodalview" ><ArrowRight /></span></h4> */}
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="extra-agentmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <button type="button" className="btn btn-primary d-none" id="targe-nextmodal" data-toggle="modal" data-target="#extra-agentmodal">
                </button>
                <div className="modal-dialog signupmodal modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content px-3 py-4">
                        <div className="modal-header signupaftermodal login-header justify-content-center border-0">
                            <h3 className="modal-title">Additional Information</h3>
                        </div>
                        <div className="modal-body login-body login-text">
                            <div className="signup-detailform">
                                <form onSubmit={formik2.handleSubmit}>
                                    <div className="row mb-3">
                                        <div className="col-lg-6 col-md-6 col-6 py-2">
                                            <label>No.of.people contract </label>
                                            <input type="text" className="form-control" value={peoplecount} onChange={(e) => { setpeoplecount(e.target.value?.replace(/[^0-9]/g, "")) }} placeholder="No.of.people" {...formik2.getFieldProps('people_contract')} />
                                            {
                                                formik2.errors.people_contract && formik2.touched.people_contract ? <span className="text-danger">{formik2.errors.people_contract}</span> : null
                                            }
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6 py-2">
                                            <label>Why You Join</label>
                                            <input type="text" className="form-control" placeholder="Why You Join"  {...formik2.getFieldProps('whyjoin')} />
                                            {
                                                formik2.errors.whyjoin && formik2.touched.whyjoin ? <span className="text-danger">{formik2.errors.whyjoin}</span> : null
                                            }
                                        </div>
                                        <div className="col-lg-6 col-md-6  col-6 py-2">
                                            <label>Present Working Detail</label>
                                            <input type="text" className="form-control" placeholder="Working Detail"  {...formik2.getFieldProps('working_detail')} />
                                            {
                                                formik2.errors.working_detail && formik2.touched.working_detail ? <span className="text-danger">{formik2.errors.working_detail}</span> : null
                                            }
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6  py-2">
                                            <label>Previous Experience</label>
                                            <select class="form-select" aria-label="Default select example" {...formik2.getFieldProps('experience')} >
                                                <option selected >select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            {
                                                formik2.errors.experience && formik2.touched.experience ? <span className="text-danger">{formik2.errors.experience}</span> : null
                                            }
                                        </div>

                                    </div>

                                    <p className="text-primary" style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('targe-nextmodal').click(); document.getElementById('gobacksign')?.click() }}> <span className="text-primary mx-2"><ArrowLeft /></span>Go Back</p>

                                    <div className="loginmodal">
                                        <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Submit'}</button>
                                    </div>
                                </form>

                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Signup;