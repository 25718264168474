import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { baseurl, setting } from '../api/Pageapi'
import ContactUs from '../pages/ContactUs'
import AOS from 'aos'

export default function Footer({ setting_datas, footerpages }) {

  const router = useNavigate()

  useEffect(() => {
    AOS.init({
      duration: 1500
    });
  }, []);


  return (
    <footer>
      <div className='top_foot d-none gap-sm-3 flex-wrap gap-4' >
        <h2 className='fw-bold text-dark mb-0'>Start investing in gold today!</h2>
        <button type='button' className='btn'>SIGNUP</button>
      </div>

      <div className='bot_foot position-relative footer_bg'>
        <div className='container'>
          <div className='row'>
            {
              !setting_datas[0] ? (<>
                <div className="col-md-12 ">
                  <div className="tableload  mb-tableload text-center">
                    <div class="spinner-border text-primary text-center" role="status"></div>
                  </div>
                </div>

              </>) : (<>
                <div className='col-lg-3 col-md-6 col-sm-6'>
                  <div className='mb-3'>
                    <img src={setting_datas[0]?.logo ?? '/assets/images/green.png'} alt='' className='mainIcon' style={{ cursor: "pointer" }} onClick={() => { router('/') }} />
                  </div>
                  <div>
                    {/* <h6 className='text-wrap lh-base'>
                          Greenheap Gold And Silver Jewellery Private Limited. is an organised and transparent method of buying and accumulating 24K physical gold in compliance with all applicable laws and regulations.
                        </h6> */}

                    <h6 className='text-wrap lh-base footer_contents'>
                      {setting_datas[0]?.footer_content}
                    </h6>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6'>
                  <div className='mb-3 ft-md-head '>
                    <h5 className='mt-4'>Useful Links</h5>
                  </div>
                  <div className='d-flex align-items-center justify-content-md-start justify-content-lg-start'>
                    <div className='uselink'>
                      <Link to="/about"> <h6 className='text-wrap lh-base'>About Us</h6></Link>
                      {/* <Link to="/Faq"> <h6 className='text-wrap lh-base'>Faq</h6></Link> */}
                      {
                        footerpages ? footerpages.map((pages, i) => {
                          return (
                            <>
                              <h6 className='text-wrap lh-base' style={{ cursor: 'pointer' }} onClick={() => { router(`/pages/${pages.slug}`) }} key={i}>{pages.name}</h6>
                            </>
                          )
                        }) : ''
                      }

                      <Link to="/contact"> <h6 className='text-wrap lh-base'>Contact Us</h6></Link>

                    </div>
                  </div>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-6 '>
                  <div className='mb-3  ft-md-head '>
                    <h5 className='mt-4 '>Contact Us</h5>
                  </div>
                  <div className='  ft-md-lists '>
                    <div className='d-flex gap-2  folle mb-2' >
                      <img alt='' src='/assets/iocns/Location.png' />
                      {/* <h6 className='text-wrap lh-base'>Sri Ananth, GF No:1B, 11th Sector, 66th Street, KK Nagar, Chennai - 600078</h6> */}
                      <h6 className='text-wrap lh-base'>{setting_datas[0]?.address1}, {setting_datas[0]?.address2}, <br></br> {setting_datas[0]?.state}, {setting_datas[0]?.city}, {setting_datas[0]?.country}.</h6>
                    </div>

                    <div className='d-flex gap-2  folle mb-2'>
                      <img alt='' src='/assets/iocns/Mail_1.png' />
                      {/* <h6 className='text-wrap lh-base'>vintagehealthchennai@gmail.com</h6> */}
                      <a href={`mailto:${setting_datas[0]?.support_mail}`} data-rel="external"    ><h6 className='text-wrap lh-base'>{setting_datas[0]?.support_mail}</h6></a>
                    </div>

                    <div className='d-flex gap-2  folle'>
                      <img alt='' src='/assets/iocns/call 1.png' />
                      {/* <h6 className='text-wrap lh-base'>04423664425, 81900 59995</h6> */}
                      <a href={`tel:+${setting_datas[0]?.phone}`} data-rel="external" ><h6 className='text-wrap lh-base'>{setting_datas[0]?.phone}</h6></a>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 folle'>
                  <div className='mb-3'>
                    <h5 className='mt-4'>Follow Us</h5>
                  </div>
                  <div className='d-flex align-items-center justify-content-start gap-3'>
                    <a href={setting_datas[0]?.facebook} target='_blank' rel="noreferrer">
                      <div className='footer_social_icons'>
                        <img alt='' src='/assets/iocns/Facebook.png' />
                      </div>
                    </a>
                    <a href={setting_datas[0]?.instagram} target='_blank' rel="noreferrer">
                      <div className='footer_social_icons'>
                        <img alt='' src='/assets/iocns/insta.png' />
                      </div>
                    </a>
                    <a href={setting_datas[0]?.twitter} target='_blank' rel="noreferrer">
                      <div className='footer_social_icons'>
                        <img alt='' src='/assets/iocns/X.png' />
                      </div>
                    </a>
                    <a href={setting_datas[0]?.youtube} target='_blank' rel="noreferrer">
                      <div className='footer_social_icons'>
                        <img alt='' src='/assets/iocns/Youtube.png' />
                      </div>
                    </a>
                    <a href={setting_datas[0]?.linkedin} target='_blank' rel="noreferrer">
                      <div className='footer_social_icons'>
                        <img alt='' src='/assets/iocns/Linkedin.png' />
                      </div>
                    </a>
                  </div>

                  <div className='play_img_flx'>
                    <a href="https://play.google.com/" target='_blank'>
                      <div className='play_img'>
                        <img alt='' src='/assets/images/black-play-img.png' />
                      </div>
                    </a>

                    <a href="https://www.apple.com/in/app-store/" target='_blank'>
                      <div className='play_img'>
                        <img alt='' src='/assets/images/black-store-img.png' />
                      </div>
                    </a>
                  </div>
                </div>

              </>)
            }
          </div>
        </div>

        {/* <a target="_blank" href="https://api.whatsapp.com/send?phone=918190059995">
          <div className='whatsapp_icon'>
            <img alt='image' src='/assets/iocns/whatsapp_icon.png' />
          </div>
        </a> */}

        <a target="_blank" href={`https://api.whatsapp.com/send?phone=${decodeURIComponent(setting_datas[0]?.phone).replace(/\D/g, '')}`}>
          <div className='whatsapp_icon'>
            <img alt='image' src='/assets/iocns/whatsapp_icon.png' />
          </div>
        </a>



        <div className='hepl_text help1' data-aos="fade-left">
          <a href="#">
            <h6 className='mb-0'>Help & Support</h6>
          </a>
        </div>

        <div className='hepl_text help2'>
          <a href="#">
            <h6 className='mb-0'>Help & Support</h6>
          </a>
        </div>

      </div>





      <div className='top_nav'>
        <div className='container w-100 d-flex justify-content-center'>
          <p className='mb-0'>© 2024 Greenheap Gold And Silver Jewellery Private Limited.</p>
        </div>
      </div>
    </footer>
  )
}
