import React from 'react'

export default function HeadingBanner({title}) {
  return (
    <section>
      <div className='head_bn'>
      <img src= '/assets/images/banner 2.png'   /> 
        <div className='sdd position-absolute top-0' style={{backdropFilter:'brightness(0.5)',width:'100%',height:'100%'}}>
        </div>
        <div className='position-absolute top-50 start-50 text-center translate-middle'>
          <h2 className=''>{title}</h2>
        </div>
      </div>
    </section>
  )
}
