import axios from "axios"; 


const logout =()=>{
    localStorage.removeItem("green_login"); 
    localStorage.removeItem("phone")
    localStorage.removeItem("green_token"); 
    localStorage.removeItem("kyc");
}

const defaultOptions = {
    baseURL:  process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "multipart/form-data" ,
      Accept: 'application/json',
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('green_token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

  instance.interceptors.response.use(
    (response) => { 
        if(response.status == 401 || response.status == 403 || response.data?.status == 403) {
          logout(); 
        } 
        return response;  
    },
    (error) => {
        return Promise.reject(error);
    }
  )

  export default instance;