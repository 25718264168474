"use client"
import React, { useContext, useEffect, useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { Logindetail } from "../context/Logindetail"
import { getInvoiceDown, getShemesSchedule, postMonthShec, SentRequest } from "../api/Pageapi"
import moment from "moment/moment"
import useRazorpay from "react-razorpay";
import toast from 'react-hot-toast';
import { Image, Shimmer } from 'react-shimmer'
import { Getdigital_trans } from "../api/RouteScheme2"
import { GetGoldPlantApi } from "../api/RouteScheme3"
import { getCommisionApi } from "../api/Dashboardapi"



export default function ClientDashboard({ setting_data }) {

    const router = useNavigate()



    // const RAZAR_KEY = process.env.REACT_APP_RAZAR_KEY;

    const RAZAR_KEY = "rzp_live_FZ6iVw5tcRGpW5";

    // const RAZAR_KEY = "rzp_test_K7CipNQYyyMPiS";


    const { token, detail_info, setdetail_info } = useContext(Logindetail)

    const [Shcudle, setShcudle] = useState([])
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [shemData, setSchemaData] = useState();
    const [isLoading, setisLoading] = useState(true);
    const [digita_shedule, setdigital_shedule] = useState([])
    const [gold_shedule, setgold_shedule] = useState([])

    const [data, setdata] = useState([])
    const [agent_earning, setAgentEarning] = useState(0)



    const fetchCommision = () => {
        setisLoading(true)

        getCommisionApi().then(res => {
            setdata(res.data);
            setisLoading(false)

            setAgentEarning(res?.agent_earning)

        }).catch(err => {
            console.log("err", err.message);
            setisLoading(false)
        })
    }

    console.log('asdasdsadasdasdasdsdaasd', data);


    const handleGetSchema = () => {
        setisLoading(true)
        getShemesSchedule(token).then(res => {
            setShcudle(res);
            setisLoading(false)
        }).catch(err => {
            console.log('err', err.message);
            setisLoading(false)
        })
    }

    const handleDigitalSchmea = () => {
        Getdigital_trans()
            .then((res) => {
                setdigital_shedule(res)
                setisLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setisLoading(false)
            })
    }

    const handleGoldGetSchema = () => {
        setisLoading(true)
        GetGoldPlantApi(token).then(res => {
            setgold_shedule(res)
            setisLoading(false)
        }).catch(err => {
            setisLoading(false)
        })
    }

    const handlePayment = useCallback(async () => {
        const options = {
            key: RAZAR_KEY,
            amount: (shemData?.amount) * 100,
            currency: "INR",
            name: 'Green Heap',
            description: 'Green Heap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "amount": shemData?.amount,
                    "purchase_id": shemData?.id,
                    "transaction_id": res?.razorpay_payment_id,
                }
                postMonthShec(body, token).then(res => {
                    // navigate('/dashboard');
                    handleGetSchema();
                    toast.success("Payment success!")
                    setShowRazorpayModal(false);
                }).catch(err => {
                    console.log('err', err.message)
                })
            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [shemData]);

    useEffect(() => {
        if (showRazorpayModal) {
            handlePayment();
        }
    }, [showRazorpayModal, handlePayment]);

    useEffect(() => {
        handleGetSchema();
        handleDigitalSchmea()
        handleGoldGetSchema()
        fetchCommision()
    }, []);

    const handledownload = (value) => {
        const baseurl = process.env.REACT_APP_BASE_URL
        let fileName = `${value?.paid_date}-invoice.pdf`;
        const url = `${baseurl}generate_invoice?id=${value?.id}`;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('Error fetching PDF: ', error);
                window.open(url, "_blank");
            });
    }



    // const triggerAgentRequset = () => {

    //     SentRequest(token)
    //         .then((res) => {
    //             if (res.type == 'success') {
    //                 toast.success(res.data)
    //                 document.getElementById('agentBtn').innerText = 'Request submitted';
    //             } else {
    //                 toast.error(res.data)
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })

    // }


    const triggerAgentRequset = () => {

        SentRequest(token)
            .then((res) => {
                if (res.type == 'success') {

                    toast.success(res.data)
                    document.getElementById('agentBtn').innerText = 'The request has been sent and is awaiting approval from the administration.';

                    const greenLogin = JSON.parse(localStorage.getItem('green_login'));
                    if (greenLogin.agent_request == 0) {
                        greenLogin.agent_request = 1;
                        localStorage.setItem('green_login', JSON.stringify(greenLogin));
                    }

                } else {
                    toast.error(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }


    // const handlegetprofile = () => {
    //     getprofile_fun()
    //       .then((res) => {
    //         localStorage.setItem("green_login", JSON.stringify(res?.data));
    //         setdetail_info(res?.data)
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //   }


    return (
        <div className="dashbord-content">
            <div className="customer-name">
                <h4>#<span> {detail_info?.code} </span></h4>
            </div>

            <div className="customer-balance my-3">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="balance-view1 position-relative">
                            <div className="balance-view2">
                                <div className="balance-view3 d-flex align-itmes-center justify-content-between position-absolute">
                                    <h4>{detail_info?.role === 1 ? 'Agent Earnings' : 'Available balance'}</h4>
                                    <h4>{
                                        // isLoading ? <div><Shimmer className="rounded" width={80} height={20} /></div> : data ? `₹${data?.reduce((accumulator, current) => parseInt(accumulator) + parseInt(current?.commission), 0)}` : 0
                                        isLoading ? <div><Shimmer className="rounded" width={80} height={20} /></div> : `₹${agent_earning}`
                                    }</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="bonus-view">
                            <div className="row">
                                <div className="col-lg-8 col-md-6 col-sm-8 col-8">
                                    <div className="bonus">
                                        <h4>Welcome</h4>
                                        <h5 className="pb-2 pt-1">Greenheap<span>gold</span></h5>
                                        {
                                            (detail_info?.role == 2) ? (detail_info?.agent_request != 1 ? <button id="agentBtn" onClick={triggerAgentRequset} className="btn btn-dark text-white">Become a Agent</button> : <button onClick={triggerAgentRequset} className="btn btn-dark text-white">Approval from the administration is currently pending.</button>) : (detail_info?.role == 1 ? <button id="agentBtn" className="btn btn-success text-white">The administration has approved the agent's request</button> : null)

                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                                    <div className="bonus-pic">
                                        <img src="\assets\images\store.png" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className=" col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-6" >
                    <div className="post-bg1 d-flex flex-column justify-content-between w-100">
                        <div className="totalprop">
                            <span className="one_line">{setting_data[0]?.saving_plan}</span>
                        </div>
                        {
                            isLoading ? <h4>{<Shimmer className="rounded" width={80} height={20} />}</h4> : <h4 className="pt-2 totaltrans">{Shcudle ? Shcudle?.total : '0'}</h4>
                        }
                        <div className="d-flex justify-content-between align-items-center">
                            {/* <h4 className="mb-0">65</h4> */}
                            <button type="button" onClick={() => router('/user/chit-jewels')} className="btn btn-warning">View All</button>
                            <img src="/assets/iocns/Buy.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className=" col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-6" >
                    <div className="post-bg2 d-flex flex-column justify-content-between  w-100">
                        <div className="totalprop">
                            <span className="one_line">{setting_data[0]?.digital_plan}</span>
                        </div>
                        {
                            isLoading ? <h4>{<Shimmer className="rounded" width={80} height={20} />}</h4> : <h4 className=" totaltrans pt-2">{digita_shedule ? digita_shedule?.total : '0'}</h4>
                        }

                        <div className="d-flex justify-content-between align-items-center">
                            <button type="button" onClick={() => router('/user/digital-gold')} className="btn btn-warning">View All</button>
                            <img src="/assets/iocns/Buy.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className=" col-xl-4 col-lg-6 mb-4 col-md-6 col-sm-6" >
                    <div className="post-bg3 d-flex flex-column justify-content-between  w-100">
                        <div className="totalprop">
                            <span className="one_line">{setting_data[0]?.gold_plant}</span>
                        </div>
                        {
                            isLoading ? <h4>{<Shimmer className="rounded" width={80} height={20} />}</h4> : <h4 className=" totaltrans pt-2">{gold_shedule ? gold_shedule?.total : '0'}</h4>
                        }

                        <div className="d-flex justify-content-between align-items-center">
                            <button type="button" onClick={() => router('/user/gold-plant')} className="btn btn-warning">View All</button>
                            <img src="/assets/iocns/Buy.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            {/* { isLoading ? 
           <div className="your-sipscheme mb-3"  >
                <div className="sip-title d-flex align-items-center gap-2">
                    <h4 className="m-0"><Shimmer width={130} height={24} /></h4>
                </div>
                <div className="sip-subtitle d-flex align-items-center justify-content-between mt-3">
                    <h4><Shimmer width={200} height={22} /></h4>
                    <h5><Shimmer width={60} height={24} /></h5>
                </div>
                <div className="sip-schemeplan my-2">
                    <div className="row"> 
                        {
                            Array.from({length : 12}).map((_,i) => (
                                <div className="col-xl-3 col-lg-4 col-sm-6 mb-3" key={i}>
                                <Shimmer width={186} height={68} />
                                </div> 
                            ))
                        }
                    </div>
                </div>
                <div className="sip-tc">
                    <h5 ><Shimmer width={200} height={24} /></h5>
                </div>
            </div>
         :
            Shcudle?.length > 0 ? 
            Shcudle?.map((value, index) => (
                <div className="your-sipscheme mb-3" key={index} onClick={()=> setSchemaData(value)}>
                    <div className="sip-title d-flex align-items-center gap-2">
                        <h4 className="m-0">Your Scheme</h4>
                        {
                         value?.purchase_status == 0 ? 
                            <div className="actived-sip d-flex align-items-center gap-2">
                                <img src="\assets\iocns\Tick 1.png" />
                                <h5 className="m-0">Activated</h5>
                            </div> : ''
                        }
                    </div>
                    <div className="sip-subtitle d-flex align-items-center justify-content-between mt-3">
                        <h4>{value?.saving_plan?.title ?? ''}</h4>
                        <h5>₹{value?.amount ?? ''}</h5>
                    </div>
                    <div className="sip-schemeplan my-2">
                        <div className="row"> 
                            {
                              value?.purchase_transaction?.length > 0 ? 
                                value?.purchase_transaction?.map((data, i) => (
                                    <div className=" col-lg-4 col-sm-6" key={i}>
                                        <div className={`plan1  sp${(i+1) > 6 ? (value?.purchase_transaction?.length - i) : i+1} border rounded d-flex align-items-center justify-content-between`}>
                                            <div className="plan-time ">
                                                <p className="fw-bold">Schedule Date :</p>
                                                <p>({data?.schedule_date})</p>
                                            </div>
                                            <div className="plan-rate ">
                                                {  
                                                    data?.status == "unpaid" &&  moment(data?.schedule_date, "YYYY-MM-DD").month() == moment(new Date()).month()  ?  
                                                    <button type="button" onClick={()=> {setShowRazorpayModal(true)}} className="btn paynsd">PAY NOW</button> :  data?.status == "paid" ?
                                                    <img src="\assets\iocns\invoice.png" onClick={()=> handledownload(data)} className="ytjsfvdjd" />: data?.admin_paid == 1 ? 
                                                    <button type="button" disabled className="btn btn-warning">UNPAID</button> : <button type="button" disabled className="btn btn-secondary">UNPAID</button>
                                                }
                                            </div>
                                        </div>
                                    </div> 
                                ))
                            : ''
                            }
                        </div>
                    </div>
                    <div className="sip-tc">
                        <h5 >T&C* Apply Minimum 1gram Above Applicable</h5>
                    </div>
                </div> 
            ))
            : ''
        } */}



        </div>
    )
}

