import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Grid, Autoplay } from "swiper/modules";
import Schemes from '../Components/Schemes.jsx';
import FAQ from '../Components/FAQ.jsx';
import Chart from '../Components/Chart.jsx';
import { Digitalgold_fun, Enroll_fun, homebannerapi, Investing_fun, whychoose } from '../api/Pageapi.js';
import { Liveprice } from '../Components/Liveprice.jsx';
import { Shimmer } from 'react-shimmer';
import Aos from 'aos'
import { useNavigate } from 'react-router-dom';

import { trestiAPi } from '../api/Controllapi.js';


export default function Home({ setopenScheme, setting_datas }) {


  const [homebanner, sethomebanner] = useState([])
  const [Isloading, setIsloading] = useState(true)
  const [ChooseData, setChooseData] = useState()
  const [Enroll_data, setEnroll_data] = useState()
  const [Investing_data, setInvesting_data] = useState()
  const [Digitalgold_data, setDigitalgold_data] = useState()
  const router = useNavigate()

  const [testimoanl_data, settestimoanl_data] = useState([])




  const gethomebannerapi = async () => {


    homebannerapi()
      .then((res) => {
        sethomebanner(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })

  }

  const getWhyChoose = () => {
    whychoose()
      .then((res) => {
        setChooseData(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }

  const getenroll = () => {
    Enroll_fun()
      .then((res) => {
        setEnroll_data(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }

  const getinvesting = () => {
    Investing_fun()
      .then((res) => {
        setInvesting_data(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }

  const getdigitalgold = () => {
    Digitalgold_fun()
      .then((res) => {
        setDigitalgold_data(res?.data)
        setIsloading(false)

      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }



  const fetchData = async () => {
    await Promise.all([
      gethomebannerapi(),
      getWhyChoose(),
      // getenroll(),
      getinvesting(),
      getdigitalgold()
    ])
  }

  useEffect(() => {
    fetchData()
    getenroll()
    testimonal_fun()
  }, [])


  const testimonal_fun = async () => {
    trestiAPi()
      .then((res) => {
        setIsloading(true)
        settestimoanl_data(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }



  return (
    <>
      {
        Isloading ? (<div>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            className="main_slider"
          >
            {
              Array.from({ length: 3 }).map((_, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div><Shimmer height={600} className='w-100' />  </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>

        </div>)

          : homebanner ? (
            <section className='home_slider'>
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="main_slider"
              >
                {
                  homebanner.map((res, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div>
                          <img alt='' src={res.image} className='w-100' />
                        </div>
                      </SwiperSlide>

                    )
                  })
                }
              </Swiper>

              <Liveprice settings_datas={setting_datas} />
            </section>

          ) : ''
      }



      <section className='schemebar home_sceme'>
        <Schemes setopenSchemes={setopenScheme} setting_data={setting_datas} />
      </section>

      <section className='center_banner mb-0'>
        <div className='center_banner_img position-relative'>
          <img src='\assets\images\red_banner.png' />
          <div className='container'>
            <a href="\gold-silver" className='ex1'>
              <div className='ex_btn'>
                <button>Explore<img src='\assets\iocns\right arrow round.png' /></button>
              </div>
            </a>

            <a href="\gold-silver" className='ex2'>
              <div className='ex_btn'>
                <img src='\assets\iocns\arrow-right.png' />
              </div>
            </a>


          </div>
        </div>
      </section>

      {/* <section  >
        <div className='container smallBan d-none d-sm-block'>
          <div className='row  '>

            {
              Isloading ? Array.from({ length: 3 }).map((_, i) => {

                return (

                  <div className='col-lg-4 col-md-4 col-sm-4  col-6 mb-2 scheme-ban '>
                    <Shimmer className='rounded w-100' height={350} />
                  </div>
                )

              }) : Enroll_data ? Enroll_data.map((data) => {
                return (
                  <div className='col-lg-4 col-md-4 col-sm-4  col-6 mb-2 ' data-aos="flip-left">
                    <div className='text-center my-sm-2 my-md-0'>
                      <img alt='' src={data?.image} className='' />
                    </div>
                  </div>
                )
              }) : ''
            }
          </div>
        </div>
      </section> */}

      {/* <Chart /> */}

      <section className='py-3'>
        <div className='container'>
          <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
            <div>
              <div data-aos="fade-right">
                <img src='/assets/images/shpas.png' />
              </div>
              <div data-aos="fade-left">
                <h2 className='fw-bold'>Clients Say's?</h2>
              </div>
            </div>
          </div>
        </div>


        {
          Isloading ? (
            <div className='says_c'>
              <div className="container">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  loop={true}
                  autoplay={{
                    delay: 2500, // delay between slides in ms
                    disableOnInteraction: false, // to ensure autoplay continues after interaction
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1
                    },
                    576: {
                      slidesPerView: 2
                    },
                    992: {
                      slidesPerView: 2
                    },
                    1200: {
                      slidesPerView: 3
                    }
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination, Autoplay]}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  className="main_slider"
                >
                  {
                    Array.from({ length: 5 }).map((_, i) => {
                      return (
                        <SwiperSlide>
                          <div className='pshycal h-100 border client-shim' key={i}>
                            <div className='d-flex gap-2 align-items-center mb-3'>
                              <div className='border-circle'><Shimmer className='rounded' width={50} height={50} /></div>
                              <div>
                                <h5 className='fw-bold'><Shimmer className='rounded' width={60} height={20} /></h5>
                                <h6><Shimmer className='rounded' width={60} height={20} /></h6>
                              </div>
                            </div>
                            <p className='lh-base'><Shimmer className='rounded' width={300} height={70} /></p>
                          </div>
                        </SwiperSlide>
                      )
                    })
                  }


                </Swiper>
              </div>
            </div>
          ) : (

            <div className='says_c'>
              <div className="container">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  loop={true}
                  autoplay={{
                    delay: 2500, // delay between slides in ms
                    disableOnInteraction: false, // to ensure autoplay continues after interaction
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1
                    },
                    576: {
                      slidesPerView: 2
                    },
                    992: {
                      slidesPerView: 2
                    },
                    1200: {
                      slidesPerView: 3
                    }
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination, Autoplay]}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  className="main_slider"
                >
                  {
                    testimoanl_data.map((data, i) => {
                      return (
                        <SwiperSlide key={i}>
                          <div className='pshycal h-100 border'>
                            <div className='d-flex gap-2 align-items-center mb-3'>
                              <img src={data?.user_image} className='rounded-circle' alt='' />
                              <div>
                                <h5 className='fw-bold'>{data?.user_name}</h5>
                                <h6>{data?.city_name}</h6>
                              </div>
                            </div>
                            <p className="lh-base four_line" dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                          </div>
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
              </div>
            </div>

          )
        }

      </section>



      <section>
        <div className='container'>
          <div className='wealth_smart d-md-flex align-items-center justify-content-between d-sm-block'>
            {
              Isloading ? (<>

                <div className='d-flex gap-3 align-items-center'>
                  <Shimmer width={60} height={60} className='rounded' />
                  <div>
                    <h2><Shimmer width={150} height={20} className='rounded' /></h2>
                    <h5><Shimmer width={250} height={40} className='rounded' /></h5>
                  </div>
                </div>
                <div>
                  <div><Shimmer width={200} height={50} className='rounded' /> </div>
                </div>
              </>) :

                Investing_data ? (
                  <>
                    <div data-aos="fade-up-right">
                      <div className='d-flex gap-3 align-items-center'>
                        <img alt='' src={Investing_data?.image} />
                        <div>
                          <h2>{Investing_data?.title}</h2>
                          <h5>{Investing_data?.subtitle}</h5>
                        </div>
                      </div>
                    </div>
                    <div data-aos="fade-up-left">
                      <div>
                        <button type='button' onClick={() => { router('/scheme') }} className='btn d-flex align-items-center mt-3 mt-md-0 gap-2'>{Investing_data?.button_name} <img alt='' src='/assets/iocns/Arrow right.png' className='smalImg' /> </button>
                      </div>
                    </div>
                  </>

                ) : ""
            }

          </div>
        </div>
      </section>

      <section className='schema2 position-relative ' >
        <div className='container'>
          <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
            <div>
              <div data-aos="fade-right">
                <img alt='' src='/assets/images/shpas.png' />
              </div>
              <div data-aos="fade-left">
                <h2 className='fw-bold'>Why choose digital gold </h2> <h2 className='fw-bold'> Over physical gold?</h2>
              </div>
            </div>
          </div>

          <div className='row'>


            <div className='col-xl-3 col-lg-6 col-sm-6 col-sm-6 mb-3 activescheme'>
              <div className='sffsfsf'>
                <img alt='' src='/assets/images/physical.png' className='' />
              </div>
            </div>

            {
              Isloading ? Array.from({ length: 3 }).map((_, i) => {

                return (
                  <div className='col-xl-3 col-lg-6 col-sm-6 mb-3 mb-3' key={i}>
                    <div className='pshycal h-100'>
                      <Shimmer width={70} height={60} className='rounded' />
                      <h5 className='text-wrap fw-bold mt-2 w-md-50 w-sm-100'><Shimmer width={120} height={25} className='rounded' /></h5>
                      <p className='lh-base whych-shim'><Shimmer width={250} height={35} className='rounded' /></p>
                    </div>
                  </div>
                )

              })

                : ChooseData ? ChooseData?.slice(0, 3)?.map((data, i) => (
                  < div className='col-xl-3 col-lg-6 col-sm-6 mb-3 col-12' key={i}>
                    <div className='pshycal h-100' data-aos="zoom-in">
                      <img alt='' src={data?.icon} />
                      <h5 className='text-wrap fw-bold mt-2 w-md-50 w-sm-100'>{data?.title}</h5>
                      <p className='lh-base three_line' dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                    </div>
                  </div>
                )
                ) : ''

            }


          </div>
        </div>
      </section>

      <section className='covert'>
        <div className='container'>
          <div className='row'>
            {
              Isloading ? (<>
                <div className='col-xl-5 col-lg-6'>
                  <h3 className='text-wrap fw-bold mb-3 convert-head' ><Shimmer className='rounded w-75 h-75' /></h3>
                  <h5 className='text-wrap mb-3' ><Shimmer width={300} height={30} className='rounded' /></h5>
                  <p className='lh-base mb-3'><Shimmer width={400} height={150} className='rounded' /></p>
                  <div><Shimmer width={160} height={50} className='rounded' /></div>
                </div>

                <div className='col-xl-7 col-lg-6 d-flex justify-content-center'>
                  <div className='d-flex justify-content-center w-100'>
                    <Shimmer className='rounded w-100 h-100' />
                  </div>
                </div>
              </>) : Digitalgold_data ?
                (<> <div className='col-xl-5 col-lg-6'>
                  <div data-aos="fade-right">
                    <h3 className='text-wrap  convert-head fw-bold mb-3' >{Digitalgold_data?.title}</h3>
                    <h5 className='text-wrap convert-sb-head mb-3' >{Digitalgold_data?.subtitle}</h5>
                    <p className='lh-base convert-para mb-3'>{Digitalgold_data?.description}</p>

                    <button type='button' className='btn d-flex align-items-center buygoldbtn gap-2' onClick={() => { router('/gold-silver') }}>{Digitalgold_data?.button_name} <img alt='' src='/assets/iocns/Arrow right.png' className='smalImg' /> </button>
                  </div>
                </div>

                  <div className='col-xl-7 col-lg-6 d-flex justify-content-center'>
                    <div data-aos="fade-left">
                      <div className='d-flex justify-content-center w-100'>
                        <img alt='' src={Digitalgold_data?.image} className='img-fluid' />
                      </div>
                    </div>
                  </div></>) : ''
            }
          </div>
        </div>
      </section>



      <FAQ setting_data={setting_datas} />


    </>
  )
}
