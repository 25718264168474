import React, { useEffect, useState } from "react"
import { ArrowRight, Envelope } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from "formik"
import { Logindetail } from "../../context/Logindetail"
import { useContext } from "react"
import { Forgatpwd } from "./Forgatpwd"
import { Login_fun } from "../../api/Controllapi"
import { PagesApi } from "../../api/Pageapi"
import toast from "react-hot-toast"
import { Footerpages } from "../../context/Footerpages"
import { getprofile_fun, checkUserRoleApi } from "../../api/Dashboardapi"
import OTPForm from "./Otp"
import { authentication } from "../firebase"
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth"
// import 'react-phone-input-2/lib/material.css'

const ERROR_CODES = {
  'auth/user-not-found': 'user Not Found',
  'auth/wrong-password': 'invalidPassword',
  'auth/email-already-in-use': 'emailInUse',
  'auth/invalid-email': 'invalidEmail',
  'auth/user-disabled': 'userAccountDisabled',
  'auth/too-many-requests': 'tooManyRequests',
  'auth/operation-not-allowed': 'operationNotAllowed',
  'auth/internal-error': 'internalError',
  'auth/invalid-login-credentials': 'incorrectDetails',
  'auth/invalid-credential': 'incorrectDetails',
  'auth/admin-restricted-operation': 'adminOnlyOperation',
  'auth/already-initialized': 'alreadyInitialized',
  'auth/app-not-authorized': 'appNotAuthorized',
  'auth/app-not-installed': 'appNotInstalled',
  'auth/argument-error': 'argumentError',
  'auth/captcha-check-failed': 'captchaCheckFailed',
  'auth/code-expired': 'codeExpired',
  'auth/cordova-not-ready': 'cordovaNotReady',
  'auth/cors-unsupported': 'corsUnsupported',
  'auth/credential-already-in-use': 'credentialAlreadyInUse',
  'auth/custom-token-mismatch': 'customTokenMismatch',
  'auth/requires-recent-login': 'requiresRecentLogin',
  'auth/dependent-sdk-initialized-before-auth': 'dependentSdkInitializedBeforeAuth',
  'auth/dynamic-link-not-activated': 'dynamicLinkNotActivated',
  'auth/email-change-needs-verification': 'emailChangeNeedsVerification',
  'auth/emulator-config-failed': 'emulatorConfigFailed',
  'auth/expired-action-code': 'expiredActionCode',
  'auth/cancelled-popup-request': 'cancelledPopupRequest',
  'auth/invalid-api-key': 'invalidApiKey',
  'auth/invalid-app-credential': 'invalidAppCredential',
  'auth/invalid-app-id': 'invalidAppId',
  'auth/invalid-user-token': 'invalidUserToken',
  'auth/invalid-auth-event': 'invalidAuthEvent',
  'auth/invalid-cert-hash': 'invalidCertHash',
  'auth/invalid-verification-code': 'invalidVerificationCode',
  'auth/invalid-continue-uri': 'invalidContinueUri',
  'auth/invalid-cordova-configuration': 'invalidCordovaConfiguration',
  'auth/invalid-custom-token': 'invalidCustomToken',
  'auth/invalid-dynamic-link-domain': 'invalidDynamicLinkDomain',
  'auth/invalid-emulator-scheme': 'invalidEmulatorScheme',
  'auth/invalid-message-payload': 'invalidMessagePayload',
  'auth/invalid-multi-factor-session': 'invalidMultiFactorSession',
  'auth/invalid-oauth-client-id': 'invalidOauthClientId',
  'auth/invalid-oauth-provider': 'invalidOauthProvider',
  'auth/invalid-action-code': 'invalidActionCode',
  'auth/unauthorized-domain': 'unauthorizedDomain',
  'auth/invalid-persistence-type': 'invalidPersistenceType',
  'auth/invalid-phone-number': 'invalidPhoneNumber',
  'auth/invalid-provider-id': 'invalidProviderId',
  'auth/invalid-recaptcha-action': 'invalidRecaptchaAction',
  'auth/invalid-recaptcha-token': 'invalidRecaptchaToken',
  'auth/invalid-recaptcha-version': 'invalidRecaptchaVersion',
  'auth/invalid-recipient-email': 'invalidRecipientEmail',
  'auth/invalid-req-type': 'invalidReqType',
  'auth/invalid-sender': 'invalidSender',
  'auth/invalid-verification-id': 'invalidVerificationId',
  'auth/invalid-tenant-id': 'invalidTenantId',
  'auth/multi-factor-info-not-found': 'multiFactorInfoNotFound',
  'auth/multi-factor-auth-required': 'multiFactorAuthRequired',
  'auth/missing-android-pkg-name': 'missingAndroidPkgName',
  'auth/missing-app-credential': 'missingAppCredential',
  'auth/auth-domain-config-required': 'authDomainConfigRequired',
  'auth/missing-client-type': 'missingClientType',
  'auth/missing-verification-code': 'missingVerificationCode',
  'auth/missing-continue-uri': 'missingContinueUri',
  'auth/missing-iframe-start': 'missingIframeStart',
  'auth/missing-ios-bundle-id': 'missingIosBundleId',
  'auth/missing-multi-factor-info': 'missingMultiFactorInfo',
  'auth/missing-multi-factor-session': 'missingMultiFactorSession',
  'auth/missing-or-invalid-nonce': 'missingOrInvalidNonce',
  'auth/missing-phone-number': 'missingPhoneNumber',
  'auth/missing-recaptcha-token': 'missingRecaptchaToken',
  'auth/missing-recaptcha-version': 'missingRecaptchaVersion',
  'auth/missing-verification-id': 'missingVerificationId',
  'auth/app-deleted': 'appDeleted',
  'auth/account-exists-with-different-credential': 'accountExistsWithDifferentCredential',
  'auth/network-request-failed': 'networkRequestFailed',
  'auth/no-auth-event': 'noAuthEvent',
  'auth/no-such-provider': 'noSuchProvider',
  'auth/null-user': 'nullUser',
  'auth/operation-not-supported-in-this-environment': 'operationNotSupportedInThisEnvironment',
  'auth/popup-blocked': 'popupBlocked',
  'auth/popup-closed-by-user': 'popupClosedByUser',
  'auth/provider-already-linked': 'providerAlreadyLinked',
  'auth/quota-exceeded': 'quotaExceeded',
  'auth/recaptcha-not-enabled': 'recaptchaNotEnabled',
  'auth/redirect-cancelled-by-user': 'redirectCancelledByUser',
  'auth/redirect-operation-pending': 'redirectOperationPending',
  'auth/rejected-credential': 'rejectedCredential',
  'auth/second-factor-already-in-use': 'secondFactorAlreadyInUse',
  'auth/maximum-second-factor-count-exceeded': 'maximumSecondFactorCountExceeded',
  'auth/tenant-id-mismatch': 'tenantIdMismatch',
  'auth/timeout': 'timeout',
  'auth/user-token-expired': 'userTokenExpired',
  'auth/unauthorized-continue-uri': 'unauthorizedContinueUri',
  'auth/unsupported-first-factor': 'unsupportedFirstFactor',
  'auth/unsupported-persistence-type': 'unsupportedPersistenceType',
  'auth/unsupported-tenant-operation': 'unsupportedTenantOperation',
  'auth/unverified-email': 'unverifiedEmail',
  'auth/user-cancelled': 'userCancelled',
  'auth/user-mismatch': 'userMismatch',
  'auth/user-signed-out': 'userSignedOut',
  'auth/weak-password': 'weakPassword',
  'auth/web-storage-unsupported': 'webStorageUnsupported'
};

// Error handling function
export const handleFirebaseAuthError = (errorCode) => {

  // Check if the error code exists in the global ERROR_CODES object
  if (ERROR_CODES.hasOwnProperty(errorCode)) {
    // If the error code exists, log the corresponding error message
    toast.error(ERROR_CODES[errorCode]);
  } else {
    // If the error code is not found, log a generic error message
    toast.error(`Error:${errorCode}`)
  }
  // Optionally, you can add additional logic here to handle the error
  // For example, display an error message to the user, redirect to an error page, etc.
}

function Login({ openSchemes }) {

  const [invalid, setinvalid] = useState()
  const router = useNavigate()
  const [spinner, setspinner] = useState(false)
  const [check, setCheck] = useState(false)
  const { setdetail_info, settoken } = useContext(Logindetail)
  const [showLoader, setShowLoader] = useState(true);
  const [openOtpModal, setOpenOtpModal] = useState(false)
  const [showOTPContent, setShowOtpContent] = useState(false);
  const [otpload, setOtpLoad] = useState(false);
  const [load, setLoad] = useState(false)
  const [otpData, setOtpData] = useState({ phone: '' })

  const initvalue = {
    'email': '',
    'password': '',
    "phone": '',
    "role": ''
  }

  const signschema = Yup.object().shape({
    'phone': Yup.string()
      .min(10, 'Phone Number Minimum 10 digits')
      .max(10, 'Phone Number Maximum 10 digits')
      .required('Enter Your Phone Number')
      .matches(/^[0-9]+$/, "Invalid Phone Number"),
    'role': Yup.string()

    // 'email': Yup.string().email('Invalid Email')
    //   .required('Enter Your Email')
    //   .matches(
    //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/, 'Invalid Email'
    //   )
    //   .test(
    //     'Invalid Email',
    //     (value) => {
    //       return value === value.toLowerCase()
    //     }
    //   ),
    // 'password': Yup.string()
    //   .required('Enter Your Password')
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //     "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character"
    //   ),
  })

  const { footerpage } = useContext(Footerpages)

  // console.log('authenticationauthentication',authentication);


  const generateRecaptcha = () => {
    if (!window?.recaptchaVerifier) {
      const recaptchaContainer = document.getElementById('recaptcha-container');
      if (recaptchaContainer) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              console.log("Recaptcha verified");
            },
            "expired-callback": () => {
              console.log("Recaptcha expired");
            },
          },
          authentication
        );
      } else {
        console.error('recaptcha-container element not found');
      }
    }
  };

  useEffect(() => {
    generateRecaptcha();
    setShowLoader(true);
    return () => {
      if (window.recaptchaVerifier) {
        try {
          window.recaptchaVerifier.clear();
          window.recaptchaVerifier = null;
        } catch (error) {
          console.error("Error clearing recaptchaVerifier:", error);
        }
      }

      const recaptchaContainer = document.getElementById("recaptcha-container");
      if (recaptchaContainer) {
        recaptchaContainer.remove();
      }
    };
  }, []);


  const generateOTP = (phoneNumber) => {
    setLoad(true)
    const phone = `+91${phoneNumber}`;
    // console.log('phoneNumberphoneNumber',phone);
    if (!window.recaptchaVerifier) {
      setLoad(false)
      window.location.reload();
      console.error('window.recaptchaVerifier is null, unable to generate OTP');
      return;
    }
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(authentication, phone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success("OTP Sent");
        setShowLoader(false);
        setOpenOtpModal(true);
        // handleClose?.()
        setOtpData({ phone: phone });
        setLoad(false)
        // Handle success
      })
      .catch((error) => {
        console.error("Error generating OTP:", error);
        const errorCode = error.code
        handleFirebaseAuthError(errorCode)
        setLoad(false)
      });
  };

  useEffect(() => {
    if (showOTPContent) {
      generateRecaptcha();
    }
  }, []);



  const Formrik = useFormik({
    initialValues: initvalue,
    validationSchema: signschema,
    onSubmit: async (value, { setErrors, setStatus, resetForm }) => {

      if (!check) {
        return toast.error('Agree Terms and Condition')
      }

      var role = document.getElementById('loginRole').value;
      Formrik.setFieldValue('role', role);


      checkUserRoleApi(role, value.phone)
        .then((res) => {

          if (res.type == 'success') {
            generateOTP(value.phone);
          } else if (res.type == 'error') {
            toast.error(res.data);
          }

        })
        .catch((err) => {
          console.error(err);
          toast.error('Something went wrong!');
        })


    }
  })

  const formSubmitHandler = (value) => {
    setspinner(true)
    Login_fun(value)
      .then((res) => {
        setOpenOtpModal(false)
        if (res.status === 403) {

          document.getElementById('closeloginmodal007')?.click();

          var roles = Formrik.getFieldProps('role').value;
          if (roles == 1) {
            document.getElementById('openAgentRegistermodal')?.click();
          } else if (roles == 2) {
            document.getElementById('openRegistermodal')?.click();
          }

          setspinner(false)
        }
        else {
          localStorage.setItem("green_token", res.token)
          handlegetprofile()
          settoken(res.token);
          document.getElementById('closeloginmodal007')?.click();
          // resetForm();
          setspinner(false);
          // if (openSchemes) {
          //   document.getElementById("openPriceplanpopu")?.click();
          // }
        }
      })
      .catch((err) => {
        // setErrors({ auth: "Authentication failed!" });
        document.getElementById('closeloginmodal007')?.click();

        var roles = Formrik.getFieldProps('role').value;
        if (roles == 1) {
          document.getElementById('openAgentRegistermodal')?.click();
        } else if (roles == 2) {
          document.getElementById('openRegistermodal')?.click();
        }

        setspinner(false)
        setOpenOtpModal(false)
      })
  }

  // const Agreepage = (slug) => {
  //   document.getElementById('closeloginmodal007')?.click();
  //   router(`/pages/${slug}`)
  // }


  const handlegetprofile = () => {
    getprofile_fun()
      .then((res) => {
        localStorage.setItem("green_login", JSON.stringify(res?.data));
        setdetail_info(res?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const handleConfirm = (value) => {


    setOtpLoad(true)
    let confirmationResult = window.confirmationResult;
    confirmationResult?.confirm(value.otp)
      .then(async (result) => {
        toast.success("verified!");
        setOtpLoad(false)
        const val = {
          phone: value.phone ?? Formrik.getFieldProps('phone').value,
          password: "12345678"
        }
        localStorage.setItem('phone', `${value.phone ?? Formrik.getFieldProps('phone').value}`)
        formSubmitHandler(val)

      })
      .catch((error) => {
        console.log(error);
        const errorCode = error.code
        handleFirebaseAuthError(errorCode)
        setShowLoader(false);
        setOtpLoad(false)
      });
    setShowLoader(true);
  }


  return (
    <>
      <button type="button" id="chooseOpenOtpclose" onClick={() => setOpenOtpModal(false)} className="d-none"></button>
      <section>
        <div className="modal fade loginmodal" id="loginmodalview" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" >
            <div className="modal-content px-4 py-2">
              <div id="recaptcha-container"></div>
              <div className="modal-header login-header justify-content-center border-0">
                <h3 className="modal-title"> <img alt='image' className="me-2" src='/assets/iocns/__Fav Icon.png' />Welcome to Greenheap Gold</h3>
                <button className="d-none" id="closeloginmodal007" data-bs-dismiss="modal" type="button"></button>
              </div>
              <div className="modal-body login-body login-text">

                {
                  openOtpModal ?
                    <OTPForm formSubmitHandler={handleConfirm} otpload={otpload} phone={Formrik.getFieldProps('phone').value} />
                    :
                    <form onSubmit={Formrik.handleSubmit}>

                      <div className="login-email">

                        <input type="hidden" className="form-control bg-light border" id="loginRole" />

                        <label>Phone Number <span className="text-danger">*</span></label>
                        <div className="position-relative">
                          <input type="text" maxLength={10} className="form-control bg-light border" id="loginPhone" {...Formrik.getFieldProps('phone')} placeholder="Enter Phone Number" />
                          <div className="num_s">
                            <h6 className="mb-0">+91  </h6>
                          </div>
                        </div>
                        {
                          Formrik.touched.phone && Formrik.errors.phone ? (<span className="text-danger">{Formrik.errors.phone}</span>) : null
                        }

                      </div>

                      <div className="login-terms my-2">
                        {/* <div className="frgt-click d-flex justify-content-end">
                        <p style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#frgtpwd_modal">Forgot Password ?</p>
                      </div> */}

                        <div className="d-flex gap-2 align-items-center mt-4 mb-3">
                          <input type="checkbox" value={check} onChange={(e) => setCheck(e.target.checked)} id="skdjsdsdkjddkdd" />
                          <h6 className="fs-6 mb-0 pb-0" onClick={() => document.getElementById('skdjsdsdkjddkdd')?.click()}>I agree to <a href={`/pages/terms-conditions`} target="_blank"><span>Terms and Condition</span></a> and <a href={`/pages/privacy-policy`} target="_blank"><span>Privacy Policy</span></a> </h6>
                        </div>

                        {/* {
                        footerpage ? (<h6>I agree to  <a href={`pages/${footerpage[0]?.slug}`} target="_blank"><span>Terms and Condition</span></a> and  <a href={`pages/${footerpage[1]?.slug}`} target="_blank"><span>Privacy Policy</span></a></h6>)
                          : (<h6>I agree to Terms and Condition and Privacy Policy </h6>)
                      } */}
                      </div>
                      <div className="login-footer text-center border-0  d-block">
                        <button className='text-white' disabled={load} type="submit">{load ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Get OTP'}</button>
                        {/* <h4 className="pt-4">I don't have an account <span className="go-signup" data-bs-toggle="modal" data-bs-target="#signupmodalview" >Sign Up</span> <span className="signarrow" data-bs-toggle="modal" data-bs-target="#signupmodalview" ><ArrowRight /></span></h4> */}
                      </div>
                    </form>
                }

              </div>
            </div>
          </div>
        </div>
      </section>

      <Forgatpwd />

    </>
  )
}
export default Login;