import React, { useEffect, useState, useRef } from "react";
import HeadingBanner from "../Components/HeadingBanner";
import Dashboard from "../Components/Dashboard";
import { getPayListApi, request_payout } from "../api/Dashboardapi";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import moment from "moment";
import toast from "react-hot-toast";

const PayoutList = ({ settings }) => {

    const [data, setData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const inputRef = useRef(null);
    const [isModalOpen, payoutmodalview] = useState(false);

    const fetchPayRequest = () => {
        setisLoading(true)
        getPayListApi().then(res => {
            setisLoading(false)
            setData(res.data);
        }).catch(err => {
            console.log("err", err.message);
            setisLoading(false)
        })
    }


    const getRequest = async () => {
        setisLoading(true);
        var amount = inputRef.current.value;
        var data = {
            "amount": amount,
            "transaction_id": "JKASDTAS78D"
        };

        request_payout(data)
            .then(res => {
                console.log(res);

                if (res?.type == 'error') {
                    toast.error(res?.data);
                    setisLoading(false);
                    return false;
                } else {
                    toast.success(res?.data);
                    setisLoading(false);
                    return true;

                }
            })
            .catch(err => {
                console.log("err", err.message);
            })
    };




    useEffect(() => {
        fetchPayRequest();
    }, [])

    const paginationModel = { page: 0, pageSize: 5 };

    const columns = [
        { field: 's_no', headerName: 'S.N0', width: 70 },

        {

            field: 'amount',
            headerName: 'AMOUNT',
            width: 150,
            sortable: false,
        },

        {

            field: 'bank_name',
            headerName: 'BANK NAME',
            width: 150,
            sortable: false,
        },

        {

            field: 'account_number',
            headerName: 'ACCOUNT NUMBER',
            width: 150,
            sortable: false,
        },

        {
            field: 'transaction_id',
            headerName: 'TRANSACTION ID',
            width: 150,
            sortable: false,
        },
        {
            field: 'requested_date',
            headerName: 'REQUSETED DATE',
            width: 200,
            sortable: false,
        },

        {
            field: 'dispatch_date',
            headerName: 'DISPATCH DATE',
            width: 200,
            sortable: false,
        },

        {
            field: 'status',
            headerName: 'STATUS',
            width: 100,

        },

    ]


    return (<>
        <HeadingBanner title={"Payout Request"} />
        <section>
            <div className="container">
                <div className="siptrans-layout">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4"><Dashboard setting_data={settings} /></div>
                        <div className="col-xl-9 col-lg-8">
                            <div className="siptrans-content position-relative">


                                <div className="siptrans-head pb-3 align-items-center d-flex justify-content-between">
                                    <h4>Payout Request</h4>
                                </div>

                                {/* <div className="req_btn">
                                    <button type="button" id='payout_request' data-bs-toggle="modal" data-bs-target="#payoutmodalview" >Payout Request</button>
                                </div> */}

                                <div className="modal p-0 fade signupmodal" id="payoutmodalview" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered modal-lg px-lg-5 " >
                                        <div className="modal-content pay_outmodal  px-2 py-2">
                                            <div className="modal-header login-header justify-content-center border-0">
                                                <button type="button" className="d-none" id="closesignmodaltermspage11" data-bs-dismiss="modal" aria-label="Close"></button>
                                                <h3 className="modal-title">Payout Request</h3>
                                            </div>

                                            <div className="modal-body login-body login-text pt-0">

                                                <form action="">
                                                    <input className="form-control" ref={inputRef} name="amount" type="text" />
                                                    <div className="sav_btn">
                                                        <button onClick={() => { getRequest() }} type="button">Save</button>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {
                                    isLoading ? (
                                        <div className="tableload  text-center position-absolute">
                                            <div class="spinner-border text-primary text-center" role="status"></div>
                                        </div>
                                    ) : data.length > 0 ? ((
                                        <Paper sx={{ height: 450, width: '100%' }} className="tabledata">
                                            <DataGrid
                                                rows={data.map((item, index) => ({ s_no: index + 1, ...item }))}
                                                columns={columns}
                                                disableColumnMenu
                                                initialState={{ pagination: { paginationModel } }}
                                                pageSizeOptions={[5, 10]}
                                                sx={{ border: 0, marginTop: 3 }}
                                                disableColumnFilter
                                                disableColumnSelector
                                                disableDensitySelector
                                                className="schemedata-table"
                                                slots={{ toolbar: GridToolbar }}
                                                slotProps={{
                                                    toolbar: {
                                                        showQuickFilter: true,
                                                        printOptions: { disableToolbarButton: true },
                                                        csvOptions: { disableToolbarButton: true },
                                                        ShowCellToolTip: false
                                                    },
                                                }}
                                            />
                                        </Paper>
                                    )) : <div className="text-center no-commision">
                                        <img src="\assets\noData.png" className="img-fluid" alt="" />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default PayoutList