import React, { useRef, useState } from "react";
import { ArrowRight, Envelope } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from "formik"
import { useContext } from "react"
import toast from "react-hot-toast"
import { Logindetail } from "../../context/Logindetail";
import OtpInput from "react-otp-input";
import { Box, Stack, Typography, TextField, useTheme } from '@mui/material'




const OTPForm = ({formSubmitHandler,phone, otpload}) => { 

      const [invalid, setinvalid] = useState()
      const router = useNavigate()
      const [spinner, setspinner] = useState(false)
      const { setdetail_info, settoken } = useContext(Logindetail)
      const [otp, setOtp] = useState("");
      const theme = useTheme();

      const initvalue = {
        'otp': '',
        'phone': phone
      } 

      const otpFormik = useFormik({
        initialValues: {
            otp: '',
            phone: phone,
        },
        validationSchema: Yup.object({
            otp: Yup.string().required("Enter your Otp"),
        }),
        onSubmit: async (values) => {
            try {
                formSubmitHandler(values)
            } catch (err) {

            }
        },
    })


   
    return(<>
    <div>
        <h5>Submit the OTP code sent to +91 {phone ?? 'your Phone Number'} and verify</h5>
        <form onSubmit={otpFormik.handleSubmit}>
                    <Stack
                        padding="0 20px"
                        alignItems="center"
                        justifyContent="center"
                    >   
                        <Box
                            sx={{
                                div: {
                                    gap: "20px",
                                },
                                input: {
                                    flexGrow: "1",
                                    background: "transparent",
                                    color: '#99131',
                                    fontSize: "16px",
                                    outline: "none",
                                    height: "45px",
                                    width: "45px !important",
                                    borderRadius: "10px",
                                    border: "1.6px solid " + '#99131',
                                },
                            }}
                        >
                            <OtpInput
                                value={otpFormik.values.otp}
                                onChange={(otp) => otpFormik.setFieldValue('otp', otp)}
                                numInputs={6}
                                onBlur={otpFormik.handleBlur('otp')}
                                renderInput={(props) => <input {...props} />}
                                error={
                                    otpFormik.touched.otp &&
                                    Boolean(otpFormik.errors.otp)
                                }
                            />

                            {
                              otpFormik.touched.otp && otpFormik.errors.otp ? (<span className="text-danger">{otpFormik.errors.otp}</span>) : null
                            }
                        </Box>
                    </Stack>

                    <div className="login-footer text-center border-0 mt-4 d-block"> 
                      <button className='text-white' disabled={otpload} type="submit">{otpload ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Submit'}</button>
                    </div>

                </form>
    </div>
    </>)
}


export default OTPForm;