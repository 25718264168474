import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Logindetail } from "../../context/Logindetail"
import { ExclamationCircle } from "react-bootstrap-icons"

const Logout = () => {

    const navigate = useNavigate()
    const { setdetail_info } = useContext(Logindetail)

    const logout =()=>{
        localStorage.removeItem("green_login")
        localStorage.removeItem("phone")
        localStorage.removeItem("green_token")
        localStorage.removeItem("kyc")
        navigate('/'); 
        setdetail_info(); 
    }

    return (
        <>
            <section>
                <div className="modal fade" id="logout_modal" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <h2 className="logouticon"><ExclamationCircle/></h2>
                                <h3 className="logout-text"> Are you sure you want logout ?</h3>
                            </div>
                            <div className="modal-footer logout text-center d-block gap-3">
                                <button type="button" className="btn btn-secondary " data-bs-dismiss="modal" onClick={logout}>Yes</button>
                                <button type="button" className="btn btn-primary " data-bs-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Logout;