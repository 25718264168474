import React, { useContext, useEffect, useState, useCallback, act } from "react"
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import moment from "moment/moment"
import useRazorpay from "react-razorpay";
import toast from 'react-hot-toast';
import { Image, Shimmer } from 'react-shimmer'
import { fetchBeneficiaries, getInvoiceDown, getShemesSchedule, postMonthShec, postSchemaSave, SetPlanCancell } from "../api/Pageapi"
import { Link, useNavigate } from "react-router-dom"
import { Logindetail } from "../context/Logindetail"
import { DataGrid, GridToolbar, GridToolbarQuickFilter, GridLogicOperator } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { render } from "@testing-library/react";
import { SchemeDetails } from "../context/SchemeDetails.js";
import { ArrowLeft, BookmarkCheckFill, BookmarkStar, BookmarkStarFill, BookmarkX, BookmarkXFill, Check2Circle, HourglassTop, PauseCircle, PauseCircleFill, SlashCircle, SlashCircleFill, X } from "react-bootstrap-icons";
import Box from '@mui/material/Box';

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <GridToolbarQuickFilter
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .map((value) => value.trim())
                        .filter((value) => value !== '')
                }
            />
        </Box>
    );
}

export default function ChitSchemes({ settings }) {

    // const RAZAR_KEY = process.env.REACT_APP_RAZAR_KEY;

    const RAZAR_KEY = "rzp_live_FZ6iVw5tcRGpW5";

    // const RAZAR_KEY = "rzp_test_K7CipNQYyyMPiS";

    const router = useNavigate()

    const { token, detail_info, setdetail_info } = useContext(Logindetail)

    const [Shcudle, setShcudle] = useState([])
    // const [full, setFull] = useState([])
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [transcation, setTranscation] = useState()
    const [ALlData_list, setALlData_list] = useState()
    const [DataPage, setDataPage] = useState(0)
    // const [Totalpage, setTotalpage] = useState()
    const [spinner, setspinner] = useState(false)
    const [spinid, setspinid] = useState()
    const [checked, setchecked] = useState()
    const [cancel_slug, setcancel_slug] = useState()
    const [plandetails, setplandetails] = useState()
    const [shemaData, setshemaData] = useState()
    const [price, setprice] = useState()
    const [statuslist, setstatuslist] = useState({
        'active': '',
        'hold': '',
        'complete': '',
        'suspend': '',
        'cancel': ''
    })

    const [benfic, setbenfic] = useState()
    const [benificid, setbenificid] = useState()
    const [finalamount, setfinalamount] = useState()
    const [activebank, setactivebank] = useState()
    const [actid, setactid] = useState()
    const [cancel_id, setcancel_id] = useState()
    const [Kycactive, setKycactive] = useState()
    const [Transactionid, setTranscationid] = useState()
    const [transfile, settransfile] = useState()
    const [file1, setfile1] = useState()
    const [process_type, setprocess_type] = useState()
    const [clientamount, setclientamount] = useState()
    const [monthlydata, setmonthlydata] = useState([])



    const currentDate = new Date();

    const handleGetSchema = (slug, id) => {
        setisLoading(true)
        getShemesSchedule(slug, id).then(res => {
            setShcudle(res?.data);
            setALlData_list(res?.data)
            setfinalamount(res?.final_amount)
            setplandetails(res?.plan)
            setactivebank(res?.bank_details)
            setKycactive(res?.kyc_details)

            // setFull(res?.data)
            // setTotalpage(res?.total)
            setcancel_slug(res?.cancel_slug)
            setisLoading(false)
            setstatuslist(previousState => {
                return { ...previousState, active: res?.active_count, hold: res?.hold_count, complete: res?.complete_count, suspend: res?.suspended_count, cancel: res?.cancel_count }
            })
        }).catch(err => {
            setisLoading(false)
        })
    }

    const handlePayment = useCallback(async (data, enterprice, plandata) => {
        const options = {
            key: RAZAR_KEY,
            amount: (enterprice ? enterprice : data?.amount) * 100,
            currency: "INR",
            name: 'Green Heap',
            description: 'Green Heap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                if (ALlData_list?.length >= 1) {
                    const body = {
                        "amount": data?.amount,
                        "purchase_id": data?.purchase_id,
                        "transaction_id": res?.razorpay_payment_id,
                        "payment_method": 'razor_pay',
                    }
                    postMonthShec(body).then(res => {
                        // navigate('/dashboard');
                        handleGetSchema(1);
                        toast.success("Payment success!")
                        setShowRazorpayModal(false);
                        setTranscation([])
                    }).catch(err => {
                        console.log('err', err.message)
                    })

                }
                else {
                    const body = {
                        "amount": enterprice,
                        "saving_plan_id": plandata?.id,
                        "transaction_id": res?.razorpay_payment_id,
                        'benificiar': benificid ? benificid : '',
                        "payment_method": 'razor_pay',
                    }
                    postSchemaSave(body).then(res => {
                        if (res?.error) {
                            return toast.error(res?.error ?? 'Somthing Went Wrong!')
                        }
                        toast.success(`Payment successful from ${plandata?.name}`)
                        handleGetSchema(1);
                        setShowRazorpayModal(false);
                        setbenificid()
                    }).catch(err => {
                        console.log('err', err.message)
                    })

                }

            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, []);



    // const openrazar_function = (amount, plandata) => {
    //     handlePayment('', amount, plandata)
    // }


    useEffect(() => {
        handleGetSchema(1);
        setactid(1)

    }, [DataPage]);

    const handledownload = (value) => {

        const baseurl = process.env.REACT_APP_BASE_URL

        let fileName = `${value?.paid_date}-invoice.pdf`;
        const url = `${baseurl}generate_invoice?id=${value?.id}`;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setspinner(false)
            })
            .catch(error => {
                console.error('Error fetching PDF: ', error);
                window.open(url, "_blank");
            });
    }

    function addMonths(date, months) {
        const result = new Date(date);
        result.setMonth(result.getMonth() + months);
        return result;
    }

    // const setcancell = (id) => {

    //     //   if(!checked){
    //     //     toast.error('Please Agree Terms & condition')

    //     //   }
    //     //   else{
    //     //     document.getElementById('cancelpopmsg').click()
    //     //     SetPlanCancell(id, token)
    //     //     .then((res) => {
    //     //         console.log(res)
    //     //         toast.success(res?.data)
    //     //     })
    //     //     .catch((err) => {
    //     //         console.log(err)
    //     //     })
    //     //   }
    //     handlecancel(id)

    // }



    const handlecancel = () => {
        if (!checked) {
            toast.error('Please Agree Terms & condition')
        }
        else {
            document.getElementById('cancelpopmsg').click()
            SetPlanCancell(cancel_id)
                .then((res) => {

                    toast.success(res?.data)
                    handleGetSchema(1);
                    setactid(1)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }


    const columns = [

        { field: 's_no', headerName: 'S.NO', width: 100 },
        {
            field: 'title',
            headerName: 'NAME',
            width: 140,
            renderCell: (params) => (
                <>
                    {
                        <p style={{ cursor: 'pointer' }} onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row)}>{params?.row?.beneficiary?.name ? params?.row?.beneficiary?.name : detail_info?.name}</p>
                    }
                </>
            ),

        },
        // {
        //     field: 'title',
        //     headerName: 'NAME',
        //     width: 140,
        // },
        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 140,
            // valueGetter: (value, row) => `${row.purchase_transaction?.reduce((accumulator, current) => accumulator + (current?.gold_gram ?? ''), 0)} (gm)` ?? '0',
            valueGetter: (value, row) => {
                const totalGold = row.purchase_transaction?.filter(e => e.payment_status == 'success' && e.status == 'paid').reduce((accumulator, current) => {
                    const goldGram = parseFloat(current?.gold_gram);
                    return !isNaN(goldGram) ? accumulator + goldGram : accumulator;
                }, 0);
                return totalGold ? `${totalGold} (gm)` : '0';
            }
        },

        {
            field: 'total',
            headerName: 'TOTAL',
            width: 140,
            valueGetter: (value, row) => `₹ ${row.purchase_transaction?.filter(e => e.payment_status == 'success' && e.status == 'paid').reduce((accumulator, current) => accumulator + current?.amount, 0)}` ?? '0',
        },
        {
            field: 'purchase_Date',
            headerName: 'PURCHASE DATE',
            width: 205,
            valueGetter: (value, row) => moment(row.purchase_transaction[0]?.paid_date).format('DD-MM-YYYY') ?? '-',
        },
        {
            field: 'view',
            headerName: 'VIEW',
            width: 120,
            renderCell: (params) => (
                <img
                    src="\assets\iocns\eye.png"
                    onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row)}
                    alt=""
                    width={20}
                    style={{ cursor: 'pointer' }}

                />
            ),
            sortable: false,
        },
        {
            field: 'status',
            headerName: 'STATUS',
            width: 120,
            renderCell: (params) => (
                <>
                    {
                        // moment(params?.row?.purchase_transaction[0]?.paid_date, "YYYY-MM-DD").add(3, 'M').month() == moment(new Date()).month() ?
                        currentDate >= addMonths(new Date(params?.row?.purchase_transaction[0]?.paid_date), 3) ?
                            <button type="button" className="btn btn-danger text-white" disabled={params?.row?.cancel_status == 1} onClick={() => { setcancel_id(params?.row?.purchase_transaction[0]?.purchase_id); document.getElementById('cancelpopmsg').click(); handleGetSchema(1, params?.row?.purchase_transaction[0]?.purchase_id); }}>{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button> :
                            params?.row?.purchase_status == 1 ?
                                <button type="button" disabled className="btn btn-success text-white">Completed</button> :
                                <button type="button" disabled={params?.row?.cancel_status == 1} className={`btn btn-${params?.row?.cancel_status == 1 ? 'danger' : 'outline-danger'} `} data-bs-toggle="modal" data-bs-target="#cancelpipup" >{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button>

                    }
                </>
            ),
            sortable: false,
        }
    ];

    const columns2 = [
        { field: 's_no', headerName: 'S.NO', width: 145 },
        {
            field: 'title',
            headerName: 'NAME',
            width: 160,
            // valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,
            renderCell: (params) => (
                <>
                    {
                        <p style={{ cursor: 'pointer' }} onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row)}>{params?.row?.beneficiary?.name ? params?.row?.beneficiary?.name : detail_info?.name}</p>
                    }
                </>
            ),
        },
        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 160,
            // valueGetter: (value, row) => `${row.purchase_transaction?.reduce((accumulator, current) => accumulator + (current?.gold_gram ?? ''), 0)} (gm)` ?? '0',
            valueGetter: (value, row) => ` ${row?.cancel_total_gram}(gm)` ?? '0',
        },
        {
            field: 'total',
            headerName: 'TOTAL',
            width: 160,
            valueGetter: (value, row) => `₹ ${row?.cancel_total_amount}` ?? '0',

        },
        {
            field: 'cancel_Date',
            headerName: 'CANCELLED DATE',
            width: 220,
            valueGetter: (value, row) => moment(row?.updated_at).format('DD-MM-YYYY') ?? '-',
        },
        // {
        //     field: 'withdraw',
        //     headerName: 'Withdraw',
        //     width: 120,
        //     valueGetter: (value, row) => `${row?.withdraw ? row?.withdraw : '-'}`,
        //     sortable: false,
        // },
        // {

        //     field: 'withdraw_date',
        //     headerName: 'Withdraw Date',
        //     width: 140,
        //     valueGetter: (value, row) => `${row?.withdraw ? row?.withdraw : '-'}`,
        //     sortable: false,
        // },
        {
            field: 'view',
            headerName: 'VIEW',
            width: 120,
            // valueGetter: (value, row) => `<img alt="" src="\assets\iocns\eye.png" onClick={() => row?.cancel_status == 1 ? {} : setTranscation(row?.purchase_transaction)} className="img-fluid" />`,
            renderCell: (params) => (
                <img
                    src="\assets\iocns\eye.png"
                    onClick={() => setTranscation(params.row)}
                    alt=""
                    width={20}
                    style={{ cursor: 'pointer' }}

                />
            ),
            sortable: false,
        }
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     width: 134,
        //     renderCell: (params) => (
        //         <>
        //             {
        //                 // moment(params?.row?.purchase_transaction[0]?.paid_date, "YYYY-MM-DD").add(3, 'M').month() == moment(new Date()).month() ?
        //                 currentDate >= addMonths(new Date(params?.row?.purchase_transaction[0]?.paid_date), 3) ?
        //                     <button type="button" className="btn btn-danger text-white" disabled={params?.row?.cancel_status == 1} onClick={() => { setcancel_id(params?.row?.purchase_transaction[0]?.purchase_id); document.getElementById('cancelpopmsg').click(); handleGetSchema(1, params?.row?.purchase_transaction[0]?.purchase_id); }}>{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button> :
        //                     params?.row?.purchase_status == 1 ?
        //                         <button type="button" disabled className="btn btn-success text-white">Completed</button> :
        //                         <button type="button" disabled={params?.row?.cancel_status == 1} className={`btn btn-${params?.row?.cancel_status == 1 ? 'danger' : 'outline-danger'} `} data-bs-toggle="modal" data-bs-target="#cancelpipup" >{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button>

        //             }
        //         </>
        //     ),
        //     sortable: false,
        // }
    ];

    const columns3 = [

        { field: 's_no', headerName: 'S.NO', width: 100 },
        {
            field: 'title',
            headerName: 'NAME',
            width: 120,
            // valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,
            renderCell: (params) => (
                <>
                    {
                        <p style={{ cursor: 'pointer' }} onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row)}>{params?.row?.beneficiary?.name ? params?.row?.beneficiary?.name : detail_info?.name}</p>
                    }
                </>
            )

        },
        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 120,
            valueGetter: (value, row) => `${row.purchase_transaction?.reduce((accumulator, current) => accumulator + (current?.gold_gram ?? ''), 0)} (gm)` ?? '0',
        },
        {
            field: 'total',
            headerName: 'TOTAL',
            width: 100,
            valueGetter: (value, row) => `₹ ${row.purchase_transaction?.filter(e => e.status == 'paid').reduce((accumulator, current) => accumulator + current?.amount, 0)}` ?? '0',
        },
        {
            field: 'completed_date',
            headerName: 'COMPLETED DATE',
            width: 172,
            valueGetter: (value, row) => moment(row?.updated_at).format('DD-MM-YYYY') ?? '-',
        },
        {
            field: 'withdraw ',
            headerName: 'WITHDRAW TYPE',
            width: 140,
            valueGetter: (value, row) => `${row?.withdraw ? row?.withdraw : '-'}`,
            sortable: false,
        },
        {

            field: 'withdraw_date',
            headerName: 'WITHDRAW DATE',
            width: 150,
            valueGetter: (value, row) => `${row?.withdraw_date ? moment(row?.withdraw_date).format('DD-MM-YYYY') : '-'}`,
            sortable: false,
        },
        {
            field: 'view',
            headerName: 'VIEW',
            width: 63,
            // valueGetter: (value, row) => `<img alt="" src="\assets\iocns\eye.png" onClick={() => row?.cancel_status == 1 ? {} : setTranscation(row?.purchase_transaction)} className="img-fluid" />`,
            renderCell: (params) => (
                <img
                    src="\assets\iocns\eye.png"
                    onClick={() => setTranscation(params.row)}
                    alt=""
                    width={20}
                    style={{ cursor: 'pointer' }}

                />
            ),
            sortable: false,
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     width: 105,
        //     renderCell: (params) => (
        //         <>
        //             {
        //                 // moment(params?.row?.purchase_transaction[0]?.paid_date, "YYYY-MM-DD").add(3, 'M').month() == moment(new Date()).month() ?
        //                 currentDate >= addMonths(new Date(params?.row?.purchase_transaction[0]?.paid_date), 3) ?
        //                     <button type="button" className="btn btn-danger text-white" disabled={params?.row?.cancel_status == 1} onClick={() => { setcancel_id(params?.row?.purchase_transaction[0]?.purchase_id); document.getElementById('cancelpopmsg').click() }}>{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button> :
        //                     params?.row?.purchase_status == 1 ?
        //                         <button type="button" disabled className="btn btn-success text-white">Completed</button> :
        //                         <button type="button" disabled={params?.row?.cancel_status == 1} className={`btn btn-${params?.row?.cancel_status == 1 ? 'danger' : 'outline-danger'} `} data-bs-toggle="modal" data-bs-target="#cancelpipup" >{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button>
        //             }
        //         </>
        //     ),
        //     sortable: false,
        // }
    ];
    const columns4 = [

        { field: 's_no', headerName: 'S.NO', width: 145 },
        {
            field: 'title',
            headerName: 'NAME',
            width: 160,
            // valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,
            renderCell: (params) => (
                <>
                    {
                        <p style={{ cursor: 'pointer' }} onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row)}>{params?.row?.beneficiary?.name ? params?.row?.beneficiary?.name : detail_info?.name}</p>
                    }
                </>
            )

        },
        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 160,
            valueGetter: (value, row) => `${row.purchase_transaction?.reduce((accumulator, current) => accumulator + (current?.gold_gram ?? ''), 0)} (gm)` ?? '0',
        },
        {
            field: 'total',
            headerName: 'TOTAL',
            width: 160,
            valueGetter: (value, row) => `₹ ${row.purchase_transaction?.filter(e => e.status == 'paid').reduce((accumulator, current) => accumulator + current?.amount, 0)}` ?? '0',
        },
        {
            field: 'purchase_Date',
            headerName: 'HOLD DATE',
            width: 220,
            valueGetter: (value, row) => moment(row.purchase_transaction[0]?.paid_date).format('DD-MM-YYYY') ?? '-',
        },
        {
            field: 'view',
            headerName: 'VIEW',
            width: 120,
            // valueGetter: (value, row) => `<img alt="" src="\assets\iocns\eye.png" onClick={() => row?.cancel_status == 1 ? {} : setTranscation(row?.purchase_transaction)} className="img-fluid" />`,
            renderCell: (params) => (
                <img
                    src="\assets\iocns\eye.png"
                    onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row)}
                    alt=""
                    width={20}
                    style={{ cursor: 'pointer' }}

                />
            ),
            sortable: false,
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     width: 105,
        //     renderCell: (params) => (
        //         <>
        //             {
        //                 // moment(params?.row?.purchase_transaction[0]?.paid_date, "YYYY-MM-DD").add(3, 'M').month() == moment(new Date()).month() ?
        //                 currentDate >= addMonths(new Date(params?.row?.purchase_transaction[0]?.paid_date), 3) ?
        //                     <button type="button" className="btn btn-danger text-white" disabled={params?.row?.cancel_status == 1} onClick={() => { setcancel_id(params?.row?.purchase_transaction[0]?.purchase_id); document.getElementById('cancelpopmsg').click() }}>{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button> :
        //                     params?.row?.purchase_status == 1 ?
        //                         <button type="button" disabled className="btn btn-success text-white">Completed</button> :
        //                         <button type="button" disabled={params?.row?.cancel_status == 1} className={`btn btn-${params?.row?.cancel_status == 1 ? 'danger' : 'outline-danger'} `} data-bs-toggle="modal" data-bs-target="#cancelpipup" >{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button>
        //             }
        //         </>
        //     ),
        //     sortable: false,
        // }
    ];
    const columns5 = [
        { field: 's_no', headerName: 'S.NO', width: 145 },
        {
            field: 'title',
            headerName: 'NAME',
            width: 160,
            // valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,
            renderCell: (params) => (
                <>
                    {
                        <p style={{ cursor: 'pointer' }} onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row)}>{params?.row?.beneficiary?.name ? params?.row?.beneficiary?.name : detail_info?.name}</p>
                    }
                </>
            ),

        },
        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 160,
            valueGetter: (value, row) => `${row.purchase_transaction?.reduce((accumulator, current) => accumulator + (current?.gold_gram ?? ''), 0)} (gm)` ?? '0',
        },
        {
            field: 'total',
            headerName: 'TOTAL',
            width: 160,
            valueGetter: (value, row) => `₹ ${row.purchase_transaction?.filter(e => e.status == 'paid').reduce((accumulator, current) => accumulator + current?.amount, 0)}` ?? '0',
        },
        {
            field: 'purchase_Date',
            headerName: 'SUSPENDED DATE',
            width: 220,
            valueGetter: (value, row) => moment(row.purchase_transaction[0]?.paid_date).format('DD-MM-YYYY') ?? '-',
        },
        // {
        //     field: 'withdraw',
        //     headerName: 'Withdraw',
        //     width: 120,
        //     valueGetter: (value, row) => `${row?.withdraw ? row?.withdraw : '-'}`,
        //     sortable: false,
        // },
        // {

        //     field: 'withdraw_date',
        //     headerName: 'Withdraw Date',
        //     width: 140,
        //     valueGetter: (value, row) => `${row?.withdraw ? row?.withdraw : '-'}`,
        //     sortable: false,
        // },
        {
            field: 'view',
            headerName: 'VIEW',
            width: 120,
            // valueGetter: (value, row) => `<img alt="" src="\assets\iocns\eye.png" onClick={() => row?.cancel_status == 1 ? {} : setTranscation(row?.purchase_transaction)} className="img-fluid" />`,
            renderCell: (params) => (
                <img
                    src="\assets\iocns\eye.png"
                    onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row)}
                    alt=""
                    width={20}
                    style={{ cursor: 'pointer' }}

                />
            ),
            sortable: false,
        }
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     width: 134,
        //     renderCell: (params) => (
        //         <>
        //             {
        //                 // moment(params?.row?.purchase_transaction[0]?.paid_date, "YYYY-MM-DD").add(3, 'M').month() == moment(new Date()).month() ?
        //                 currentDate >= addMonths(new Date(params?.row?.purchase_transaction[0]?.paid_date), 3) ?
        //                     <button type="button" className="btn btn-danger text-white" disabled={params?.row?.cancel_status == 1} onClick={() => { setcancel_id(params?.row?.purchase_transaction[0]?.purchase_id); document.getElementById('cancelpopmsg').click(); handleGetSchema(1, params?.row?.purchase_transaction[0]?.purchase_id); }}>{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button> :
        //                     params?.row?.purchase_status == 1 ?
        //                         <button type="button" disabled className="btn btn-success text-white">Completed</button> :
        //                         <button type="button" disabled={params?.row?.cancel_status == 1} className={`btn btn-${params?.row?.cancel_status == 1 ? 'danger' : 'outline-danger'} `} data-bs-toggle="modal" data-bs-target="#cancelpipup" >{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button>

        //             }
        //         </>
        //     ),
        //     sortable: false,
        // }
    ];

    const paginationModel = { page: 0, pageSize: 5 };

    const handlespinner = (data) => {
        setspinid(data?.id)
        setspinner(true)
    }

    const handlefectch = () => {
        fetchBeneficiaries()
            .then(res => {
                setbenfic(res?.data)
                setisLoading(false);
                if (res?.data?.status == 403) {
                    logout();
                }
            }).catch(err => {
                console.log('err', err.message);
                setisLoading(false)
            });
    }

    const logout = () => {
        localStorage.removeItem("green_login")
        localStorage.removeItem("phone")
        localStorage.removeItem("green_token")
        localStorage.removeItem("kyc")
        router('/');
        setdetail_info();
    }

    useEffect(() => {
        handlefectch()
        setshemaData()
    }, [])

    const paymentfun = (e) => {
        e.preventDefault()

        if (process_type == '1') {

            setShowRazorpayModal(true);
            handlePayment(monthlydata, clientamount, shemaData)
            document.getElementById('payment-pup').click()
            document.getElementById('Rupees').value = null
        }
        else if (process_type == '2') {
            document.getElementById('payment-pup').click()
            document.getElementById('offlinepop-pup').click()
            document.getElementById('transaction-id').value = null
            setfile1()
            settransfile()
            setTranscationid()
        }
        else if (process_type == '3') {
            document.getElementById('payment-pup').click()
            document.getElementById('bankpop-pup').click()
            document.getElementById('transaction-id2').value = null
            setTranscationid()
        }
        else {
            toast.error('please select payment method')
        }

    }

    const upimethod = (e) => {
        e.preventDefault()

        if (!transfile) {
            toast.error('please Upload the transaction slip');
            return false;
        }

        if (Transactionid || transfile) {


            if (ALlData_list?.length >= 1) {
                setspinner(true)

                const formdata = new FormData()
                formdata.append('amount', monthlydata?.amount)
                formdata.append('purchase_id', monthlydata?.purchase_id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('payment_method', 'upi')
                formdata.append('uploads', transfile)

                postMonthShec(formdata).then(res => {
                    handleGetSchema(1);
                    toast.success("Payment success!")
                    document.getElementById('offlinepop-pup').click()
                    setShowRazorpayModal(false);
                    setspinner(false)
                    setTranscation([])
                }).catch(err => {
                    console.log('err', err.message)
                })

            }

            else {

                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('saving_plan_id', shemaData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid ? benificid : '')
                formdata.append('payment_method', 'upi')
                formdata.append('uploads', transfile)
                setspinner(true)

                postSchemaSave(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    handleGetSchema(1);
                    toast.success(`Payment successful from ${shemaData?.name}`)
                    document.getElementById('offlinepop-pup').click()
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })

            }
        }
        else {
            toast.error('please Upload the transaction slip or enter the transaction ID')
        }

    }

    const Bankmethod = (e) => {
        e.preventDefault()

        if (!transfile) {
            toast.error('please Upload the transaction slip');
            return false;
        }

        if (ALlData_list?.length >= 1) {
            setspinner(true)
            const formdata = new FormData()
            formdata.append('amount', monthlydata?.amount)
            formdata.append('purchase_id', monthlydata?.purchase_id)
            formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
            formdata.append('payment_method', 'bank')
            formdata.append('uploads', transfile)

            postMonthShec(formdata).then(res => {
                handleGetSchema(1);
                toast.success("Payment success!")
                document.getElementById('bankpop-pup').click()
                setShowRazorpayModal(false);
                setTranscation([])
                setspinner(false)
            }).catch(err => {
                console.log('err', err.message)
            })

        }

        else {
            setspinner(true)
            const formdata = new FormData()
            formdata.append('amount', clientamount)
            formdata.append('saving_plan_id', shemaData?.id)
            formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
            formdata.append('benificiar', benificid ? benificid : '')
            formdata.append('payment_method', 'bank')
            formdata.append('uploads', transfile)


            postSchemaSave(formdata).then(res => {
                if (res?.error) {
                    return toast.error(res?.error ?? 'Somthing Went Wrong!')
                }
                handleGetSchema(1);
                toast.success(`Payment successful from ${shemaData?.name}`)
                document.getElementById('bankpop-pup').click()
                setShowRazorpayModal(false);
                setspinner(false)
            }).catch(err => {
                console.log('err', err.message)
            })

        }

    }

    const screenshot = (e) => {

        setfile1(e.target.files[0]?.name)
        settransfile(e.target.files[0])
    }


    return (
        <>
            <HeadingBanner title={settings ? settings[0]?.saving_plan : 'Loading..,'} />
            <section>

                <div className="container">

                    <button type="button" className="d-none" data-bs-toggle="modal" id='openPriceplanpopu' data-bs-target="#razarpaypopup"> sssdsdsd</button>

                    <div className="modal fade" id="razarpaypopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="razarpaypopupLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel">{`${shemaData?.digital_plan?.name ?? shemaData?.name}(Cash Back)`}</h1>

                                    <button type="button" className="btn-close" id='closepriceModal' data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('Rupees').value = null; setprice(null) }}></button>
                                </div>

                                <div className="modal-body">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        if (!price) {
                                            toast.error("Please Enter Price!")
                                        } else
                                            if (parseInt(price) < parseInt(shemaData?.digital_plan?.min_price ?? shemaData?.min_price)) {
                                                toast.error(`Minimum Amount is ${shemaData?.digital_plan?.min_price ?? shemaData?.min_price}!`)
                                            } else if (parseInt(price) > shemaData?.digital_plan?.max_price ?? shemaData?.max_price) {
                                                toast.error(`Maximum Amount is ${shemaData?.digital_plan?.max_price ?? shemaData?.max_price}!`)
                                            }
                                            else if (checked !== true) {
                                                toast.error('Please Agree Terms & conditions')
                                            }

                                            else {
                                                document.getElementById('closepriceModal')?.click();
                                                document.getElementById('payment-pup').click()
                                                document.getElementById('inlineRadio1').checked = false;
                                                document.getElementById('inlineRadio2').checked = false;
                                                document.getElementById('inlineRadio3').checked = false;
                                                setprocess_type();
                                                setclientamount(price)
                                                // openrazar_function(price, shemaData)
                                            }
                                    }}>

                                        <div className="mb-3">
                                            <label className="col-form-label fw-bold">Beneficiaries</label>
                                            <select className="form-select p-3" onChange={(e) => { setbenificid(e.target.value) }} aria-label="ben" >
                                                <option value={''} selected>{detail_info?.name}</option>
                                                {
                                                    benfic ? benfic?.map((data, i) => (
                                                        <option value={data?.id} key={i}>{data.name}</option>
                                                    )) : ''
                                                }
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="Rupees" className="form-label"><span className='fw-bold'>Price</span> <small className='text-danger'>{`(Minimum Amount ${shemaData?.digital_plan?.min_price ?? shemaData?.min_price} to Maximum Amount ${shemaData?.digital_plan?.max_price ?? shemaData?.max_price})`}</small></label>
                                            <input type="text" maxLength={10} onChange={(e) => setprice(e.target.value?.replace(/[^0-9]/g, ""))} className="form-control p-3" id="Rupees" placeholder={`Ex: ${shemaData?.digital_plan?.min_price ?? shemaData?.min_price}`} />
                                        </div>
                                        <div className="mb-3">
                                            <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked(e.target.checked) }} />

                                            <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                                {/* I  agree {
                                            footerpage ? (<a href={`pages/${footerpage[0]?.slug}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>) : (<span className='termsborder'>Terms and Condition</span>)
                                        } */}
                                                I agree <a href={`pages/${shemaData?.page?.slug}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                            </label>
                                        </div>
                                        <div className="mb-3">
                                            <div className="update-profile  mx-2">
                                                <button className="text-white" type="submit" >Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="siptrans-layout">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="siptrans-content position-relative">
                                    <div className="siptrans-head pb-3">
                                        {
                                            // isLoading ? <h4><Shimmer className="rounded" width={180} height={30} /></h4> :
                                            <h4>  {transcation?.purchase_transaction?.length > 0 ? <img src="/assets/iocns/Back.png" className="cursor_pointer" alt="" onClick={() => setTranscation([])} style={{ width: '24px' }} /> : ''} {settings ? settings[0]?.saving_plan : 'Loading...'}</h4>
                                        }

                                    </div>

                                    {
                                        !transcation?.purchase_transaction || transcation.purchase_transaction?.length <= 0 ? (
                                            <div className="row mb-sm-5 mb-lg-0 ">
                                                <div className="col-lg-3 col-6">
                                                    <div className={actid === 1 ? "statustact" : 'py-2'}>
                                                        <div className='plan-viewcard comp status2' onClick={() => { handleGetSchema(1); setactid(1) }}>
                                                            <div className="plan-count">
                                                                <h4>Active</h4>
                                                                <h2>{statuslist?.active ? statuslist?.active : 0}</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <BookmarkCheckFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3  col-6">
                                                    <div className={actid === 3 ? "statustact" : 'py-2'}>
                                                        <div className='plan-viewcard comp status1' onClick={() => { handleGetSchema(3); setactid(3) }}>
                                                            <div className="plan-count ">
                                                                <h4>Completed</h4>
                                                                <h2>{statuslist?.complete ? statuslist?.complete : 0}</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <BookmarkStarFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="cox-xl-33">

                                                    <div className={actid === 4 ? "statustact" : 'py-2'}>
                                                        <div className='plan-viewcard comp status3' onClick={() => { handleGetSchema(4); setactid(4) }}>
                                                            <div className="plan-count">
                                                                <h4>Hold</h4>
                                                                <h2>{statuslist?.hold ? statuslist?.hold : 0}</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <PauseCircleFill />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div> */}
                                                <div className="col-lg-3 col-6">
                                                    <div className={actid === 2 ? "statustact" : 'py-2'}>
                                                        <div className='plan-viewcard comp status4' onClick={() => { handleGetSchema(2); setactid(2) }}>
                                                            <div className="plan-count">
                                                                <h4>Cancelled</h4>
                                                                <h2>{statuslist?.cancel ? statuslist?.cancel : 0}</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <BookmarkXFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-6">
                                                    <div className={actid === 5 ? "statustact" : 'py-2'}>
                                                        <div className='plan-viewcard comp status5' onClick={() => { handleGetSchema(5); setactid(5) }}>
                                                            <div className="plan-count">
                                                                <h4>Suspended</h4>
                                                                <h2>{statuslist?.suspend ? statuslist?.suspend : 0}</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <SlashCircleFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ) : ''
                                    }
                                    {
                                        isLoading ?
                                            (
                                                <>
                                                    <div className="tableload mb-tableload text-center position-absolute">
                                                        <div class="spinner-border text-primary text-center" role="status"></div>
                                                    </div>

                                                </>
                                            )
                                            :

                                            transcation?.purchase_transaction?.length > 0 ?
                                                <>
                                                    {
                                                        actid === 2 || actid === 3 ? (
                                                            <>
                                                                {/* <div className="cancle-histry d-flex justify-content-around align-items-center">
                                                                    <h5>TOTAL AMOUNT : ₹ {transcation?.cancel_total_amount} </h5>
                                                                    <h5>TOTAL GRAM : {transcation?.cancel_total_gram}(gm) </h5>
                                                                    <h5>CANCEL DATE : {moment(transcation?.updated_at).format('DD-MM-YYYY') ?? '-'} </h5>
                                                                </div> */}
                                                                <div className="row">
                                                                    <div className="col-lg-4 mb-4 col-md-4" >
                                                                        <div className="post-bg1  cancbg1 d-flex flex-column justify-content-between w-100">
                                                                            <div className="totalprop">
                                                                                <h4 className="one_line">TOTAL AMOUNT</h4>
                                                                                <h6>Amount : {transcation?.total_amount} </h6>
                                                                                <h6>Service Charge (6%) : {transcation?.service_charge} </h6>

                                                                            </div>
                                                                            <h4 className="pt-2 totaltrans"> ₹ {transcation?.cancel_total_amount ?? transcation?.complete_total_amount}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 mb-4 col-md-4" >
                                                                        <div className="post-bg2  cancbg1  d-flex flex-column justify-content-between  w-100">
                                                                            <div className="totalprop">
                                                                                <h4 className="one_line">TOTAL GRAM</h4>
                                                                            </div>

                                                                            <h4 className=" totaltrans pt-2">{transcation?.cancel_total_gram ?? transcation?.complete_total_gram} (gm)</h4>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 mb-4 col-md-4" >
                                                                        <div className="post-bg3  cancbg1 d-flex flex-column justify-content-between  w-100">
                                                                            <div className="totalprop">
                                                                                <h4 className="one_line">{actid === 2 ? 'CANCEL DATE ' : 'COMPLETED DATE'}</h4>
                                                                            </div>
                                                                            <h4 className=" totaltrans pt-2">{moment(transcation?.updated_at).format('DD-MM-YYYY') ?? '-'}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : ''
                                                    }

                                                    <div className="row">
                                                        {
                                                            transcation?.purchase_transaction?.map((data, i) => (
                                                                <div className="col-sm-6  mb-3" key={i}>
                                                                    <div className={`plan1 p-3 sp7 border rounded d-flex align-items-center justify-content-between`}>
                                                                        <div className="plan-time ">
                                                                            {
                                                                                data?.status == "paid" ?
                                                                                    <p className="text-wrap"> <span className="fw600">Paid Date :</span> ({moment(data?.paid_date).format('DD-MM-YYYY')})</p>
                                                                                    : <p className={"text-wrap"}> <span className="fw600">Schedule Date :</span> ({moment(data?.schedule_date).format('DD-MM-YYYY')})</p>
                                                                            }
                                                                            <p className={" text-wrap mb-1"}><span className="fw600">Transaction Id :</span> {data?.transaction_id ?? '-'}</p>

                                                                        </div>

                                                                        <div className="plan-rate ">
                                                                            {
                                                                                actid === 1 ? (
                                                                                    <>
                                                                                        {data?.admin_paid == 1 ?
                                                                                            <span className="badge text-bg-danger">ADMIN PAY</span> : data?.payment_status == "pending" ? <span>Pending </span> :
                                                                                                data?.status == "unpaid" && moment(new Date()).month() >= moment(data?.schedule_date, "YYYY-MM-DD").month() && moment(new Date()).year() >= moment(data?.schedule_date, "YYYY-MM-DD").year() ?
                                                                                                    <button type="button" onClick={() => { document.getElementById('payment-pup').click(); setmonthlydata(data) }} className="btn paynsd">PAY NOW</button> : data?.status == "paid" ?
                                                                                                        spinner && data?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img src="\assets\iocns\invoice.png" onClick={() => { handledownload(data); handlespinner(data) }} className="ytjsfvdjd" alt="" />)
                                                                                                        :
                                                                                                        <button type="button" disabled className="btn btn-warning">UNPAID</button>
                                                                                        }
                                                                                    </>
                                                                                ) :

                                                                                    actid === 2 ? (
                                                                                        <>
                                                                                            {
                                                                                                data?.status == "paid" ?
                                                                                                    spinner && data?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img src="\assets\iocns\invoice.png" onClick={() => { handledownload(data); handlespinner(data) }} className="ytjsfvdjd" alt="" />)
                                                                                                    :
                                                                                                    <button type="button" disabled className="btn btn-danger">Cancelled</button>
                                                                                            }

                                                                                        </>
                                                                                    )
                                                                                        :
                                                                                        actid === 3 ? (
                                                                                            <>
                                                                                                {
                                                                                                    data?.status == "paid" ? spinner && data?.id == spinid ?
                                                                                                        (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) :
                                                                                                        (<img src="\assets\iocns\invoice.png" onClick={() => { handledownload(data); handlespinner(data) }} className="ytjsfvdjd" alt="" />) :
                                                                                                        <button disabled className="btn btn-primary text-white">Completed</button>
                                                                                                }
                                                                                            </>
                                                                                        ) : ''

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>

                                                </>
                                                :
                                                actid === 1 && ALlData_list?.length === 0 ?

                                                    (<div className="addbank-ac text-center border py-3 my-3">
                                                        <div className="bankimage my-3  ">
                                                            <img src="\assets\iocns\pure gold.png" className="img-fluid rounded p-1 " style={{ border: "1px solid #991313" }} />
                                                        </div>
                                                        <div className="addbank-info ">
                                                            <h5>{settings ? settings[0]?.saving_plan : ''}</h5>
                                                        </div>
                                                        <div className="addbank-btn text-white my-3">
                                                            <button className="text-white" onClick={() => { setshemaData(plandetails); document.getElementById('openPriceplanpopu').click(); document.getElementById('agreecheck').checked = false; setchecked(false) }} data-bs-whatever="@getbootstrap" >Buy Scheme</button>
                                                        </div>
                                                    </div>)

                                                    : ALlData_list?.length > 0 ? (
                                                        <Paper sx={{ height: 450, width: '100%' }} className="tabledata">
                                                            <DataGrid
                                                                rows={ALlData_list?.map((item, index) => ({ s_no: index + 1, title: item?.beneficiary?.name ?? item?.users?.name, ...item }))}
                                                                columns={actid === 1 ? columns : actid === 2 ? columns2 : actid === 3 ? columns3 : actid === 4 ? columns4 : columns5}
                                                                disableColumnMenu
                                                                initialState={{ pagination: { paginationModel } }}
                                                                pageSizeOptions={[5, 10]}
                                                                sx={{
                                                                    border: 0, marginTop: 3,
                                                                    '.MuiDataGrid-iconButtonContainer': {
                                                                        visibility: 'visible',
                                                                    },
                                                                    '.MuiDataGrid-sortIcon': {
                                                                        opacity: 'inherit !important',
                                                                    },
                                                                }}
                                                                disableColumnFilter
                                                                disableColumnSelector
                                                                disableDensitySelector
                                                                className="schemedata-table"
                                                                slots={{ toolbar: GridToolbar }}
                                                                // slots={{ toolbar: QuickSearchToolbar }}
                                                                slotProps={{
                                                                    toolbar: {
                                                                        showQuickFilter: true,
                                                                        printOptions: { disableToolbarButton: true },
                                                                        csvOptions: { disableToolbarButton: true },
                                                                        ShowCellToolTip: false
                                                                    },
                                                                }}
                                                            />
                                                        </Paper>
                                                    ) : <div className="text-center no-commision">
                                                        <img src="\assets\noData.png" className="img-fluid" alt="" />
                                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="cancelboxmsg" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <button className="d-none" data-toggle="modal" data-target="#cancelboxmsg" id="cancelpopmsg"></button>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content schemecard">
                        <div className="modal-header poptitle justify-content-center border-0">
                            <h5 className="modal-title" id="exampleModalLongTitle">{settings ? settings[0]?.saving_plan : ''}</h5>
                        </div>
                        <div className="modal-body pt-0 text-center">
                            <div className="pop-cancel-icon">
                                <img src="\assets\iocns\cross mark.png" className="img-fluid" alt="" />
                            </div>
                            <div className="pop-submsg d-flex justify-content-center pt-2">

                                {
                                    isLoading ? (<>
                                        <div><Shimmer className="rounded" width={350} height={60} /></div>
                                    </>) : (<>
                                        {
                                            activebank === 0 ? <h4 className="cancel-msg">
                                                To complete the cancellation process, kindly submit your bank details.
                                            </h4>
                                                :
                                                Kycactive === 0 && finalamount >= 200000 ? <h4>
                                                    Your account balance has exceeded ₹2,00,000. To cancel your plan, please submit your KYC details. Service charges will apply.
                                                </h4> :
                                                    <h4 className="cancel-msg">If you cancel, the final amount will be {finalamount ? `₹${finalamount}` : ''} excluding  service charges.</h4>
                                        }

                                    </>)

                                }

                            </div>
                            <div className="mt-3">
                                {
                                    activebank ? activebank === 1 ? (
                                        <>
                                            <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked(e.target.checked) }} />

                                            <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                                I agree <a href={`pages/${cancel_slug ? cancel_slug : ''}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                            </label>
                                        </>
                                    ) : '' : ''
                                }
                                {/* <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked(e.target.checked) }} />

                                <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                    I agree <a href={`pages/${cancel_slug ? cancel_slug : ''}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                </label> */}
                            </div>
                            <div className="modal-footer border-0 justify-content-center ">

                                {
                                    activebank === 0 ? <button type="button " className="btn bg-danger text-white" onClick={() => { document.getElementById('cancelpopmsg').click(); router('/user/mybank-account') }} >Submit</button> :
                                        Kycactive === 0 && finalamount >= 200000 ? <button type="button " className="btn bg-danger text-white" onClick={() => { document.getElementById('cancelpopmsg').click(); router('/user/kyc') }} >Submit</button> :
                                            <button type="button " className="btn bg-danger text-white" onClick={() => { handlecancel() }} >Cancel</button>
                                }

                                <button type="button " className="btn bg-secondary text-white" data-dismiss="modal">Close</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="cancelpipup" tabindex="-1" aria-labelledby="cancelpipupLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header poptitle justify-content-center border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('cancelpipup').click() }} ></button>
                        </div>
                        <div className="modal-body pt-0 text-center">
                            <div className="pop-cancel-icon">
                                <img src="\assets\iocns\warning.png" className="img-fluid" alt="" />
                            </div>
                            <div className="pop-submsg pt-2 d-flex justify-content-center text-center">
                                <div className="plan-name-sub">
                                    <h3 className="">{settings ? settings[0]?.saving_plan : ''}</h3>
                                    <p className="mb-0">You can only cancel the plan after completing 3 months. Please reach out to support for more details.</p>

                                </div>
                            </div>
                            <div className="modal-footer border-0 justify-content-center scmcanc ">
                                <Link to={'/contact'} target="_blank" className="text-color"> <button className="btn btn-danger">Contact Support</button> </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* qr-code-modal */}
            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#offlinebox" id='offlinepop-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="offlinebox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel"><span className='mx-2' style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('offlinepop-pup').click(); document.getElementById('payment-pup').click() }}><ArrowLeft /></span>{settings[0]?.saving_plan}(Cash Back)</h1>

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('offlinepop-pup').click() }} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="qr-detail text-center">
                                    <h6>{settings[0]?.account_holder}</h6>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <div className="qr-upiid text-center">
                                        <h6 className='mb-0'>Vyapar.171813426519@hdfcbank</h6>
                                    </div>
                                </div>
                                <div className="qq-sacn-img p-2">
                                    <img src={settings[0]?.qr_code} alt='' className='img-fluid' />
                                </div>
                                <form onSubmit={upimethod}>
                                    <div className="uplo-img ">
                                        <input type='file' className='d-none' id='scanimg' onChange={screenshot} />
                                        <button className='btn' type='button' onClick={() => { document.getElementById('scanimg').click() }}>Upload Transaction Slip <img src='\assets\iocns\upload.png' alt='' className='img-fluid' /></button>

                                        {
                                            file1 ? (
                                                <>
                                                    <div className='uploadfile-name position-relative'>
                                                        <p className='m-0'>{file1 ? `File Name : ${file1}` : ''}</p>
                                                        <h6 className='position-absolute remove-upload' onClick={() => { setfile1(); settransfile() }}><X /></h6>
                                                    </div>
                                                </>
                                            ) : ''
                                        }

                                        <input type="text" class="form-control bg-white text-dark border text-center my-2" placeholder="Transaction ID" aria-label="transaction-id" id='transaction-id' onChange={(e) => { setTranscationid(e.target.value) }} />
                                        <p className='text-danger '>* Upload the transaction slip or enter the transaction ID</p>
                                    </div>
                                    <div className="update-profile">
                                        <button className="text-white" type="submit">{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Continue'}</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* payment-modal */}
            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#paymentbox" id='payment-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="paymentbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel">{settings[0]?.saving_plan}(Cash Back)</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('payment-pup').click() }} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="pm-ti">
                                    <h5> Choose Payment Method</h5>
                                </div>
                                <div className="pm-choice ">
                                    <form onSubmit={paymentfun}>
                                        <div className={process_type == '1' ? 'form-check act' : 'form-check'}>
                                            <div className="selecttypes p-2 d-flex align-items-center gap-2">
                                                <input class="form-check-input mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                {/* <label class="form-check-label" for="inlineRadio1">Online Razarpay</label> */}

                                                <div className='razoricon'>
                                                    <img src='\assets\images\visa.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\mascard.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\rupay.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\phopay.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\mastro.png' className='img-fluid' alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={process_type == '2' ? 'form-check act' : 'form-check'} >
                                            <div className="selecttypes p-2  d-flex align-items-center gap-2">
                                                <input class="form-check-input  mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                {/* <label class="form-check-label" for="inlineRadio2">QR code / UPI</label> */}
                                                <label class="form-check-label" for="inlineRadio2">Direct UPI Payments (QR Code) </label>
                                                <div className='razoricon'><img src='\assets\images\upis.png' className='img-fluid' alt='' /></div>
                                            </div>
                                        </div>
                                        <div className={process_type == '3' ? 'form-check act' : 'form-check'}>
                                            <div className="selecttypes py-3 px-2  d-flex align-items-center gap-2">
                                                <input class="form-check-input  mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="3" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                {/* <label class="form-check-label" for="inlineRadio3">Bank Details</label> */}
                                                <label class="form-check-label" for="inlineRadio3">For RTGS / NEFT / IMPS</label>
                                                <div className='banic'><img src='\assets\images\net_img.png' className='img-fluid' alt='' /></div>
                                            </div>
                                        </div>
                                        <div className="update-profile ">
                                            <button className="text-white" type="submit">Continue</button>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* bandetail-modal */}
            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#bankbox" id='bankpop-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="bankbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel"><span className='mx-1' style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('bankpop-pup').click(); document.getElementById('payment-pup').click() }}><ArrowLeft /></span>{settings[0]?.saving_plan}(Cash Back)saasd </h1>

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('bankpop-pup').click() }} ></button>
                            </div>
                            <div className="modal-body ac-holdbank ">
                                <h5 className='mb-4'>Bank Details</h5>

                                <div className="acc-holder">
                                    <img src='\assets\iocns\Profile.png' className='img-fluid' /><span>{settings[0]?.account_holder}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\accountnumber.png' className='img-fluid' /><span>{settings[0]?.account_number}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\bank.png' className='img-fluid' /><span>{settings[0]?.bank_name}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\branchlocatin.png' className='img-fluid' /><span>{settings[0]?.branch_name}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\ifsc code.png' className='img-fluid' /><span>{settings[0]?.ifsc_code}</span>
                                </div>
                                <form onSubmit={Bankmethod}>

                                    <div className="uplo-img text-start">
                                        <input type='file' className='d-none' id='scanimg' onChange={screenshot} />
                                        <button className='btn ' type='button' onClick={() => { document.getElementById('scanimg').click() }}>Upload Transaction Slip <img src='\assets\iocns\upload.png' alt='' className='img-fluid' /></button>
                                        {
                                            file1 ? (
                                                <>
                                                    <div className='uploadfile-name position-relative'>
                                                        <p className='m-0'>{file1 ? `File Name : ${file1}` : ''}</p>
                                                        <h6 className='position-absolute remove-upload' onClick={() => { setfile1(); settransfile() }}><X /></h6>
                                                    </div>
                                                </>
                                            ) : ''
                                        }
                                        <input type="text" class="form-control bg-white text-dark border text-center my-2" placeholder="Transaction ID" id='transaction-id2' aria-label="transaction-id" onChange={(e) => { setTranscationid(e.target.value) }} />
                                        <p className='text-danger '>* Upload the transaction slip or enter the transaction ID</p>
                                    </div>

                                    <div className="update-profile mt-2 mb-0">
                                        <button className="text-white" type="submit" >{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Continue'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
