import React, { useContext, useEffect, useState, useCallback } from "react"
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import moment from "moment/moment"
import useRazorpay from "react-razorpay";
import toast from 'react-hot-toast';
import { Image, Shimmer } from 'react-shimmer'
// import { getInvoiceDown, getShemesSchedule, postMonthShec, SetPlanCancell } from "../api/Pageapi"
import { Link, useNavigate } from "react-router-dom"
import { Logindetail } from "../context/Logindetail"
import { GetGoldPlantApi, RequestCancelApi, SaveGoldplan } from "../api/RouteScheme3.js";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { ArrowLeft, BookmarkCheckFill, BookmarkStarFill, BookmarkXFill, PauseCircleFill, SlashCircleFill, X } from "react-bootstrap-icons";
import { fetchBeneficiaries } from "../api/Pageapi.js";

export default function GoldPlantsSchems({ settings }) {


    // const RAZAR_KEY = process.env.REACT_APP_RAZAR_KEY;

    const RAZAR_KEY = "rzp_live_FZ6iVw5tcRGpW5";

    // const RAZAR_KEY = "rzp_test_K7CipNQYyyMPiS";


    const router = useNavigate()

    const { token, detail_info, setdetail_info } = useContext(Logindetail)


    const [full, setFull] = useState([])
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [shemData, setSchemaData] = useState()
    const [isLoading, setisLoading] = useState(true);
    // const [sort, setSort] = useState(5)
    // const [search, setSearch] = useState('');
    // const [transcation, setTranscation] = useState()
    // const [Entername, setEntername] = useState()
    const [ALlData_list, setALlData_list] = useState()
    const [spinner, setspinner] = useState(false)
    const [spinid, setspinid] = useState()
    const [plandetails, setplandetails] = useState()
    const [statuslist, setstatuslist] = useState({
        'active': '',
        'hold': '',
        'complete': '',
        'suspend': '',
        'cancel': ''
    })

    const [SelectPlan, setSelectPlan] = useState('')
    const [ChargeAmt, setChargeAmt] = useState()
    const [benificid, setbenificid] = useState('')
    const [price, setprice] = useState()
    const [checked, setchecked] = useState()
    const [checked2, setchecked2] = useState()
    const [benfic, setbenfic] = useState()
    const [actid, setactid] = useState()
    const [Transactionid, setTranscationid] = useState()
    const [transfile, settransfile] = useState()
    const [file1, setfile1] = useState()
    const [process_type, setprocess_type] = useState()
    const [clientamount, setclientamount] = useState()



    useEffect(() => {
        if (SelectPlan) {
            document.getElementById('CancelPopupHHH')?.click();
        }

    }, [SelectPlan])



    const handleGetSchema = (slug) => {

        setisLoading(true)
        GetGoldPlantApi(slug).then(res => {
            setALlData_list(res?.data)
            setFull(res?.data)
            setplandetails(res?.plan)
            setstatuslist(previousState => {
                return { ...previousState, active: res?.active_count, hold: res?.hold_count, complete: res?.complete_count, suspend: res?.suspended_count, cancel: res?.cancel_count }
            })
            setisLoading(false)
        }).catch(err => {
            setisLoading(false)
        })
    }


    const handleRequestCancel = () => {

        // if (!checked2) {
        //     toast.error('Please agree Terms and condition')
        // }
        // else {
        // }

        RequestCancelApi(SelectPlan?.id)
            .then(res => {
                setSelectPlan('')
                toast.success(res?.data ?? 'Cancel SuucessFull')
                // document.getElementById('cancelpipup').click()
                handleGetSchema(1);

            }).catch(err => {
                console.log("err", err.message);
            })


    }


    const handlespinner = (data) => {
        setspinid(data?.id)
        setspinner(true)
    }



    useEffect(() => {
        handleGetSchema(1);
        setactid(1)
    }, []);

    const handledownload = (value) => {
        const baseurl = process.env.REACT_APP_BASE_URL
        let fileName = `${moment(value?.created_at).format('DD-MM-YYYY')}-invoice.pdf`;
        const url = `${baseurl}gold_plant_invoice?id=${value?.id}`;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setspinner(false)
                setspinid()
            })
            .catch(error => {
                console.error('Error fetching PDF: ', error);
                window.open(url, "_blank");
                setspinner(false)
                setspinid()
            });
    }


    // const handlesearch = () => {
    //     const name = Entername?.toLowerCase();
    //     const filtrer = ALlData_list.filter(e => e.beneficiary_id ? e.beneficiary?.name?.toLowerCase().includes(name) : detail_info?.name?.toLowerCase().includes(name));
    //     setShcudle(filtrer)
    // }


    const paginationModel = { page: 0, pageSize: 5 };

    // useEffect(() => {
    //     if (Entername) {
    //         handlesearch();
    //     } else {
    //         setShcudle(ALlData_list);
    //     }

    // }, [Entername])


    const handlePaymentgoldplant = useCallback(async () => {
        const options = {
            key: RAZAR_KEY,
            amount: (ChargeAmt) * 100,
            currency: "INR",
            name: 'Greenheap',
            description: 'Greenheap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "gold_plant_id": shemData?.id,
                    "amount": ChargeAmt,
                    "transaction_id": res?.razorpay_payment_id,
                    'benificiar': benificid,
                    "payment_method": 'razor_pay',
                }
                SaveGoldplan(body).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    setShowRazorpayModal(false);
                    handleGetSchema(1);
                    setbenificid()
                }).catch(err => {
                    console.log('err', err.message)
                })

            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [ChargeAmt, shemData]);


    const logout = () => {
        localStorage.removeItem("green_login")
        localStorage.removeItem("phone")
        localStorage.removeItem("green_token")
        localStorage.removeItem("kyc")
        router('/');
        setdetail_info();
    }

    const handlefectch = () => {
        fetchBeneficiaries()
            .then(res => {
                setbenfic(res?.data)
                setisLoading(false);
                if (res?.data?.status == 403) {
                    logout();
                }
            }).catch(err => {
                console.log('err', err.message);
                setisLoading(false)
            });
    }




    const paymentfun = (e) => {
        e.preventDefault()

        if (process_type == '1') {

            setShowRazorpayModal(true);
            handlePaymentgoldplant()
            document.getElementById('payment-pup').click()
            document.getElementById('Rupees').value = null
        }
        else if (process_type == '2') {
            document.getElementById('payment-pup').click()
            document.getElementById('offlinepop-pup').click()
            document.getElementById('transaction-id').value = null
            setfile1()
            settransfile()
            setTranscationid()
        }
        else if (process_type == '3') {
            document.getElementById('payment-pup').click()
            document.getElementById('bankpop-pup').click()
            document.getElementById('transaction-id2').value = null
            setTranscationid()
            setfile1()
            settransfile()
        }
        else {
            toast.error('please select payment method')
        }

    }

    const upimethod = (e) => {
        e.preventDefault()

        if (!transfile) {
            toast.error('please Upload the transaction slip');
            return false;
        }

        if (Transactionid || transfile) {
            setspinner(true)
            const body = {
                "gold_plant_id": shemData?.id,
                "amount": clientamount,
                "transaction_id": Transactionid ? Transactionid : '',
                'benificiar': benificid,
                "payment_method": 'upi',
            }
            SaveGoldplan(body).then(res => {
                if (res?.error) {
                    return toast.error(res?.error ?? 'Somthing Went Wrong!')
                }
                toast.success(`Payment successful from ${shemData?.name}`)
                document.getElementById('offlinepop-pup').click()
                setShowRazorpayModal(false);
                handleGetSchema(1);
                setbenificid()
                setspinner(false)
            }).catch(err => {
                console.log('err', err.message)
            })
        }
        else {
            toast.error('please Upload the transaction slip or enter the transaction ID')
        }
    }

    const Bankmethod = (e) => {
        e.preventDefault()

        if (!transfile) {
            toast.error('please Upload the transaction slip');
            return false;
        }

        if (Transactionid || transfile) {
            setspinner(true)

            const body = {
                "gold_plant_id": shemData?.id,
                "amount": clientamount,
                "transaction_id": Transactionid ? Transactionid : '',
                'benificiar': benificid,
                "payment_method": 'bank',
            }
            SaveGoldplan(body).then(res => {
                if (res?.error) {
                    return toast.error(res?.error ?? 'Somthing Went Wrong!')
                }
                toast.success(`Payment successful from ${shemData?.name}`)
                document.getElementById('bankpop-pup').click()
                setShowRazorpayModal(false);
                handleGetSchema(1);
                setbenificid()
                setspinner(false)
            }).catch(err => {
                console.log('err', err.message)
            })



        }
        else {
            toast.error('please Upload the transaction slip or enter the transaction ID')
        }


    }

    const screenshot = (e) => {
        console.log(e.target.files[0])
        setfile1(e.target.files[0]?.name)
        settransfile(e.target.files[0])
    }

    useEffect(() => {
        handlefectch()
    }, [])


    const columns = [

        { field: 's_no', headerName: 'S.No', width: 90 },
        {
            field: 'name',
            headerName: 'NAME',
            width: 130,
            // valueGetter: (value, row) => `${row.beneficiary_id ? row?.beneficiary?.name ?? '-' : detail_info?.name}`,
            valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,

        },
        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 140,
            valueGetter: (value, row) => `${row?.gold_gram ? `${row?.gold_gram}(gm)` : '-'}`,

        },
        {
            field: 'total',
            headerName: 'TOTAL',
            width: 130,
            valueGetter: (value, row) => `${`₹${row?.amount}` ?? '-'}`,
        },
        {
            field: 'purchase_Date',
            headerName: 'PURCHASE DATE',
            width: 190,
            valueGetter: (value, row) => `${moment(row?.created_at).format('DD-MM-YYYY') ?? '-'}`,

        },
        {
            field: 'status',
            headerName: 'STATUS',
            width: 155,
            renderCell: (params) => (
                <button type="button" className="btn btn-outline-danger" onClick={() => {
                    setSelectPlan(params?.row);
                }}>cancel</button>
            )
        },
        {
            field: 'invoice',
            headerName: 'INVOICE',
            width: 130,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border  spinner-border-sm" role="status"></span>) : (<img alt="" src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)
                    }
                </>
            ),
            sortable: false,
        }


    ];
    const columns2 = [

        { field: 's_no', headerName: 'S.No', width: 140 },
        {
            field: 'name',
            headerName: 'NAME',
            width: 150,
            // valueGetter: (value, row) => `${row.beneficiary_id ? row?.beneficiary?.name ?? '-' : detail_info?.name}`,
            valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,

        },
        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 170,
            valueGetter: (value, row) => `${row?.gold_gram ? `${row?.gold_gram}(gm)` : '-'}`,

        },
        {
            field: 'total',
            headerName: 'TOTAL',
            width: 150,
            valueGetter: (value, row) => `${`₹${row?.amount}` ?? '-'}`,
        },
        {
            field: 'cancel_date',
            headerName: 'CANCELLED DATE',
            width: 220,
            valueGetter: (value, row) => `${moment(row?.updated_at).format('DD-MM-YYYY') ?? '-'}`,

        },
        {
            field: 'invoice',
            headerName: 'STATUS',
            width: 140,
            renderCell: (params) => (
                <>
                    <span className="">Cancelled</span>
                </>
            ),
            // renderCell: (params) => (
            //     <>
            //         {
            //             spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm" role="status"></span>) : (<img alt="" src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)
            //         }
            //     </>
            // ),
            sortable: false,
        }


    ];
    const columns3 = [

        { field: 's_no', headerName: 'S.NO', width: 80 },
        {
            field: 'name',
            headerName: 'NAME',
            width: 120,
            // valueGetter: (value, row) => `${row.beneficiary_id ? row?.beneficiary?.name ?? '-' : detail_info?.name}`,
            valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,

        },
        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 120,
            valueGetter: (value, row) => `${row?.gold_gram ? `${row?.gold_gram}(gm)` : '-'}`,

        },
        {
            field: 'total',
            headerName: 'TOTAL',
            width: 100,
            valueGetter: (value, row) => `${`₹${row?.amount}` ?? '-'}`,
        },
        {
            field: 'completed_date',
            headerName: 'COMPLETED DATE',
            width: 172,
            valueGetter: (value, row) => `${moment(row?.created_at).format('DD-MM-YYYY') ?? '-'}`,

        },
        {
            field: 'withdrawtype',
            headerName: 'WITHDRAW TYPE',
            width: 140,
            valueGetter: (value, row) => `${row?.withdraw ?? '-'}`,
            sortable: false,

        },
        {
            field: 'withdraw_date',
            headerName: 'WITHDRAW DATE',
            width: 150,
            valueGetter: (value, row) => `${moment(row?.withdraw_date).format('DD-MM-YYYY') ?? '-'}`,
            sortable: false,

        },

        {
            field: 'invoice',
            headerName: 'INVOICE',
            width: 73,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm" role="status"></span>) : (<img alt="" src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)
                    }
                </>
            ),
            sortable: false,
        }


    ];
    const columns4 = [

        { field: 's_no', headerName: 'S.NO', width: 140 },
        {
            field: 'name',
            headerName: 'NAME',
            width: 150,
            // valueGetter: (value, row) => `${row.beneficiary_id ? row?.beneficiary?.name ?? '-' : detail_info?.name}`,
            valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,

        },
        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 170,
            valueGetter: (value, row) => `${row?.gold_gram ? `${row?.gold_gram}(gm)` : '-'}`,

        },
        {
            field: 'total',
            headerName: 'TOTAL',
            width: 160,
            valueGetter: (value, row) => `${`₹${row?.amount}` ?? '-'}`,
        },
        {
            field: 'hold_date',
            headerName: 'HOLD DATE',
            width: 200,
            valueGetter: (value, row) => `${moment(row?.created_at).format('DD-MM-YYYY') ?? '-'}`,

        },

        {
            field: 'invoice',
            headerName: 'INVOICE',
            width: 144,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm" role="status"></span>) : (<img alt="" src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)
                    }
                </>
            ),
            sortable: false,
        }


    ];
    const columns5 = [

        { field: 's_no', headerName: 'S.NO', width: 140 },
        {
            field: 'name',
            headerName: 'NAME',
            width: 150,
            // valueGetter: (value, row) => `${row.beneficiary_id ? row?.beneficiary?.name ?? '-' : detail_info?.name}`,
            valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,

        },
        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 170,
            valueGetter: (value, row) => `${row?.gold_gram ? `${row?.gold_gram}(gm)` : '-'}`,

        },
        {
            field: 'total',
            headerName: 'TOTAL',
            width: 150,
            valueGetter: (value, row) => `${`₹${row?.amount}` ?? '-'}`,
        },
        {
            field: 'suspended_Date',
            headerName: 'SUSPENDED DATE',
            width: 210,
            valueGetter: (value, row) => `${moment(row?.created_at).format('DD-MM-YYYY') ?? '-'}`,

        },

        {
            field: 'invoice',
            headerName: 'INVOICE',
            width: 144,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm" role="status"></span>) : (<img alt="" src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)
                    }
                </>
            ),
            sortable: false,
        }

    ];


    return (
        <>
            <button type="button" className="btn d-none" id="CancelPopupHHH" data-bs-toggle="modal" data-bs-target="#cancelpipup" ></button>
            <HeadingBanner title={settings ? settings[0]?.gold_plant : 'Loading...'} />
            <section>

                <button type="button" className="d-none" data-bs-toggle="modal" id='openPriceplanpopu' data-bs-target="#razarpaypopup"></button>

                <div className="modal fade" id="razarpaypopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="razarpaypopupLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className={shemData?.plan_title === "gold_plant" ? 'modal-content schemecard ' : 'modal-content schemecard '}>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel">{shemData?.plan_title === "digital_plan" ? `${shemData?.name}(Cash Back)` : shemData?.plan_title === "gold_plant" ? `${shemData?.name}(Monthly Rental)` : shemData?.name}</h1>

                                <button type="button" className="btn-close" id='closepriceModal' data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('Rupees').value = null; setprice(null) }} ></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={(e) => {
                                    e.preventDefault();

                                    if (!price) {
                                        toast.error("Please Enter Price!")
                                    } else {
                                        const amount = parseFloat(price) + parseFloat(((price * 6 / 100) + (price * 3 / 100)))

                                        if (parseInt(price) < parseInt(shemData?.min_price)) {
                                            toast.error(`Minmum Amount is ${shemData?.min_price}!`)
                                        } else if (parseInt(price) > shemData?.max_price) {
                                            toast.error(`Maximum Amount is ${shemData?.max_price}!`)
                                        } else if (checked !== true) {
                                            toast.error('Please Agree Terms & conditions')
                                        }
                                        else {

                                            document.getElementById('closepriceModal')?.click();
                                            document.getElementById('payment-pup').click()
                                            document.getElementById('inlineRadio1').checked = false;
                                            document.getElementById('inlineRadio2').checked = false;
                                            document.getElementById('inlineRadio3').checked = false;
                                            setprocess_type();
                                            setclientamount(price)
                                            setChargeAmt(amount)

                                        }
                                    }

                                }}>
                                    <div className="mb-3">
                                        <label className="col-form-label fw-bold">Beneficiaries</label>
                                        <select className="form-select p-3" onChange={(e) => { setbenificid(e.target.value) }} aria-label="ben" >
                                            <option value={''} selected>{detail_info?.name}</option>
                                            {
                                                benfic ? benfic.map((data, i) => (
                                                    <option value={data?.id} key={i}>{data.name}</option>
                                                )) : ''
                                            }
                                        </select>
                                    </div>


                                    <div className="mb-3">
                                        <label htmlFor="Rupees" className="form-label"><span className='fw-bold'>Price</span> <small className='text-danger'>{`(Minimum Amount ${shemData?.min_price} to Maximum Amount 500000)`}</small></label>
                                        <input type="text" maxLength={10} value={price} onChange={(e) => setprice(e.target.value?.replace(/[^0-9]/g, ""))} className="form-control p-3" id="Rupees" placeholder={`Ex: ${shemData?.min_price}`} />
                                        {/* {shemData?.plan_title === "gold_plant" && price ? <span className='text-color'>Deduct Gst & Service tax  : {(price - ((price * 6 / 100) + (price * 3 / 100))).toFixed(2)}</span> : ''} */}
                                        <p className='text-color m-0'>VAT charge : {price ? (price * 3 / 100).toFixed(2) : 0}</p>
                                        <p className='text-color m-0'>Service charge : {price ? (price * 6 / 100).toFixed(2) : 0}</p>
                                        <p className='text-color m-0'>VAT + Service  : {price ? ((price * 6 / 100) + (price * 3 / 100)).toFixed(2) : 0}</p>
                                        <p className='text-color m-0'>Total Amount   : {price ? (parseFloat(price) + ((price * 6 / 100) + (price * 3 / 100))) : 0}</p>

                                    </div>
                                    <div className="mb-3">
                                        <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked(e.target.checked) }} />

                                        <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                            {/* I  agree {
                                footerpage ? (<a href={`pages/${footerpage[0]?.slug}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>) : (<span className='termsborder'>Terms and Condition</span>)
                            } */}
                                            I agree <a href={`pages/${shemData?.page?.slug}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <div className="update-profile  mx-2">
                                            <button className="text-white" type="submit">Continue</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="siptrans-layout">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="siptrans-content position-relative" >

                                    <div className="siptrans-head pb-3">
                                        {
                                            // isLoading ? <h4><Shimmer className="rounded " width={180} height={30} /></h4> :
                                            <h4> {settings ? settings[0]?.gold_plant : 'Loading...'}</h4>
                                        }
                                    </div>
                                    <div className="row mb-md-5 mb-lg-0">
                                        <div className="col-lg-3 col-6">
                                            <div className={actid === 1 ? "statustact my-2" : 'my-2'}>
                                                <div className='plan-viewcard comp status2' onClick={() => { handleGetSchema(1); setactid(1) }}>
                                                    <div className="plan-count">
                                                        <h4>Active</h4>
                                                        <h2>{statuslist?.active ? statuslist?.active : 0}</h2>
                                                    </div>
                                                    <div className="icon-position">
                                                        <BookmarkCheckFill />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className={actid === 3 ? "statustact my-2" : 'my-2'}>
                                                <div className='plan-viewcard comp status1' onClick={() => { handleGetSchema(3); setactid(3) }}>
                                                    <div className="plan-count ">
                                                        <h4>Completed</h4>
                                                        <h2>{statuslist?.complete ? statuslist?.complete : 0}</h2>
                                                    </div>
                                                    <div className="icon-position">
                                                        <BookmarkStarFill />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="cox-xl-33">
                                            <div className={actid === 4 ? "statustact my-2" : 'my-2'}>
                                                <div className='plan-viewcard comp status3' onClick={() => { handleGetSchema(4); setactid(4) }}>
                                                    <div className="plan-count">
                                                        <h4>Hold</h4>
                                                        <h2>{statuslist?.hold ? statuslist?.hold : 0}</h2>
                                                    </div>
                                                    <div className="icon-position">
                                                        <PauseCircleFill />
                                                    </div>
                                                </div>
                                            </div>

                                        </div> */}
                                        <div className="col-lg-3 col-6">
                                            <div className={actid === 2 ? "statustact my-2" : 'my-2'}>
                                                <div className='plan-viewcard comp status4' onClick={() => { handleGetSchema(2); setactid(2) }}>
                                                    <div className="plan-count">
                                                        <h4>Cancelled</h4>
                                                        <h2>{statuslist?.cancel ? statuslist?.cancel : 0}</h2>
                                                    </div>
                                                    <div className="icon-position">
                                                        <BookmarkXFill />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className={actid === 5 ? "statustact my-2" : 'my-2'}>
                                                <div className='plan-viewcard comp status5' onClick={() => { handleGetSchema(5); setactid(5) }}>
                                                    <div className="plan-count">
                                                        <h4>Suspended</h4>
                                                        <h2>{statuslist?.suspend ? statuslist?.suspend : 0}</h2>
                                                    </div>
                                                    <div className="icon-position">
                                                        <SlashCircleFill />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        isLoading ? (
                                            <>
                                                {/* <div className="siptrans-table border mt-4" >
                                                    <div className="siptable-view">
                                                        <table className="table">
                                                            <thead className="p-3">
                                                                <tr className="text-center">
                                                                    <th scope="col" >S.NO</th>
                                                                    <th scope="col" >Name</th>
                                                                    <th scope="col">Weight</th>
                                                                    <th scope="col">Total</th>
                                                                    <th scope="col">Purchase</th>
                                                                    <th scope="col">Status</th>
                                                                    <th scope="col">Invoice</th>

                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    Array.from({ length: 6 }).map((_, i) => {
                                                                        return (
                                                                            <tr className="text-center" key={i}>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block ms-2" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>



                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div> */}
                                                <div className="tableload mb-tableload text-center position-absolute">
                                                    <div class="spinner-border text-primary text-center" role="status"></div>
                                                </div>

                                            </>
                                        )
                                            :

                                            actid === 1 && ALlData_list?.length === 0 ?

                                                (<div className="addbank-ac text-center border py-3 my-3">
                                                    <div className="bankimage my-3  ">
                                                        <img alt="" src="\assets\iocns\pure gold.png" className="img-fluid rounded p-1 " style={{ border: "1px solid #991313" }} />
                                                    </div>
                                                    <div className="addbank-info ">
                                                        <h5>Green Heap Gold Plant Scheme</h5>
                                                    </div>
                                                    <div className="addbank-btn text-white my-3">
                                                        <button className="text-white" onClick={() => { setSchemaData(plandetails); document.getElementById('openPriceplanpopu').click() }} data-bs-whatever="@getbootstrap" >Buy Scheme</button>
                                                    </div>
                                                </div>)

                                                : ALlData_list?.length > 0 ? (
                                                    <Paper sx={{ height: 450, width: '100%' }} className="tabledata">
                                                        <DataGrid
                                                            rows={ALlData_list?.map((item, index) => ({ s_no: index + 1, title: item?.beneficiary?.name ?? item?.users?.name, ...item }))}
                                                            columns={actid === 1 ? columns : actid === 2 ? columns2 : actid === 3 ? columns3 : actid === 4 ? columns4 : columns5}
                                                            disableColumnFilter
                                                            disableColumnSelector
                                                            disableDensitySelector
                                                            disableColumnMenu
                                                            initialState={{ pagination: { paginationModel } }}
                                                            pageSizeOptions={[5, 10]}
                                                            sx={{
                                                                border: 0, marginTop: 3,
                                                                '.MuiDataGrid-iconButtonContainer': {
                                                                    visibility: 'visible',
                                                                },
                                                                '.MuiDataGrid-sortIcon': {
                                                                    opacity: 'inherit !important',
                                                                },

                                                            }}
                                                            slots={{ toolbar: GridToolbar }}
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                    printOptions: { disableToolbarButton: true },
                                                                    csvOptions: { disableToolbarButton: true },

                                                                },
                                                            }}

                                                            className="schemedata-table"
                                                        />
                                                    </Paper>

                                                ) : <div className="text-center no-commision">
                                                    <img src="\assets\noData.png" className="img-fluid" alt="" />
                                                </div>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="cancelpipup" tabindex="-1" aria-labelledby="cancelpipupLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header poptitle justify-content-center border-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('cancelpipup').click() }} ></button>
                                {/* <h5 className="modal-title" id="exampleModalLongTitle">{settings ? settings[0]?.saving_plan : ''}</h5> */}
                            </div>
                            <div className="modal-body pt-0  text-center">
                                <div className="pop-cancel-icon">
                                    <img src="\assets\iocns\warning.png" className="img-fluid" alt="" />
                                </div>
                                <div className="pop-submsg pt-2 d-flex justify-content-center text-center">
                                    <div className="plan-name-sub">
                                        <h3 className="">{settings ? settings[0]?.gold_plant : ''}</h3>
                                        <p className="mb-0">To cancel your Gold Plan Scheme, please contact our admin for assistance.</p>
                                    </div>
                                </div>
                                {/* <div>
                                    <input className="form-check-input" type="checkbox" value="" id="gplant-check" onChange={(e) => { setchecked2(e.target.checked) }} />

                                    <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                        I agree <a href={`pages/${cancel_slug ? cancel_slug : ''}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                      
                                    </label>
                                </div> */}
                                <div className="modal-footer border-0 justify-content-center ">
                                    <button type="button" onClick={() => handleRequestCancel()} className="btn btn-danger px-3" data-bs-dismiss="modal"  >Contact Admin</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* qr-code-modal */}

            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#offlinebox" id='offlinepop-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="offlinebox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel"><span className='mx-2' style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('offlinepop-pup').click(); document.getElementById('payment-pup').click() }}><ArrowLeft /></span>{settings[0]?.gold_plant}(Cash Back)</h1>

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('offlinepop-pup').click() }} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="qr-detail text-center">
                                    <h6>{settings[0]?.account_holder}</h6>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <div className="qr-upiid text-center">
                                        <h6 className='mb-0'>Vyapar.171813426519@hdfcbank</h6>
                                    </div>
                                </div>
                                <div className="qq-sacn-img p-2">
                                    <img src={settings[0]?.qr_code} alt='' className='img-fluid' />
                                </div>
                                <form onSubmit={upimethod}>
                                    <div className="uplo-img ">
                                        <input type='file' className='d-none' id='scanimg' onChange={screenshot} />
                                        <button className='btn' type='button' onClick={() => { document.getElementById('scanimg').click() }}>Upload Transaction Slip <img src='\assets\iocns\upload.png' alt='' className='img-fluid' /></button>

                                        {
                                            file1 ? (
                                                <>
                                                    <div className='uploadfile-name position-relative'>
                                                        <p className='m-0'>{file1 ? `File Name : ${file1}` : ''}</p>
                                                        <h6 className='position-absolute remove-upload' onClick={() => { setfile1(); settransfile() }}><X /></h6>
                                                    </div>
                                                </>
                                            ) : ''
                                        }

                                        <input type="text" class="form-control bg-white text-dark border text-center my-2" placeholder="Transaction ID" aria-label="transaction-id" id='transaction-id' onChange={(e) => { setTranscationid(e.target.value) }} />
                                        <p className='text-danger '>* Upload the transaction slip or enter the transaction ID</p>
                                    </div>
                                    <div className="update-profile">
                                        <button className="text-white" type="submit">{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Continue'}</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* payment-modal */}

            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#paymentbox" id='payment-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="paymentbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel">{settings[0]?.gold_plant}(Cash Back)</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('payment-pup').click() }} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="pm-ti">
                                    <h5> Choose Payment Method</h5>
                                </div>
                                <div className="pm-choice ">
                                    <form onSubmit={paymentfun}>
                                        <div className={process_type == '1' ? 'form-check act' : 'form-check'}>
                                            <div className="selecttypes p-2 d-flex align-items-center gap-2">
                                                <input class="form-check-input mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                {/* <label class="form-check-label" for="inlineRadio1">Online Razarpay</label> */}

                                                <div className='razoricon'>
                                                    <img src='\assets\images\visa.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\mascard.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\rupay.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\phopay.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\mastro.png' className='img-fluid' alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={process_type == '2' ? 'form-check act' : 'form-check'} >
                                            <div className="selecttypes p-2  d-flex align-items-center gap-2">
                                                <input class="form-check-input  mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                {/* <label class="form-check-label" for="inlineRadio2">QR code / UPI</label> */}
                                                <label class="form-check-label" for="inlineRadio2">Direct UPI Payments (QR Code) </label>
                                                <div className='razoricon'><img src='\assets\images\upis.png' className='img-fluid' alt='' /></div>
                                            </div>
                                        </div>
                                        <div className={process_type == '3' ? 'form-check act' : 'form-check'}>
                                            <div className="selecttypes py-3 px-2  d-flex align-items-center gap-2">
                                                <input class="form-check-input  mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="3" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                {/* <label class="form-check-label" for="inlineRadio3">Bank Details</label> */}
                                                <label class="form-check-label" for="inlineRadio3">For RTGS / NEFT / IMPS</label>
                                                <div className='banic'>
                                                    <img src='\assets\images\net_img.png' className='img-fluid' alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="update-profile ">
                                            <button className="text-white" type="submit">Continue</button>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* bandetail-modal */}

            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#bankbox" id='bankpop-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="bankbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel"><span className='mx-1' style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('bankpop-pup').click(); document.getElementById('payment-pup').click() }}><ArrowLeft /></span>{settings[0]?.gold_plant}(Cash Back) </h1>

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('bankpop-pup').click() }} ></button>
                            </div>
                            <div className="modal-body">
                                <h5 className='mb-4'>Bank Details</h5>

                                <div className="acc-holder">
                                    <img src='\assets\iocns\Profile.png' className='img-fluid' /><span>{settings[0]?.account_holder}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\accountnumber.png' className='img-fluid' /><span>{settings[0]?.account_number}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\bank.png' className='img-fluid' /><span>{settings[0]?.bank_name}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\branchlocatin.png' className='img-fluid' /><span>{settings[0]?.branch_name}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\ifsc code.png' className='img-fluid' /><span>{settings[0]?.ifsc_code}</span>
                                </div>
                                <form onSubmit={Bankmethod}>

                                    <div className="uplo-img text-start">
                                        <input type='file' className='d-none' id='scanimg' onChange={screenshot} />
                                        <button className='btn ' type='button' onClick={() => { document.getElementById('scanimg').click() }}>Upload Transaction Slip <img src='\assets\iocns\upload.png' alt='' className='img-fluid' /></button>
                                        {
                                            file1 ? (
                                                <>
                                                    <div className='uploadfile-name position-relative'>
                                                        <p className='m-0'>{file1 ? `File Name : ${file1}` : ''}</p>
                                                        <h6 className='position-absolute remove-upload' onClick={() => { setfile1(); settransfile() }}><X /></h6>
                                                    </div>
                                                </>
                                            ) : ''
                                        }
                                        <input type="text" class="form-control bg-white text-dark border text-center my-2" placeholder="Transaction ID" id='transaction-id2' aria-label="transaction-id" onChange={(e) => { setTranscationid(e.target.value) }} />
                                        <p className='text-danger '>* Upload the transaction slip or enter the transaction ID</p>
                                    </div>

                                    <div className="update-profile mt-2 mb-0">
                                        <button className="text-white" type="submit" >{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Continue'}</button>
                                    </div>
                                </form>



                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
