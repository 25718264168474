import React, { useEffect, useState } from 'react'
import HeadingBanner from '../../Components/HeadingBanner.jsx'
import { useNavigate } from 'react-router-dom'
import {Product_List_fun } from '../../api/Pageapi.js';
import { Image, Shimmer } from 'react-shimmer';
import "aos/dist/aos.css";
import AOS from 'aos';

export default function ProductList() {
  const router = useNavigate();

  const [response, setresponse] = useState([]) 
  const [Isloading, setIsloading] = useState(true) 


  const getproduct_api = async () => {
         
         Product_List_fun()
         .then((res)=>{
          setresponse(res?.data)
          setIsloading(false)
         })

         .catch((err)=>{
          console.log(err)
          setIsloading(false)
         })

        }
   

  useEffect(() => {
    getproduct_api()
  }, [])

  useEffect(() => {
    AOS.init({
      duration : 1500
    });
  }, []);

  const slug = response[0]?.slug



  return (
    <>
      <HeadingBanner title={"Buy Gold & Silver"} />
      <section className='product-list-page'>
        <div className='container'>
          <div className='row'> 
            

            {   Isloading  ? 
             Array.from({length : 6}).map((_,i) => (
              <div className='col-lg-4  col-md-6 col-sm-6 col-12  mb-4' key={i}>
                  <div className='gp_lst border'>
                    <div className='mb-3 d-flex justify-content-center pl-img'>
                      <Shimmer  width={200} height={200} className='rounded'  />
                    </div>

                    <div className='d-flex flex-column gap-3 justify-content-center text-center'>
                      <h5 className='fw-bold pl-imgname'><Shimmer width={350} height={24}  className='rounded' /></h5>
                      <h6 className='text-color2 fw-bold text-center d-flex justify-content-center'><Shimmer  className='rounded' width={100} height={19}/></h6>
                      <div className='d-flex justify-content-center'>
                        <Shimmer width={160} height={50}  className='rounded' />
                      </div> 
                    </div>

                  </div>
            </div>
             ))
              : 
              response.length > 0 ? (
                response.map((product,i) => {
                  return (
                    <div className='col-lg-4 col-sm-6 mb-4' key={i}>
                      <div >
                        <div className='gp_lst border'>
                          <div className='mb-3 d-flex justify-content-center pd-view'>
                            <img src={product?.image} />
                          </div>

                          <div className='d-flex flex-column gap-3 justify-content-center text-center'>
                            <h5 className='fw-bold one_line'>{product?.name}</h5>
                            <h6 className='text-color2 fw-bold'>₹ {product?.price}</h6>
                            <div>
                              {/* <button type='button' onClick={() => router(`/detail`,{state : {id : product.id}})} className='btn' >View Details</button> */}
                              <button type='button' onClick={() => router(`/detail/${product.slug}`)} className='btn' >View Details</button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  )

                })

              ) : 
              <div className='w-100 d-flex justify-content-center'>
                <img src='/assets/noData.png' alt='' className='w-50' />
              </div>
            }

          </div>
        </div>
      </section>
    </>
  )
}
