import React, { useContext, useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from 'yup'
import { Logindetail } from "../context/Logindetail";
import moment from "moment/moment";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { accountdetails_fun, getprofile_fun, update_fun } from "../api/Dashboardapi";
import { City_fun, Country_fun, GenderApi, State_fun } from "../api/Controllapi";
import { CameraFill, X } from "react-bootstrap-icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { format } from "date-fns";
import dayjs from "dayjs";

const MyAccount = () => {

  const [file, setfile] = useState()
  const [gender, setgender] = useState([])
  const [imag, setimag] = useState()
  const [spinner, setspinner] = useState(false)
  const [CountryData, setCountryData] = useState([])
  const [StateData, setStateData] = useState([])
  const [countryid, setcountryid] = useState()
  const [CityData, setCityData] = useState()
  const [aadharlist, setaadharlist] = useState([])
  const [panlist, setpanlist] = useState([])
  const [date, setdate] = useState()
  const [adharfile, setAdharfile] = useState([])
  const [panfile, setpanfile] = useState([])

  const router = useNavigate()


  const { detail_info, setdetail_info } = useContext(Logindetail);
  const today = dayjs().subtract(18, 'years')

  const [initValue, setinitValue] = useState({

    // profile

    "name": `${detail_info?.name ? detail_info?.name : ""}`,
    "father_name": `${detail_info?.father_name ? detail_info?.father_name : ""}`,
    "phone_number": `${detail_info?.phone_number ? detail_info?.phone_number : ""}`,
    "email": `${detail_info?.email ? detail_info?.email : ""}`,
    "date_of_birth": `${detail_info?.date_of_birth ? detail_info?.date_of_birth : ""}`,
    "gender": `${detail_info?.gender ? detail_info?.gender : "female"}`,
    "avatar": `${detail_info?.avatar ? detail_info?.avatar : ""}`,

    // addresss

    "address_1": `${detail_info?.address_1 ? detail_info?.address_1 : ""}`,
    "address_2": `${detail_info?.address_2 ? detail_info?.address_2 : ""}`,
    "country": `${detail_info?.country ? detail_info?.country : ""}`,
    "state": `${detail_info?.state ? detail_info?.state : ""}`,
    "city": `${detail_info?.city ? detail_info?.city : ""}`,
    "postal": `${detail_info?.postal ? detail_info?.postal : ""}`,

    // Kyc

    "name_as_aadhar": `${detail_info?.name_as_aadhar ? detail_info?.name_as_aadhar : ''}`,
    "aadhar_number": `${detail_info?.aadhar_number ? detail_info?.aadhar_number : ''}`,
    "name_as_pan": `${detail_info?.name_as_pan ? detail_info?.name_as_pan : ''}`,
    "pan_number": `${detail_info?.pan_number ? detail_info?.pan_number : ''}`,
    "pan_documents": `${detail_info?.pan_documents ? detail_info?.pan_documents : ''}`,
    "aadhar_documents": `${detail_info?.aadhar_documents ? detail_info?.aadhar_documents : ''}`,

    "holder_name": `${detail_info?.account_detail?.holder_name ? detail_info?.account_detail?.holder_name : ''}`,
    "account_number": `${detail_info?.account_detail?.account_number ? detail_info?.account_detail?.account_number : ''}`,
    "confirm_account_number": `${detail_info?.account_detail?.account_number ? detail_info?.account_detail?.account_number : ''}`,
    "ifsc": `${detail_info?.account_detail?.ifsc ? detail_info?.account_detail?.ifsc : ''}`,
    "account_type": `${detail_info?.account_detail?.account_type ? detail_info?.account_detail?.account_type : 'Savings'}`,
    "bank_name": `${detail_info?.account_detail?.bank_name ? detail_info?.account_detail?.bank_name : ''}`,
    "branch": `${detail_info?.account_detail?.branch ? detail_info?.account_detail?.branch : ''}`,
    "gpay": `${detail_info?.account_detail?.gpay ? detail_info?.account_detail?.gpay : ''}`,
    "upi": `${detail_info?.account_detail?.upi ? detail_info?.account_detail?.upi : ''}`

  })




  const updateschema = Yup.object().shape({
    "name": Yup.string()
      .required("Enter name")
      .matches(/^[a-zA-Z ]*$/, "Invalid name"),

    "father_name": Yup.string()
      .required("Enter mother name")
      .matches(/^[a-zA-Z ]*$/, "Invalid name"),

    "phone_number": Yup.string()
      .min(10, 'Phone Number Minimum 10 digits')
      .max(10, 'Phone Number Maximum 10 digits')
      .required("Enter your Phone Number")
      .matches(/^[0-9]+$/, "Invalid  Phone Number"),


    'email': Yup.string().email('Invalid Email')
      .required('Enter Your Email')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/, 'Invalid Email'
      )
      .test(
        'Invalid Email',
        (value) => {
          return value === value.toLowerCase()
        }
      ),


    "date_of_birth": Yup.string(),

    "gender": Yup.string(),

    "avatar": Yup.mixed(),

    "address_1": Yup.string()
      .required("Enter Your Address"),

    "address_2": Yup.string(),

    "country": Yup.string(),

    "state": Yup.string(),

    "city": Yup.string(),

    "postal": Yup.string(),

    "name_as_aadhar": Yup.string()
      .required("Enter Name as per Aadhar")
      .matches(/^[a-zA-Z ]*$/, "Invalid Aadhar name"),

    "aadhar_number": Yup.string().required("Enter Aadhar Number")
      .min(12, ' Aadhar number Minimum 12 digits')
      .matches(/^[0-9]+$/, "Invalid  Aadhar number"),

    "name_as_pan": Yup.string().required("Enter Name as per PAN")
      .matches(/^[a-zA-Z ]*$/, "Invalid Pan name"),

    "pan_number": Yup.string().required("Enter PAN number")
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Pan number must contain 10 characters, including five uppercase letter,  four number, and last one uppercase letter"),


    "pan_documents": Yup.mixed(),

    "aadhar_documents": Yup.mixed(),

    "holder_name": Yup.string()
      .required('Enter Account Holder Name')
      .matches(/^[a-zA-Z ]*$/, "Invalid name"),

    "account_number": Yup.string()
      .required("Enter Account Number")
      .matches(/^[0-9]+$/, "Invalid  Account Number")
      .min(12, 'Account number minimum 12 digits'),


    "confirm_account_number": Yup.string()
      .required('Enter Confirm Account Number')
      .matches(/^[0-9]+$/, "Invalid  Account Number")
      .min(12, 'Account number minimum 12 digits')
      .oneOf([Yup.ref('account_number'), null], 'Account Number must be a Same'),

    "ifsc": Yup.string().required('Enter IFSC Code')
      .matches(/^[A-Z0-9]+$/, "Invalid IFSC Code")
      .min(11, ' Ifsc code Must be 11 digits'),


    "account_type": Yup.string()
      .required('Enter Account Type'),

    "bank_name": Yup.string().required('Enter Bank Name')
      .matches(/^[a-zA-Z ]*$/, "Invalid name"),

    "branch": Yup.string().required('Enter Branch Name')
      .matches(/^[a-zA-Z ]*$/, "Invalid name"),

    "gpay": Yup.string().required('Enter Gpay Number')
      .min(10, 'Gpay Number Minimum 10 digits')
      .max(10, 'Gpay Number Maximum 10 digits')
      .matches(/^[0-9]+$/, "Invalid  Account Number"),

    "upi": Yup.string()
      .required('Enter UPI ID')
      .matches(/^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/, 'Invalid UPI ID')

  })


  // function handlechange(e) {
  //   setimag(e.target.files[0])
  //   setfile(URL.createObjectURL(e.target.files[0]))
  // }

  function handlechange(e) {


    if (e.target.files[0]?.type === 'image/jpeg') {

      if (e.target.files[0]?.size > 2 * 1024 * 1024 || e.target.files[0]?.size <= 0) {
        toast.error('File Size Max 2MB')
      }
      else {
        setfile(URL.createObjectURL(e.target.files[0]));
        setimag(e.target.files[0])
      }

    }
    else {
      toast.error('Only Upload jpeg format')
    }

  }

  const handleaadhar = (e) => {

    const data = Object.values(e.target.files)

    for (var i = 0; i < data?.length; i++) {

      if (data[i]?.type === 'image/jpg' || data[i]?.type === 'image/jpeg' || data[i]?.type === 'image/png') {

        if (data[i]?.size > 2 * 1024 * 1024 || e.target.files[0]?.size <= 0) {
          toast.error('File Size Max 2MB')
        }
        else {

          setAdharfile([...adharfile, data[i]]);
        }
      }
      else {
        toast.error('Check image format')
      }
    }

  }

  const cancelimage = (value) => {
    setAdharfile((p) => p?.filter(r => r != value))
  }

  const handlepan = (e) => {

    const data = Object.values(e.target.files)

    for (var i = 0; i < data?.length; i++) {

      if (data[i]?.type === 'image/jpg' || data[i]?.type === 'image/jpeg' || data[i]?.type === 'image/png') {

        if (data[i]?.size > 2 * 1024 * 1024 || e.target.files[0]?.size <= 0) {
          toast.error('File Size Max 2MB')
        }
        else {

          setpanfile([...panfile, data[i]]);
        }
      }
      else {
        toast.error('Check image format')
      }
    }

  }

  const cancelimage2 = (value) => {
    setpanfile(panfile?.filter(e => e != value))
  }

  const Formrik = useFormik({
    initialValues: initValue,
    validationSchema: updateschema,
    onSubmit: async (value, { setErrors, setStatus, resetForm }) => {
      console.log(value?.date_of_birth)
      setspinner(true)
      const DOB = moment(date).format('YYYY-MM-DD')
      const formdata = new FormData()
      formdata.append("name", value.name)
      formdata.append("father_name", value.father_name)
      formdata.append("phone_number", value.phone_number)
      formdata.append("email", value.email)
      formdata.append("date_of_birth", DOB)
      formdata.append("gender", value.gender)
      formdata.append("address_1", value.address_1)
      formdata.append("address_2", value.address_2)
      formdata.append("country", value.country)
      formdata.append("state", value.state)
      formdata.append("city", value.city)
      formdata.append("postal", value.postal)
      formdata.append("avatar", imag)
      formdata.append("name_as_aadhar", value.name_as_aadhar)
      formdata.append("aadhar_number", value.aadhar_number)
      formdata.append("name_as_pan", value.name_as_pan)
      formdata.append("pan_number", value.pan_number)
      adharfile?.map((data, i) => {
        formdata.append(`aadhar_documents[${i}]`, data)
      })
      panfile?.map((data, i) => { formdata.append(`pan_documents[${i}]`, data) })
      formdata.append("holder_name", value.holder_name)
      formdata.append("account_number", value.account_number)
      formdata.append("confirm_account_number", value.confirm_account_number)
      formdata.append("ifsc", value.ifsc)
      formdata.append("account_type", value.account_type)
      formdata.append("bank_name", value.bank_name)
      formdata.append("branch", value.branch)
      formdata.append("gpay", value.gpay)
      formdata.append("upi", value.upi)

      update_fun(formdata)
        .then((res) => {
          setspinner(false)
          toast.success(res?.message)
          handlegetprofile()
        })
        .catch((err) => {
          console.log(err)
          setspinner(false)
        })


    }


  });

  const genderapi = async () => {
    try {
      GenderApi().then(res => {
        setgender(res?.data)
      }).catch(err => {
        console.log(err)
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  const getcountry = async () => {
    Country_fun()
      .then((res) => {
        setCountryData(res?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    const value = Formrik.getFieldProps('country').value;
    const value2 = Formrik.getFieldProps('state').value;

    if (value) {
      getstate(value)
    }

    if (value2) {
      getcity(value, value2)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Formrik.getFieldProps('country').value]);

  const getstate = (id) => {
    Formrik.setFieldValue("country", id);
    setcountryid(id)
    State_fun(id)
      .then((res) => {
        setStateData(res?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const getcity = (con, id) => {

    City_fun(con, id)
      .then((res) => {
        setCityData(res?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handlegetprofile = () => {
    getprofile_fun()
      .then((res) => {
        localStorage.setItem("green_login", JSON.stringify(res?.data));
        setdetail_info(res?.data)
        setfile(res?.data?.avatar);
      })
      .catch((err) => {
        console.log(err)
        setspinner(false)
      })
  }



  useEffect(() => {
    genderapi()
    getcountry()
    setfile(detail_info?.avatar ?? '')
    handlegetprofile()
  }, [])

  useEffect(() => {
    const aadharlist1 = Formrik.getFieldProps('aadhar_documents').value;
    setaadharlist(aadharlist1 ? aadharlist1.split(',') : '')

    const panlist1 = Formrik.getFieldProps('pan_documents').value;
    setpanlist(panlist1 ? panlist1.split(',') : '')

  }, [])





  return (
    <>
      {/* <section className='breadcamp'>
        <div className='container'>
          <div className='d-flex gap-2'>
            <h4 className='mb-0' style={{ cursor: "pointer" }}><span onClick={() => { router('/') }} >Home</span>{`>> My Account`}</h4>
          </div>
        </div>
      </section> */}

      <section className="pt-3">
        <div className="container">
          <div className="myaccont_layout">

            <div className="myaccount-title text-center">
              <h2>My Account</h2>
            </div>

            <div className="myaccount-info mt-5">
              <form onSubmit={Formrik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="myac-basicdetail">
                      <div className="bd-head pb-3" >
                        <h4>Basic Details</h4>
                      </div>
                      <div className="bd-form border">

                        <div className="profice-pic text-center position-relative">
                          <img src={file ? file : '/assets/images/user.jfif'} id="uploadimg" className="img-fluid" alt="" />
                          <input type="file" id="my_file" className="d-none" accept=".jpeg " onChange={handlechange} />
                          <div className=" profile-came position-absolute" onClick={() => { document.getElementById('my_file').click() }}><CameraFill /></div>
                          {
                            Formrik.touched.avatar && Formrik.errors.avatar ? (<p className="text-danger m-0">{Formrik.errors.avatar}</p>) : null
                          }
                        </div>
                        <div className="row p-md-4 p-sm-0">
                          <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                            <label> Name</label>
                            <input type="text" className="form-control" id="inputname" placeholder=" Name"  {...Formrik.getFieldProps("name")} />
                            {
                              Formrik.touched.name && Formrik.errors.name ? (<span className="text-danger">{Formrik.errors.name}</span>) : null
                            }
                          </div>
                          <div className="form-group col-md-6  col-sm-6 col-6 login-text">
                            <label >Mother Name</label>
                            <input type="text" className="form-control" id="inputfathername" placeholder="Mother Name" {...Formrik.getFieldProps("father_name")} />
                            {
                              Formrik.touched.father_name && Formrik.errors.father_name ? (<span className="text-danger">{Formrik.errors.father_name}</span>) : null
                            }
                          </div>
                          <div className="form-group col-md-6  col-sm-6 col-6 login-text">
                            <label >Email</label>
                            <input type="Email" className="form-control" id="inputemail" placeholder="Email"  {...Formrik.getFieldProps("email")} />
                            {
                              Formrik.touched.email && Formrik.errors.email ? (<span className="text-danger">{Formrik.errors.email}</span>) : null
                            }
                          </div>
                          <div className="form-group col-md-6  col-sm-6 col-6 login-text">
                            <label >Phone</label>
                            <input type="text" className="form-control" id="inputPhone" placeholder="Phone" {...Formrik.getFieldProps("phone_number")} />
                            {
                              Formrik.touched.phone_number && Formrik.errors.phone_number ? (<span className="text-danger">{Formrik.errors.phone_number}</span>) : null
                            }
                          </div>
                          <div className="form-group col-md-6  col-sm-6 col-6 login-text">
                            <label >Date of Birth</label>
                            {/* <input type="date" className="form-control" id="inputemail2" placeholder="D-O-B" {...Formrik.getFieldProps("date_of_birth")} pattern="\d{2}-\d{2}-\d{4}" /> */}
                            <div className="react-date">
                              {/* <DatePicker
                                selected={date}
                                dateFormat="dd/MM/yyyy"
                                minDate={new Date(moment().subtract(100, "years"))}
                                maxDate={new Date(moment().subtract(18, "years"))}
                                peekNextMonth
                                showMonthDropdown
                                placeholderText="DD-MM-YYYY"
                                showYearDropdown
                                dropdownMode="select"
                                onChange={(date) => { setdate(date); Formrik.setFieldValue('date_of_birth', moment(date).format('YYYY-MM-DD')) }}

                              /> */}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                  {/* <DatePicker
                                    maxDate={today}
                                    format="DD-MM-YYYY"
                                    onChange={(newValue) => { setdate(format(newValue, 'yyyy-MM-dd')) }}
                                    defaultValue={detail_info?.date_of_birth ? dayjs(detail_info?.date_of_birth) : ''}
                                    sx={{
                                      backgroundColor: "#b7b2b221",
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none"
                                      },
                                      "&.Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border: "none"
                                        }
                                      }


                                    }}
                                    slotProps={{
                                      field: {
                                        readOnly: true
                                      }
                                    }}

                                  /> */}
                                  <DatePicker
                                    maxDate={today}
                                    format="DD-MM-YYYY"
                                    onChange={(newValue) => { setdate(dayjs(newValue).format('YYYY-MM-DD')) }}
                                    defaultValue={detail_info?.date_of_birth ? dayjs(detail_info?.date_of_birth) : ''}
                                    sx={{
                                      backgroundColor: "#b7b2b221",
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none"
                                      },
                                      "&.Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border: "none"
                                        }
                                      }
                                    }}
                                    slotProps={{
                                      field: {
                                        readOnly: true
                                      }
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                            {
                              Formrik.touched.date_of_birth && Formrik.errors.date_of_birth ? (<span className="text-danger">{Formrik.errors.date_of_birth}</span>) : null
                            }
                          </div>
                          <div className="form-group col-md-6  col-sm-6 col-6 login-text">
                            <label>Gender</label>
                            <select className="form-select" id="inputGroupSelect01" {...Formrik.getFieldProps("gender")} >
                              {/* {
                                gender ? gender.map((r, i) => {
                                  return (
                                    <option value={r.id} key={i}>{r.name}</option>
                                  )
                                }) : ""
                              } */}
                              <option value="" selected disabled>Select</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>

                            </select>
                            {
                              Formrik.touched.gender && Formrik.errors.gender ? (<span className="text-danger">{Formrik.errors.gender}</span>) : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="myac-withdraw mt-5">
                      <div className="bd-head pb-3" >
                        <h4>Withdrawal Method</h4>
                      </div>
                      <div className="bd-form border">
                        {/* <div className="row">
                          <div className="form-group col-md-6 col-sm-6 col-6 login-text" >
                            <label>Name as Aadhar Card</label>
                            <input type="text" className="form-control" id="inputaddress7" placeholder="Name as Aadhar Card" />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                            <label>Aadhar Number</label>
                            <input type="text" className="form-control" id="inputaddress8" placeholder="Aadhar Number" />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                            <label>Name As Pan Card</label>
                            <input type="text" className="form-control" id="inputaddress9" placeholder="Name As Pan Card" />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                            <label>Pan Number</label>
                            <input type="text" className="form-control" id="inputaddress10" placeholder="Pan Number" />
                          </div>
                          <div className="form-group col-md-12 col-12  mb-3 login-text">
                            <label>Upload Documents *(PNG,JPG,JPEG)</label>
                            <input className="form-control" id="inputGroupFile03" type="file" />
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-12   form-group login-text">
                            <label className="col-form-label">Account Holder Name</label>
                            <input type="text" className="form-control" id="recipient-name" placeholder="Account Holder Name" {...Formrik.getFieldProps("holder_name")} />
                            {
                              Formrik.touched.holder_name && Formrik.errors.holder_name ? (<p className="text-danger m-0">{Formrik.errors.holder_name}</p>) : null
                            }
                          </div>


                          <div className="col-md-6 col-sm-6 col-6 form-group login-text">
                            <label className="col-form-label mb-acnum">Account Number</label>
                            <input type="text" className="form-control" id="recipient-name2" placeholder="Account Number"    {...Formrik.getFieldProps("account_number")} />
                            {
                              Formrik.touched.account_number && Formrik.errors.account_number ? (<p className="text-danger m-0">{Formrik.errors.account_number}</p>) : null
                            }
                          </div>
                          <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                            <label className="col-form-label">Confirm Account Number</label>
                            <input type="text" className="form-control" id="recipient-name1" placeholder="Confirm Account Number"  {...Formrik.getFieldProps("confirm_account_number")} />
                            {
                              Formrik.touched.confirm_account_number && Formrik.errors.confirm_account_number ? (<p className="text-danger m-0">{Formrik.errors.confirm_account_number}</p>) : null
                            }
                          </div>
                          <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                            <label className="col-form-label">Bank Name</label>
                            <input type="text" className="form-control" id="recipient-name3" placeholder="Bank Name"  {...Formrik.getFieldProps("bank_name")} />
                            {
                              Formrik.touched.bank_name && Formrik.errors.bank_name ? (<p className="text-danger m-0">{Formrik.errors.bank_name}</p>) : null
                            }
                          </div>

                          <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                            <label className="col-form-label">Branch Name</label>
                            <input type="text" className="form-control" id="recipient-name3" placeholder="Branch Name"  {...Formrik.getFieldProps("branch")} />
                            {
                              Formrik.touched.branch && Formrik.errors.branch ? (<p className="text-danger m-0">{Formrik.errors.branch}</p>) : null
                            }
                          </div>
                          <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                            <label className="col-form-label">IFSC Code</label>
                            <input type="text" className="form-control" id="recipient-name3" placeholder="IFSC Code"  {...Formrik.getFieldProps("ifsc")} />
                            {
                              Formrik.touched.ifsc && Formrik.errors.ifsc ? (<p className="text-danger m-0">{Formrik.errors.ifsc}</p>) : null
                            }
                          </div>
                          <div className="col-md-6 col-sm-6 col-6  form-group login-text" >
                            <label className="col-form-label">Account Type</label>
                            <select className="form-select" aria-label="addbankname" {...Formrik.getFieldProps("account_type")}>
                              <option value="" selected disabled>Select</option>
                              <option value="Savings">Savings</option>
                              <option value="Current">Current</option>
                            </select>
                            {
                              Formrik.touched.account_type && Formrik.errors.account_type ? (<p className="text-danger m-0">{Formik.errors.account_type}</p>) : null
                            }
                          </div>
                          <div className="border my-2" ></div>
                          <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                            <label className="col-form-label">Gpay Number</label>
                            <input type="text" className="form-control" id="recipient-name3" placeholder="Gpay Number"  {...Formrik.getFieldProps("gpay")} />
                            {
                              Formrik.touched.gpay && Formrik.errors.gpay ? (<p className="text-danger m-0">{Formrik.errors.gpay}</p>) : null
                            }
                          </div>

                          <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                            <label className="col-form-label">UPI ID</label>
                            <input type="text" className="form-control" id="recipient-name3" placeholder="UPI ID"  {...Formrik.getFieldProps("upi")} />
                            {
                              Formrik.touched.upi && Formrik.errors.upi ? (<p className="text-danger m-0">{Formrik.errors.upi}</p>) : null
                            }
                          </div>


                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-lg-6">
                    <div className="myac-addrdetail mt-5 mt-lg-0">
                      <div className="bd-head pb-3" >
                        <h4>Address Details</h4>
                      </div>
                      <div className="bd-form border ">
                        <div className="row">
                          <div className="form-group col-md-12 login-text">
                            <label>Address Line 1</label>
                            <input type="text" className="form-control" id="inputaddress" placeholder="Address Line 1"  {...Formrik.getFieldProps("address_1")} />
                            {
                              Formrik.touched.address_1 && Formrik.errors.address_1 ? (<p className="text-danger m-0">{Formrik.errors.address_1}</p>) : null
                            }
                          </div>
                          <div className="form-group col-md-12 login-text">
                            <label>Address Line 2</label>
                            <input type="text" className="form-control" id="inputaddress2" placeholder="Address Line 2"  {...Formrik.getFieldProps("address_2")} />
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                            <label>Country</label>
                            <select className="form-select" id="inputGroupSelect00"   {...Formrik.getFieldProps("country")}  >
                              <option value={''} selected disabled>Select</option>
                              {
                                CountryData?.map((r) => {
                                  return (
                                    <option value={r?.id} > {r?.name}  </option>
                                  )
                                })
                              }
                            </select>
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6 login-text ">
                            <label>State</label>
                            <select className="form-select" id="inputGroupSelect02"   {...Formrik.getFieldProps("state")}   >
                              <option value={''} selected disabled>Select</option>
                              {
                                StateData?.map((r) => {
                                  return (
                                    <option value={r?.id}>{r?.state}</option>
                                  )
                                })
                              }
                            </select>
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6 login-text"  >
                            <label>City</label>
                            <select className="form-select" id="inputGroupSelect03" {...Formrik.getFieldProps("city")} >
                              <option value={''} selected disabled>Select</option>
                              {
                                CityData?.map((r) => {
                                  return (
                                    <option value={r?.id}>{r?.city}</option>
                                  )
                                })
                              }
                            </select>
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                            <label>Postal code</label>
                            <input type="text" className="form-control" id="inputaddress2" placeholder="Postal code"  {...Formrik.getFieldProps("postal")} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="myac-kyc mt-5">
                      <div className="bd-head pb-3 ">
                        <h4>KYC Info</h4>
                      </div>
                      <div className="bd-form border">
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                            <label>Name as Aadhar Card</label>
                            <input type="text" className="form-control" id="inputaddress3" placeholder="Name as Aadhar Card" {...Formrik.getFieldProps("name_as_aadhar")} />
                            {
                              Formrik.touched.name_as_aadhar && Formrik.errors.name_as_aadhar ? (<p className="text-danger">{Formrik.errors.name_as_aadhar}</p>) : null
                            }

                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                            <label>Aadhar Number</label>
                            <input type="text" maxLength={12} className="form-control" id="inputaddress4" placeholder="Aadhar Number" {...Formrik.getFieldProps("aadhar_number")} />
                            {
                              Formrik.touched.aadhar_number && Formrik.errors.aadhar_number ? (<p className="text-danger m-0">{Formrik.errors.aadhar_number}</p>) : null
                            }
                          </div>

                          <div className="form-group mb-3 col-md-12 col-12 login-text">
                            <label>Upload Aadhar Documents <span className="text-danger doc-format">*(PNG,JPG,JPEG)</span></label>
                            <div className="upload-docs">
                              <button className="btn" type="button" onClick={() => { document.getElementById('aadharfile').click() }}> <span><img src="\assets\iocns\upload.png" alt="" /></span> Browse</button>
                            </div>
                            <input className="form-control d-none" id="aadharfile" type="file" accept="image/jpg, image/jpeg, image/png" multiple="multiple" onChange={handleaadhar} />
                            {
                              Formrik.touched.aadhar_documents && Formrik.errors.aadhar_documents ? (<p className="text-danger m-0">{Formrik.errors.aadhar_documents}</p>) : null
                            }

                            <div className="selected-file d-flex flex-wrap login-text">
                              {
                                adharfile?.map((data, i) => {
                                  return (
                                    <div className="position-relative">
                                      <img src={URL.createObjectURL(data)} className="img-fluid docimg " alt="" />
                                      <div className="diselect position-absolute" onClick={() => { cancelimage(data) }} ><X /></div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                            <label>Name as Pan Card</label>
                            <input type="text" className="form-control" id="inputaddress5" placeholder="Name as Pan Card" {...Formrik.getFieldProps("name_as_pan")} />
                            {
                              Formrik.touched.name_as_pan && Formrik.errors.name_as_pan ? (<p className="text-danger m-0">{Formrik.errors.name_as_pan}</p>) : null
                            }
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                            <label>Pan Number</label>
                            <input type="text" className="form-control" id="inputaddress6" placeholder="Pan Number"   {...Formrik.getFieldProps("pan_number")} />
                            {
                              Formrik.touched.pan_number && Formrik.errors.pan_number ? (<p className="text-danger m-0">{Formrik.errors.pan_number}</p>) : null
                            }
                          </div>

                          <div className="form-group mb-3 col-md-12 col-12 login-text">
                            <label>Upload Pan Documents  <span className="text-danger doc-format">*(PNG,JPG,JPEG)</span></label>
                            <div className="upload-docs">
                              <button className="btn" type="button" onClick={() => { document.getElementById('panfile').click() }}> <span><img src="\assets\iocns\upload.png" alt="" /></span> Browse</button>
                            </div>
                            <input className="form-control d-none" id="panfile" accept="image/jpg, image/jpeg, image/png" multiple="multiple" type="file" onChange={handlepan} />
                            {
                              Formrik.touched.pan_documents && Formrik.errors.pan_documents ? (<p className="text-danger m-0">{Formrik.errors.pan_documents}</p>) : null
                            }
                            <div className="selected-file d-flex flex-wrap login-text">
                              {
                                panfile?.map((data, i) => {
                                  return (
                                    <div className="position-relative">
                                      <img src={URL.createObjectURL(data)} className="img-fluid docimg" alt="" />
                                      <div className="diselect position-absolute" onClick={() => { cancelimage2(data) }} ><X /></div>
                                    </div>
                                  )
                                })
                              }
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="myac-submit submit mt-sm-5 mt-3">
                  <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Submit'}</button>
                </div>
              </form>
            </div>

          </div>
        </div>


      </section>

    </>
  )
}
export default MyAccount