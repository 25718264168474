import { initializeApp, getApps, getApp, } from 'firebase/app'
import {
    getMessaging,
    getToken,
    onMessage,
    isSupported,
} from 'firebase/messaging'; 
import {getAuth} from 'firebase/auth'
import firebase from "firebase/compat/app"

let firebaseConfig = {
    apiKey: 'AIzaSyA7P97-I1YVkiKLexJsqzZ0OGGwqHJC7xE',
    authDomain: 'greenheapfood.firebaseapp.com',
    projectId: 'greenheapfood',
    storageBucket: 'greenheapfood.firebasestorage.app',
    messagingSenderId: '213126205794',
    appId: '1:213126205794:web:7819f40a8c3ea19cdd5c3b',
    measurementId: 'G-7JET4SQRBH',
}

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } 

const app = initializeApp(firebaseConfig);

const authentication = getAuth(app);

const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp(); 

// Initialize Firebase App
// let firebaseApp;
// if (!getApps().length) {
//     firebaseApp = initializeApp(firebaseConfig);
// } else {
//     firebaseApp = getApp(); // Use the already initialized app
// }
// const authentication = getAuth(firebaseApp);


const messaging = (async () => {
    try {
        const isSupportedBrowser = await isSupported()
        if (isSupportedBrowser) {
            return getMessaging(firebaseApp)
        }

        return null
    } catch (err) {
        return null
    }
})()

export const fetchToken = async (setTokenFound, setFcmToken) => {
    return getToken(await messaging, {
        vapidKey:
            '',
    })
        .then((currentToken) => {
            if (currentToken) {
                setTokenFound(true)
                setFcmToken(currentToken)
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                setTokenFound(false)
                setFcmToken()
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            console.error(err)
            // catch error while creating client token
        })
}   

export const onMessageListener = async () =>
    new Promise((resolve) =>
        (async () => {
            // const messagingResolve = await messaging
            // onMessage(messagingResolve, (payload) => {
            //     resolve(payload)
            // })
        })()
    )

const signOut = () => {
 return authentication.signOut();
};

export {firebaseApp, authentication, signOut, app };