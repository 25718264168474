import React, { useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import * as Yup from 'yup'
import { useFormik } from "formik";
import { Logindetail } from "../context/Logindetail.js";
import { fetchBeneficiaries, RemoveBeneficiaries, saveBeneficiaries } from "../api/Pageapi.js";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Image, Shimmer } from 'react-shimmer'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo/DemoContainer.js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { format } from "date-fns";
import dayjs from "dayjs";
// import DatePicker from "react-datepicker";

export default function Beneficiaries({ settings }) {

    const [spinner, setspinner] = useState(false)
    const [isLoading, setisLoading] = useState(true)
    const [benfic, setbenfic] = useState([])
    const [rowdata, setRowData] = useState()
    const [Delete_id, setDelete_id] = useState()
    const [date, setdate] = useState()
    const today = dayjs().subtract(18, 'years')


    const bankaccount = {
        "name": rowdata?.name ?? '',
        "relation": rowdata?.relation ?? '',
        "gender": rowdata?.gender ?? '',
        "dob": rowdata?.date_of_birth ?? ''
    }

    const updateschema = Yup.object().shape({
        "name": Yup.string().required('Enter Name')
            .matches(/^[a-zA-Z ]*$/, "Invalid name"),

        "relation": Yup.string()
            .required("Enter Relationship")
            .matches(/^[a-zA-Z ]*$/, "Invalid name"),

        "gender": Yup.string().required('Select Gender'),

        'dob': Yup.string()
            .required("Enter DOB"),
    })

    useEffect(() => {
        if (rowdata) {
            Formik.setFieldValue('name', rowdata?.name ?? '')
            Formik.setFieldValue('relation', rowdata?.relation ?? '')
            Formik.setFieldValue('gender', rowdata?.gender ?? '')
            Formik.setFieldValue('dob', format(rowdata?.date_of_birth, 'yyyy-MM-dd') ?? '')

            document.getElementById('jhgdSJHKDSADDD')?.click();
        } else {
            Formik.resetForm();
        }
    }, [rowdata]);

    useEffect(() => {
        Formik.setFieldValue('dob', date)
    }, [date])


    const handlefectch = () => {
        setisLoading(true)
        fetchBeneficiaries().then(res => {
            setbenfic(res?.data)
            setisLoading(false)
        }).catch(err => {
            console.log('err', err.message);
            setisLoading(false)
        });
    }

    useEffect(() => {
        handlefectch();
    }, [])

    const handleRemove = (id) => {
        setDelete_id(id)
        document.getElementById('deletebeneifbox').click()
    }

    const handledelete_beneif = () => {
        RemoveBeneficiaries(Delete_id).then(res => {
            if (res?.error) {
                toast.error(res?.error)
            }
            else {
                handlefectch();
                toast.success('Deleted Successfully')
                setDelete_id()
            }
        }).catch(err => {
            console.log(err)
        });
    }

    const Formik = useFormik({
        initialValues: bankaccount,
        validationSchema: updateschema,
        onSubmit: (value, { setErrors, setStatus, resetForm }) => {

            console.log('dfffffffffffffffffffffffffffffffffffff');
            console.log(value);

            console.log('dfffffffffffffffffffffffffffffffffffff');

            setspinner(true)
            saveBeneficiaries(value, rowdata?.id ? rowdata?.id : '')
                .then((res) => {
                    toast.success("success!")
                    setRowData()
                    document.getElementById('clode_accountmodal')?.click();
                    resetForm()
                    setspinner(false);
                    handlefectch();

                })
                .catch((err) => {
                    console.log(err)
                    setspinner(false)
                });
        }
    })


    const columns = [

        {
            field: 's_no',
            headerName: 'S.NO',
            width: 100,

        },
        {
            field: 'name',
            headerName: 'NAME',
            width: 150,
            valueGetter: (value, row) => `${row?.name}`,
        },
        {
            field: 'relation',
            headerName: 'RELATION',
            width: 150,
            valueGetter: (value, row) => `${row?.relation}`,
            sortable: false,
        },
        {
            field: 'gender',
            headerName: 'GENDER',
            width: 150,
            sortable: false,
        },
        {
            field: 'date_of_birth',
            headerName: 'DATE OF BIRTH',
            width: 165,
            valueGetter: (value, row) => `${moment(row?.date_of_birth).format('DD-MM-YYYY')}`,
            sortable: false,
        },
        {
            field: 'edit',
            headerName: 'EDIT',
            width: 130,
            renderCell: (params) => (
                <>
                    <div className="" onClick={() => setRowData(params?.row)}>
                        <img src="\assets\iocns\edit.png" className="cursor-pointer" alt="edit" width={20} style={{ cursor: 'pointer' }} />
                    </div>
                </>
            ),
            // valueGetter: (value, row) => moment(row.purchase_transaction[0]?.paid_date).format('DD-MM-YYYY') ?? '-',
            // valueGetter: (value, row) => `${moment(row?.created_at).format('DD-MM-YYYY') ?? '-'}`,

            sortable: false,
        },
        {
            field: 'delete',
            headerName: 'DELETE',
            width: 120,
            renderCell: (params) => (
                <>
                    <div onClick={() => handleRemove(params?.row?.id)}>
                        <img src="\assets\iocns\delete.png" className="cursor-pointer" alt="delete" width={20} style={{ cursor: 'pointer' }} />
                    </div>
                </>
            ),
            sortable: false,
        }


    ];

    const paginationModel = { page: 0, pageSize: 5 };


    return (
        <>
            <HeadingBanner title={"Beneficiaries"} />
            <section>
                <div className="container">
                    <div className="mybankac-layout">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-9 col-lg-8">

                                {isLoading ?
                                    <div className="siptrans-content position-relative">
                                        <div className="siptrans-head pb-3 align-items-center d-flex justify-content-between">
                                            <h4>Beneficiaries</h4>
                                        </div>
                                        <div className="tableload commision-load text-center position-absolute">
                                            <div class="spinner-border text-primary text-center" role="status"></div>
                                        </div>
                                        {/* <div className="siptrans-table border" >
                                            <div className="siptable-view">
                                                <table className="table">
                                                    <thead className="p-3">
                                                        <tr className="text-center">
                                                            <th scope="col" >S.NO</th>
                                                            <th scope="col" >Name</th>
                                                            <th scope="col">Relation</th>
                                                            <th scope="col">Date Of Birth</th>
                                                            <th scope="col">Edit</th>
                                                            <th scope="col">Delete</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            Array.from({ length: 6 }).map((_, i) => {
                                                                return (
                                                                    <tr className="text-center" key={i}>
                                                                        <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block ms-2" /></td>
                                                                        <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                        <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                        <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                        <td className="text-center" ><Shimmer height={40} width={55} className="rounded d-inline-block" /></td>
                                                                        <td className="text-center" ><Shimmer height={40} width={55} className="rounded d-inline-block" /></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div> */}
                                    </div>
                                    :
                                    benfic?.length > 0 ?
                                        <div className="siptrans-content">
                                            <div className="siptrans-head pb-3 align-items-center d-flex justify-content-between">
                                                <h4>Beneficiaries</h4>
                                                <div className="addbank-btn text-white my-3">
                                                    <button className="d-none" type="button" data-bs-toggle="modal" id="jhgdSJHKDSADDD" data-bs-target="#addbankmodal" data-bs-whatever="@getbootstrap" ></button>
                                                    <button className="text-white" onClick={() => { Formik.resetForm(); setRowData(); setdate() }} type="button" data-bs-toggle="modal" id="jhgdSJHKDSADDD35355" data-bs-target="#addbankmodal" data-bs-whatever="@getbootstrap"  >Add Beneficiaries</button>
                                                </div>
                                            </div>
                                            <Paper sx={{ height: 450, width: '100%' }} className="tabledata">
                                                <DataGrid
                                                    rows={benfic.map((item, index) => ({ s_no: index + 1, ...item }))}
                                                    disableColumnMenu
                                                    columns={columns}
                                                    initialState={{ pagination: { paginationModel } }}
                                                    pageSizeOptions={[5, 10]}
                                                    sx={{ border: 0 }}
                                                    disableColumnFilter
                                                    disableColumnSelector
                                                    disableDensitySelector
                                                    className="schemedata-table"
                                                    slots={{ toolbar: GridToolbar }}
                                                    slotProps={{
                                                        toolbar: {
                                                            showQuickFilter: true,
                                                            printOptions: { disableToolbarButton: true },
                                                            csvOptions: { disableToolbarButton: true },

                                                        },
                                                    }}

                                                />
                                            </Paper>


                                        </div>

                                        :
                                        <div className="mybankac-content">
                                            <div className="mybank-head pb-3">
                                                <h4>Beneficiaries</h4>
                                            </div>
                                            <div className="addbank-ac text-center border py-3">
                                                <div className="bankimage my-3 ">
                                                    <img src="\assets\iocns\SIP.png" className="img-fluid" />
                                                </div>
                                                <div className="addbank-info ">
                                                    <p>Add Beneficiaries Details</p>
                                                </div>
                                                <div className="addbank-btn text-white my-3">
                                                    <button className="text-white" type="button" data-bs-toggle="modal" data-bs-target="#addbankmodal" data-bs-whatever="@getbootstrap" onClick={() => { setdate(); document.getElementById('recipient-name1').value = null; document.getElementById('recipient-name3').value = null }}>Add Beneficiaries</button>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="modal fade Addbankacc-modal" id="addbankmodal" tabIndex={-1} aria-labelledby="addbankmodal" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content py-3">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">{rowdata?.id ? 'Edit' : 'Add'} Beneficiaries</h3>
                            <button className="d-none" id="clode_accountmodal" data-bs-toggle="modal" data-bs-target="#addbankmodal"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={Formik.handleSubmit}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6  col-sm-6 col-6  form-group  login-text">
                                            <label className="col-form-label">Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="recipient-name1" placeholder="Enter Name"  {...Formik.getFieldProps("name")} />
                                            {
                                                Formik.touched.name && Formik.errors.name ? (<p className="text-danger m-0">{Formik.errors.name}</p>) : null
                                            }
                                        </div>
                                        <div className="col-lg-6 col-md-6  col-sm-6 col-6   form-group  login-text">
                                            <label className="col-form-label">Relation <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="recipient-name3" placeholder="Enter Relationship"  {...Formik.getFieldProps("relation")} />
                                            {
                                                Formik.touched.relation && Formik.errors.relation ? (<p className="text-danger m-0">{Formik.errors.relation}</p>) : null
                                            }
                                        </div>
                                        <div className="form-group col-md-6  col-sm-6 col-6  login-text">
                                            <label className="col-form-label">Date of Birth</label>
                                            <input type="date" className="form-control" name="date_of_birth" id="" {...Formik.getFieldProps("dob")} />


                                            {
                                                Formik.touched.dob && Formik.errors.dob ? (<span className="text-danger">{Formik.errors.dob}</span>) : null
                                            }


                                        </div>
                                        <div className="col-lg-6 col-md-6  col-sm-6 col-6  form-group  login-text" >
                                            <label className="col-form-label">Gender <span className="text-danger">*</span></label>
                                            <select className="form-select" aria-label="addbankname" {...Formik.getFieldProps("gender")}>
                                                <option value="" selected disabled>Select</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                            {
                                                Formik.touched.gender && Formik.errors.gender ? (<p className="text-danger m-0">{Formik.errors.gender}</p>) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="update-profile  mx-3">
                                    <button className='text-white  ' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Sumbit'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deletebeneif" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <button className="d-none" data-toggle="modal" data-target="#deletebeneif" id="deletebeneifbox"></button>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body  text-center">
                            <div className="popdelete">
                                <img src="\assets\iocns\delete.png" />
                            </div>
                            <div className="pop-submsg2 mt-3">
                                <h4>Are You Sure </h4>
                                <h4>You Want to Delete </h4>
                            </div>
                            <div className="modal-footer border-0 justify-content-center ">
                                <button type="button " className="btn btn-danger" data-dismiss="modal" onClick={handledelete_beneif}>Delete</button>
                                <button type="button " className="btn btn-primary" data-dismiss="modal">Close</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
